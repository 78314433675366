import { useCallback } from 'react';
import {
  fetchLedgers,
  sentToCreditCommittee,
  cloneLedger,
  updateLedger,
  fetchBuyRates,
  deleteLedger,
  createLedger,
  fetchLedgerOffers,
  patchLedger,
  fetchLedgerBankingSuggestedPricing,
  overrideBuyRates,
  validationOverride,
  overriddenValidations,
  fetchLedger,
} from 'api/banking/ledgerFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  BuyRatesResponse,
  LedgerBankingPricingSuggestionsRaw,
  LedgerOfferRaw,
  LedgerResponse,
  OverrideByRatesPayload,
  PatchLedgerRequestBody,
  UpdateLedgerBody,
} from 'types/api/banking/types';
import { GenericLedgerApiParams } from 'components/DealScoring/Ledger/ledger.types';
import {
  OverriddenValidationsResponse,
  ValidationOverridePayload,
} from 'types/api/underwriting/types';

export type UseUpdateLedgerArgs = {
  submissionUuid: string;
  updateBody: UpdateLedgerBody;
};

export type UseUpdateLedgerResponse = UseGenericMutationResponse<
  { success: boolean },
  UseUpdateLedgerArgs
>;

const updateLedgerWithArgs = async (
  args: UseUpdateLedgerArgs
): Promise<{ success: boolean }> =>
  updateLedger(args.submissionUuid, args.updateBody);

export const useApiUpdateLedger = (): UseUpdateLedgerResponse => {
  return useGenericMutation(updateLedgerWithArgs);
};

export const useFetchLedgers = (
  submissionUuid: string
): UseGenericQueryResponse<LedgerResponse[]> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgers(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export const useApiSendToCreditCommittee = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  return useGenericMutation(sentToCreditCommittee);
};

export const useApiPatchLedger = (): UseGenericMutationResponse<
  Pick<MutationResponse, 'success'>,
  GenericLedgerApiParams & {
    requestBody: PatchLedgerRequestBody;
  }
> => useGenericMutation(patchLedger);

export const useApiCloneLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => useGenericMutation(cloneLedger);

export const useApiFetchBuyRates = ({
  submissionUuid,
  ledgerId,
  params,
}: GenericLedgerApiParams): UseGenericQueryResponse<BuyRatesResponse> => {
  const fetchFunction = useCallback(() => {
    return fetchBuyRates({ submissionUuid, ledgerId, params });
  }, [submissionUuid, ledgerId, params]);

  return useGenericQuery(fetchFunction);
};

export const useApiResetBuyRates = (): UseGenericMutationResponse<
  BuyRatesResponse,
  GenericLedgerApiParams & {
    params: string;
  }
> => useGenericMutation(fetchBuyRates);

export const useApiOverrideBuyRates = (): UseGenericMutationResponse<
  BuyRatesResponse,
  GenericLedgerApiParams & {
    requestBody: OverrideByRatesPayload;
  }
> => useGenericMutation(overrideBuyRates);

export const useApiDeleteLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => useGenericMutation(deleteLedger);

export const useApiCreateLedger = (): UseGenericMutationResponse<
  MutationResponse,
  Omit<GenericLedgerApiParams, 'ledgerId'>
> => {
  return useGenericMutation(createLedger);
};

export const useFetchOffers = ({
  ledgerId,
  submissionUuid,
}: Omit<GenericLedgerApiParams, 'customerUuid'>): UseGenericQueryResponse<
  LedgerOfferRaw[]
> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgerOffers({ submissionUuid, ledgerId });
  }, [submissionUuid, ledgerId]);

  return useGenericQuery(fetchFunction);
};

export const useFetchLedgerBankingSuggestedPricing = (
  ledgerId?: number
): UseGenericQueryResponse<LedgerBankingPricingSuggestionsRaw> => {
  const fetchFunction = useCallback(() => {
    if (!ledgerId) {
      return undefined;
    }
    return fetchLedgerBankingSuggestedPricing(ledgerId);
  }, [ledgerId]);

  return useGenericQuery(fetchFunction);
};

export const useApiFetchLedger = (
  ledgerId?: number
): UseGenericQueryResponse<LedgerResponse> => {
  const fetchFunction = useCallback(() => {
    if (!ledgerId) {
      return undefined;
    }
    return fetchLedger(ledgerId);
  }, [ledgerId]);

  return useGenericQuery(fetchFunction);
};

export const useApiGetOverriddenValidations = (
  submissionUuid: string
): UseGenericQueryResponse<OverriddenValidationsResponse[]> => {
  const fetchFunction = useCallback(() => {
    return overriddenValidations(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export type UseValidationOverrideArgs = {
  submissionUuid: string;
  updateBody: ValidationOverridePayload;
};

export type UseValidationOverrideResponse = UseGenericMutationResponse<
  MutationResponse,
  UseValidationOverrideArgs
>;

const validationOverrideApiWithArgs = async (
  args: UseValidationOverrideArgs
): Promise<MutationResponse> =>
  validationOverride(args.submissionUuid, args.updateBody);

export const useValidationOverrideApi = (): UseValidationOverrideResponse => {
  return useGenericMutation(validationOverrideApiWithArgs);
};
