import { formatCurrency, Table } from '@forward-financing/fast-forward';
import React from 'react';
import { ExceptionRequest } from '../../ExceptionsRequest/exceptionsRequest.types';

interface TableOfferProp {
  type: string;
  buyRate?: number;
  fundingAmount?: number;
  program?: string;
  termLength?: number;
}

interface ExceptionRequestOfferTableProps {
  exceptionRequest: ExceptionRequest;
}

export const ExceptionRequestOfferTable = ({
  exceptionRequest,
}: ExceptionRequestOfferTableProps): JSX.Element => {
  const getExceptionOfferValues = (req: ExceptionRequest): TableOfferProp[] => {
    const FFOffer = req.offer;
    const underwriterDecision = req.underwriterDecision;
    const arr = [
      {
        type: 'FF Offer',
        buyRate: FFOffer?.buyRate,
        fundingAmount: FFOffer?.fundingAmount,
        program: FFOffer?.program,
        termLength: FFOffer?.termLength,
      },
      {
        type: 'Exception Request',
        buyRate: req.buyRate,
        fundingAmount: req.fundingAmount,
        program: req.program,
        termLength: req.termLength,
      },
    ];
    const obj = {
      type: 'Modified Offer',
      buyRate: underwriterDecision?.buyRate,
      fundingAmount: underwriterDecision?.fundingAmount,
      program: underwriterDecision?.program,
      termLength: underwriterDecision?.termLength,
    };
    if (underwriterDecision) {
      arr.push(obj);
    }
    return arr;
  };

  return (
    <Table>
      <Table.Head>
        <Table.ColumnHeader>Offer</Table.ColumnHeader>
        <Table.ColumnHeader>Funding Amount</Table.ColumnHeader>
        <Table.ColumnHeader>Term Length</Table.ColumnHeader>
        <Table.ColumnHeader>Buy Rate</Table.ColumnHeader>
        <Table.ColumnHeader>Program</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {getExceptionOfferValues(exceptionRequest).map((item) => {
          return (
            <Table.Row key={exceptionRequest.id.toString()}>
              <Table.Cell>{item.type}</Table.Cell>
              <Table.Cell
                backgroundColor={
                  item.type === 'Modified Offer' &&
                  item.fundingAmount !== exceptionRequest.offer?.fundingAmount
                    ? 'yellow-200'
                    : undefined
                }
              >
                {item.fundingAmount && formatCurrency(item.fundingAmount)}
              </Table.Cell>
              <Table.Cell
                backgroundColor={
                  item.type === 'Modified Offer' &&
                  item.termLength !== exceptionRequest.offer?.termLength
                    ? 'yellow-200'
                    : undefined
                }
              >
                {item.termLength}
              </Table.Cell>
              <Table.Cell
                backgroundColor={
                  item.type === 'Modified Offer' &&
                  item.buyRate !== exceptionRequest.offer?.buyRate
                    ? 'yellow-200'
                    : undefined
                }
              >
                {item.buyRate}
              </Table.Cell>
              <Table.Cell
                backgroundColor={
                  item.type === 'Modified Offer' &&
                  item.program !== exceptionRequest.offer?.program
                    ? 'yellow-200'
                    : undefined
                }
              >
                {item.program}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
