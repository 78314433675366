import React from 'react';
import { Box, Flex, Grid, Text } from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { defaultTo } from 'lodash';
import {
  scoreToBgColor3,
  scoreToBgColor5,
  scoreToBgColor7,
} from './LedgerCompositeScoreConstants';

export type Score = {
  scoreName: string;
  scoreValue?: number;
  maxScoreValue?: number;
};

export type ScoresContainerBaseProps = {
  title: string;
  currentScore: string;
  maxScore: string;
  scores: Score[];
};

export const ScoresContainerBase = ({
  title,
  currentScore,
  maxScore,
  scores,
}: ScoresContainerBaseProps): JSX.Element => {
  // We split the scores into chunks of 3. Each chunk will be displayed in a column.
  // This is to prevent the columns from being too long.
  const chunkedScores = [];
  for (let i = 0; i < scores.length; i += 3) {
    chunkedScores.push(scores.slice(i, i + 3));
  }

  const getColorForScores = (
    scoreValue: number | undefined,
    maxScoreValue: number | undefined
  ): {
    bgColor: ColorVariant | undefined;
    borderColor: ColorVariant | undefined;
  } => {
    switch (maxScoreValue) {
      case 3:
        return {
          bgColor: scoreToBgColor3.get(scoreValue)?.bgColor,
          borderColor: scoreToBgColor3.get(scoreValue)?.bgColor,
        };
      case 5:
        return {
          bgColor: scoreToBgColor5.get(scoreValue)?.bgColor,
          borderColor: scoreToBgColor5.get(scoreValue)?.bgColor,
        };
      case 7:
        return {
          bgColor: scoreToBgColor7.get(scoreValue)?.bgColor,
          borderColor: scoreToBgColor7.get(scoreValue)?.bgColor,
        };
      default:
        return {
          bgColor: scoreToBgColor7.get(scoreValue)?.bgColor,
          borderColor: scoreToBgColor7.get(scoreValue)?.bgColor,
        };
    }
  };

  return (
    <Box backgroundColor="gray-100" padding={1} rounded>
      <Flex flexDirection="row" gap={2} padding={2}>
        <Text size={'l'} bold>
          {title}:
        </Text>

        <Text size={'l'}>{`${currentScore} / ${maxScore}`}</Text>
      </Flex>

      <Flex flexDirection={'column'} gap={2} p={1}>
        {chunkedScores.map((chunk) => (
          <Grid gutter key={chunk.map((score) => score.scoreName).join('')}>
            {chunk.map((score) => (
              <Grid.Item xl={4} key={score.scoreName}>
                <Box backgroundColor="white" rounded padding={1}>
                  <Grid>
                    <Grid.Item xl={8}>
                      <Flex paddingTop={1}>
                        <Text bold>{score.scoreName}</Text>
                      </Flex>
                    </Grid.Item>

                    <Grid.Item xl={4}>
                      <Flex justifyContent={'end'}>
                        <Box
                          borderColor={
                            getColorForScores(
                              defaultTo(score.scoreValue, undefined),
                              score.maxScoreValue
                            ).borderColor
                          }
                          backgroundColor={
                            getColorForScores(
                              defaultTo(score.scoreValue, undefined),
                              score.maxScoreValue
                            ).bgColor
                          }
                          py={1}
                          px={2}
                          rounded
                        >
                          <Text bold>
                            {score.scoreValue ? score.scoreValue : `N/A`}
                          </Text>
                        </Box>
                      </Flex>
                    </Grid.Item>
                  </Grid>
                </Box>
              </Grid.Item>
            ))}
          </Grid>
        ))}
      </Flex>
    </Box>
  );
};
