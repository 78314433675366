import { TextArea } from '@forward-financing/fast-forward';
import React from 'react';

type DeclineNotesProps = {
  notes: string;
  setNotes: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export const DeclineNotes = ({
  notes,
  setNotes,
}: DeclineNotesProps): JSX.Element => (
  <TextArea
    value={notes}
    onChange={setNotes}
    label="Explanation for decline"
    placeholder="Write your reason for declining this exception request"
    rows={3}
  />
);
