import { OptionShape, formatDateString } from '@forward-financing/fast-forward';
import { useMemo } from 'react';
import {
  useBulkUpdateApiWebPresence,
  useGetApiSubmission,
  useGetApiWebPresences,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  BankAccountResponse,
  CustomerResponse,
  IndustryRiskProfile,
  StateListResponse,
  SubmissionWebPresenceResponse,
  SubmissionResponse,
  UpdateOwnerRequestBody,
  OwnerResponse,
} from 'types/api/underwriting/types';
import {
  useGetApiCustomer,
  useUpdateApiCustomer,
} from 'apiHooks/underwriting/customerFetchHooks';
import { useGetApiIndustryRisks } from 'apiHooks/underwriting/industryFetchHooks';
import { useGetApiStates } from 'apiHooks/underwriting/stateFetchHooks';
import {
  useApiCreateOwners,
  useGetApiSubmissionOwners,
  useApiUpdateOwners,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { IsoDropdownResponse } from 'types/api/funding/types';
import { useApiGetIsos } from 'apiHooks/funding/isoFetchHooks';
import { UserByRoleResponse } from 'types/api/auth/types';
import { useGetApiUsersByRole } from 'apiHooks/auth/userFetchHooks';
import {
  useCreateApiBankName,
  useGetApiBankNames,
} from 'apiHooks/underwriting/bankFetchHooks';
import { useApiSendAccessLogs } from 'apiHooks/analyticsGateway/accessLogsFetchHooks';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { AccessLogsDataRequestBody } from 'types/api/analyticsGateway/types';
import {
  AccessLogs,
  BankAccount,
  BankName,
  Customer,
  CustomerAddress,
  Owner,
  Submission,
  User,
  WebPresence,
} from './SubmissionEditing.types';

const toSubmission = (
  submission?: SubmissionResponse
): Submission | undefined => {
  if (!submission) {
    return undefined;
  }

  return {
    uuid: submission.uuid,
    capitalNeeded: submission.capital_needed ?? undefined,
    useOfFunds: submission.use_of_funds ?? undefined,
    isoUuid: submission.partner_uuid ?? undefined,
    salesRepEmail: submission.sales_rep_email ?? undefined,
    emailContent: submission.email_content ?? undefined,
    decisionAnalystId: submission.decision_analyst_id ?? undefined,
    underwriter: submission.x2dc_underwriter__c ?? undefined,
    processingAnalystId: submission.processing_analyst_id ?? undefined,
    prequalAnalystName: submission.prequal_analyst_name ?? undefined,
    customerUuid: submission.customer_uuid,
    stageName: submission.stage_name,
    /**
     This is only used to fetch contacts for the Owners editing form.
     Once we support Owners updates instead of contacts this can be removed
     */
    applicationUuid: submission.application_uuid,
    renewalSubmitterEmail: submission.renewal_submitter_email ?? undefined,
    type: submission.type,
  };
};

export type UpdateSubmissionBody = {
  capitalNeeded?: number;
  useOfFunds?: string;
  isoUuid?: string;
  salesRepEmail?: string;
  emailContent?: string;
  decisionAnalystId?: number;
  underwriter?: string;
  processingAnalystId?: number;
  prequalAnalystName?: string;
  allowDecisionAnalystOverwrite?: boolean;
  renewalSubmitterEmail?: string;
};

type UseUpdateSubmissionStageResult = [
  (input: UpdateSubmissionBody) => Promise<MutationResponse>,
  { data?: Submission; error?: Error; loading: boolean }
];

export const useUpdateSubmission = (
  submissionUuid: string
): UseUpdateSubmissionStageResult => {
  const [updateSubmission, { error, loading }] = useUpdateApiSubmission();

  const updateFunction = (
    args: UpdateSubmissionBody
  ): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid,
      updateBody: {
        decision_analyst_id: args.decisionAnalystId,
        prequal_analyst_name: args.prequalAnalystName,
        x2dc_underwriter__c: args.underwriter,
        processing_analyst_id: args.processingAnalystId,
        allow_decision_analyst_overwrite: args.allowDecisionAnalystOverwrite,
        capital_needed: args.capitalNeeded
          ? `${args.capitalNeeded}`
          : undefined,
        use_of_funds: args.useOfFunds,
        partner_uuid: args.isoUuid,
        sales_rep_email: args.salesRepEmail,
        email_content: args.emailContent,
        renewal_submitter_email: args.renewalSubmitterEmail,
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

type UseSubmissionResult = UseGenericQueryResponse<Submission>;

export const useGetSubmission = (
  submissionUuid: string
): UseSubmissionResult => {
  const useToSubmissionMemoized = (
    submission?: SubmissionResponse
  ): Submission | undefined => {
    return useMemo(() => toSubmission(submission), [submission]);
  };
  return useGenericFeatureQuery(
    useGetApiSubmission,
    useToSubmissionMemoized,
    submissionUuid
  );
};

export type UpdateCustomerBody = {
  legalName?: string;
  dba?: string;
  phoneNumber?: string;
  fein?: string;
  businessStartDate?: string;
  industryId?: number;
  entityType?: string;
  customerAddress?: CustomerAddress;
  bankAccount?: BankAccount;
};

type UseUpdateCustomerResult = [
  (input: UpdateCustomerBody) => Promise<MutationResponse>,
  { data?: Customer; error?: Error; loading: boolean }
];

const toBankAccount = (
  response?: BankAccountResponse
): BankAccount | undefined => {
  if (!response) {
    return undefined;
  }
  return {
    id: response.id ?? undefined,
    accountHolderFullName: response.account_holder_full_name ?? undefined,
    bankName: response.bank_name ?? undefined,
    city: response.city ?? undefined,
    state: response.state ?? undefined,
    zip: response.zip ?? undefined,
    accountNumber: response.account_number ?? undefined,
    routingNumber: response.routing_number ?? undefined,
    bankId: response.bank_id ?? undefined,
  };
};

const toBankAccountResponse = (
  bank?: BankAccount
): BankAccountResponse | undefined => {
  if (!bank) {
    return undefined;
  }
  return {
    id: bank.id ?? null,
    account_holder_full_name: bank.accountHolderFullName ?? null,
    bank_name: bank.bankName ?? null,
    city: bank.city ?? null,
    state: bank.state ?? null,
    zip: bank.zip ?? null,
    account_number: bank.accountNumber ?? null,
    routing_number: bank.routingNumber ?? null,
    bank_id: bank.bankId ?? null,
  };
};

const toCustomer = (response?: CustomerResponse): Customer | undefined => {
  if (!response) {
    return undefined;
  }
  return {
    uuid: response.uuid,
    legalName: response.legal_name,
    dba: response.name,
    phoneNumber: response.phone ?? undefined,
    fein: response.fein ?? undefined,
    businessStartDate: response.started_on ?? undefined,
    industryId: response.industry_id ?? undefined,
    industryName: response.industry_name ?? undefined,
    entityType: response.entity_type ?? undefined,
    customerAddress: response.address ?? undefined,
    bankAccount: toBankAccount(response.bank_account ?? undefined),
  };
};

export const useUpdateCustomer = (
  customerUuid: string
): UseUpdateCustomerResult => {
  const [updateCustomer, { error, loading }] = useUpdateApiCustomer();

  const updateFunction = (
    args: UpdateCustomerBody
  ): Promise<MutationResponse> => {
    return updateCustomer({
      customerUuid,
      updateBody: {
        legal_name: args.legalName,
        name: args.dba,
        current_address_attributes: args.customerAddress,
        entity_type: args.entityType,
        industry_id: args.industryId,
        started_on: args.businessStartDate,
        fein: args.fein,
        phone: args.phoneNumber,
        bank_account_attributes: toBankAccountResponse(args.bankAccount),
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

type UseCustomerResult = UseGenericQueryResponse<Customer>;

export const useGetCustomer = (customerUuid?: string): UseCustomerResult => {
  const useToCustomerMemoized = (
    customer?: CustomerResponse
  ): Customer | undefined => {
    return useMemo(() => toCustomer(customer), [customer]);
  };
  return useGenericFeatureQuery(
    useGetApiCustomer,
    useToCustomerMemoized,
    customerUuid
  );
};

const toWebPresence = (
  webPresence?: SubmissionWebPresenceResponse
): WebPresence | undefined => {
  if (!webPresence) {
    return undefined;
  }

  return {
    businessWebsite: webPresence.business_website ?? undefined,
    facebook: webPresence.facebook ?? undefined,
    instagram: webPresence.instagram ?? undefined,
    yelp: webPresence.yelp ?? undefined,
    other: webPresence.other ?? undefined,
  };
};

type UseWebPresenceResult = UseGenericQueryResponse<WebPresence>;

export const useGetWebPresences = (
  submissionUuid?: string
): UseWebPresenceResult => {
  const useToWebPresenceMemoized = (
    webPresence?: SubmissionWebPresenceResponse
  ): WebPresence | undefined => {
    return useMemo(() => toWebPresence(webPresence), [webPresence]);
  };

  return useGenericFeatureQuery(
    useGetApiWebPresences,
    useToWebPresenceMemoized,
    submissionUuid
  );
};

type UseBulkUpdateWebPresencesResult = [
  (input: WebPresence) => Promise<MutationResponse>,
  { data?: WebPresence; error?: Error; loading: boolean }
];

export const useBulkUpdateWebPresences = (
  submissionUuid: string
): UseBulkUpdateWebPresencesResult => {
  const [updateWebPresences, { error, loading }] =
    useBulkUpdateApiWebPresence();

  const updateFunction = (args: WebPresence): Promise<MutationResponse> => {
    return updateWebPresences({
      submissionUuid,
      body: {
        business_website: args.businessWebsite,
        facebook: args.facebook,
        instagram: args.instagram,
        yelp: args.yelp,
        other: args.other,
      },
    });
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

const toIndustryOptions = (industry: IndustryRiskProfile): OptionShape => {
  return { value: `${industry.id}`, text: industry.name };
};
export const useIndustryOptions = (): UseGenericQueryResponse<
  OptionShape[]
> => {
  return useGenericFeatureQuery(useGetApiIndustryRisks, (data) =>
    data ? data.map(toIndustryOptions) : []
  );
};

const toStateOptions = (state: StateListResponse): OptionShape => {
  return { value: state.abbreviation, text: state.name };
};
export const useGetStates = (): UseGenericQueryResponse<OptionShape[]> => {
  return useGenericFeatureQuery(useGetApiStates, (data) =>
    data ? data.map(toStateOptions) : []
  );
};

const toOwnerResponse = (owner: Owner): UpdateOwnerRequestBody => {
  return {
    id: owner.id,
    uuid: owner.uuid,
    first_name: owner.firstName,
    last_name: owner.lastName,
    born_on: owner.bornOn || null,
    email: owner.email,
    home_phone: owner.homePhone || null,
    cell_phone: owner.cellPhone || null,
    ssn: owner.ssn || null,
    title: owner.title,
    ownership_percentage: owner.ownershipPercentage,
    current_address_attributes: owner.address,
    deleted_at: owner.deletedAt ?? null,
    _destroy: owner.destroy ?? null,
  };
};

type UseUpdateOwnersResult = [
  (input: Owner[]) => Promise<MutationResponse>,
  { data?: Owner[]; error?: Error; loading: boolean }
];

const toOwner = (owner: OwnerResponse): Owner => {
  return {
    id: owner.id,
    uuid: owner.uuid,
    firstName: owner.first_name,
    lastName: owner.last_name,
    bornOn: owner.born_on ?? undefined,
    email: owner.email,
    homePhone: owner.home_phone ?? undefined,
    cellPhone: owner.cell_phone ?? undefined,
    ssn: owner.ssn ?? undefined,
    title: owner.title,
    /**
     * For some reason the BE returns a decimal value forowner.ownership_percentage. This
     is not supported, so we removed the decimal by using this Bitwise OR. Which converts
     the value to 32-bit signed integers. This conversion automatically truncates any decimal
     part and keeps only the integer part of the number
     */
    ownershipPercentage: owner.ownership_percentage
      ? +owner.ownership_percentage | 0
      : undefined,
    address: {
      street1: owner.address.street1 ?? '',
      street2: owner.address.street2 ?? '',
      city: owner.address.city ?? '',
      state: owner.address.state ?? '',
      zip: owner.address.zip ?? '',
    },
    deletedAt: owner.deleted_at
      ? formatDateString(owner.deleted_at)
      : undefined,
    ssnNotPresent: owner.ssn === null,
    dobNotPresent: owner.born_on === null,
  };
};

export const useBulkUpdateOwners = (): UseUpdateOwnersResult => {
  const [updateOwners, { data, error, loading }] = useApiUpdateOwners();

  const updateFunction = (args: Owner[]): Promise<MutationResponse> => {
    return updateOwners(
      args.map((owner) => ({
        ownerUuid: owner.uuid,
        updateBody: toOwnerResponse(owner),
      }))
    );
  };

  const updatedOwners = useMemo(() => data?.map(toOwner), [data]);

  return [
    updateFunction,
    {
      data: updatedOwners,
      error,
      loading,
    },
  ];
};

type UseOwnerResult = UseGenericQueryResponse<Owner[]>;

export const useGetOwners = (submissionUuid?: string): UseOwnerResult =>
  useGenericFeatureQuery(
    useGetApiSubmissionOwners,
    (data) => data?.map(toOwner),
    submissionUuid
  );

export type IsoOptionShape = OptionShape & { email: string };
const toIsoOptions = (iso: IsoDropdownResponse): IsoOptionShape => {
  return { value: `${iso.uuid}`, text: iso.label, email: iso.email };
};

export const useGetIsos = (): UseGenericQueryResponse<IsoOptionShape[]> => {
  return useGenericFeatureQuery(useApiGetIsos, (data) =>
    data ? data.map(toIsoOptions) : []
  );
};

const toUsers = (user: UserByRoleResponse): User => {
  return {
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
    role: user.role,
    subRole: user.sub_role,
  };
};

const mappingUsers = (users?: UserByRoleResponse[]): User[] => {
  if (!users) {
    return [];
  }
  return users?.map(toUsers);
};

export const useGetUsersByRole = (
  role?: string
): UseGenericQueryResponse<User[]> =>
  useGenericFeatureQuery(useGetApiUsersByRole, mappingUsers, role);

// Ignoring this temporarily until the component is built along with tests.
/* istanbul ignore  next */
export const useBankNames = (): UseGenericQueryResponse<BankName[]> => {
  return useGenericFeatureQuery(useGetApiBankNames, (data) => data);
};

type UseCreateBankNameResult = [
  (bankName: string) => Promise<MutationResponse>,
  { data?: BankName; error?: Error; loading: boolean }
];

// Ignoring this temporarily until the component is built along with tests.
/* istanbul ignore next */
export const useCreateBankName = (): UseCreateBankNameResult => {
  const [createBankName, { error, loading }] = useCreateApiBankName();

  const createFunction = (bankName: string): Promise<MutationResponse> => {
    return createBankName(bankName);
  };

  return [
    createFunction,
    {
      error,
      loading,
    },
  ];
};

type UseSendLogsResult = [
  (input: AccessLogs) => Promise<MutationResponse>,
  { error?: Error; loading: boolean }
];

const toAccessLogsRequest = (
  accessLogs: AccessLogs
): AccessLogsDataRequestBody => {
  return {
    field_name: accessLogs.fieldName,
    object_uuid: accessLogs.objectUuid,
    name: accessLogs.name,
    action: accessLogs.action,
    user_uuid: accessLogs.userUuid,
    user_email: accessLogs.userEmail,
    source: accessLogs.source,
    timestamp: accessLogs.timestamp,
    object_type: accessLogs.objectType,
  };
};

export const useSendLogs = (): UseSendLogsResult => {
  const [sendAccessLogs, { error, loading }] = useApiSendAccessLogs();

  const updateFunction = (logs: AccessLogs): Promise<MutationResponse> => {
    return sendAccessLogs(toAccessLogsRequest(logs));
  };

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

type UseCreateOwnersArgs = { submissionUuid: string; owners: Owner[] };

export const useCreateOwners = (): UseGenericMutationResponse<
  Owner[],
  UseCreateOwnersArgs
> => {
  const [apiCreateFn, { error, data, ...rest }] = useApiCreateOwners();

  const createFn = async ({
    submissionUuid,
    owners,
  }: UseCreateOwnersArgs): Promise<Pick<MutationResponse, 'success'>> =>
    apiCreateFn({
      submissionUuid,
      body: owners.map(toOwnerResponse),
    });

  return [
    createFn,
    { error, data: data ? data.map(toOwner) : undefined, ...rest },
  ];
};
