import { v4 as uuidv4 } from 'uuid';
import {
  Contact,
  Account,
  Application,
  WebPresences,
  WebPresenceResponse,
  Owner,
  WebPresenceType,
  Submission,
} from '../codecs';
import {
  formatDateString,
  mdyFormat,
  ymdFormat,
} from '../../../helpers/string/dateUtils';
import { featureFlags } from '../../../helpers/featureFlags';
import {
  generateEmptyContact,
  generateInitialPrequalState,
  generateEmptyAddress,
  generateEmptyDecline,
} from './index';

// We don't need to change the date format in the new Owner form
// We can remove this after the users start using the new form
const formatBornOn = (date: string | null): string => {
  let bornOn: string;

  if (date) {
    bornOn = featureFlags.show_updated_prequal_form
      ? date.replace('/', '-')
      : formatDateString(date, ymdFormat, mdyFormat);
  } else {
    bornOn = '';
  }

  return bornOn;
};

export function buildOwnerModels(
  modelData: Contact[] | Owner[],
  stepCompleted = true
): Contact[] | Owner[] {
  const models: Contact[] | Owner[] =
    modelData.length < 1
      ? [generateEmptyContact()]
      : modelData.map((model: Contact | Owner) =>
          Object.assign({}, model, {
            key: model.uuid,
            born_on: formatBornOn(model.born_on),
            ssnNotPresent:
              (model.ssn === null || model.ssn === '') && stepCompleted,
            bornOnNotPresent: model.born_on === null && stepCompleted,
            ownership_percentage: model.ownership_percentage,
            address:
              model.address === null ? generateEmptyAddress() : model.address,
          })
        );

  return models;
}

export function buildContactsFromOwners(owners: Owner[]): Contact[] {
  const contacts: Contact[] = owners.map(
    (owner: Owner): Contact => ({
      ...owner,
      id: undefined,
      uuid: '',
      born_on: formatBornOn(owner.born_on),
      address: owner.address || generateEmptyAddress(),
      ssnNotPresent: owner.ssn === null,
      bornOnNotPresent: owner.born_on === null,
      key: uuidv4(),
    })
  );

  return contacts;
}

export function buildWebPresences(
  webPresenceData: WebPresenceResponse
): WebPresences {
  return {
    business_website: {
      web_presence_type: WebPresenceType.Website,
      url: webPresenceData.business_website,
      urlChanged: false,
    },
    facebook: {
      web_presence_type: WebPresenceType.Facebook,
      url: webPresenceData.facebook,
      urlChanged: false,
    },
    instagram: {
      web_presence_type: WebPresenceType.Instagram,
      url: webPresenceData.instagram,
      urlChanged: false,
    },
    yelp: {
      web_presence_type: WebPresenceType.Yelp,
      url: webPresenceData.yelp,
      urlChanged: false,
    },
    other: {
      web_presence_type: WebPresenceType.Other,
      url: webPresenceData.other,
      urlChanged: false,
    },
  };
}

// stepCompleted is default true for Submission Edit path
export function buildAccount(
  accountData: Account,
  stepCompleted = true
): Account {
  const address =
    accountData.address === null ? generateEmptyAddress() : accountData.address;
  return {
    ...accountData,
    address: address,
    started_on: accountData.started_on
      ? formatDateString(accountData.started_on, ymdFormat, mdyFormat)
      : '',
    feinNotPresent: accountData.fein === null && stepCompleted,
    dbaSameAsLegalName: accountData.name === accountData.legal_name,
    phoneNotPresent: accountData.phone === null && stepCompleted,
  };
}

export function buildApplication(
  applicationData: Application,
  fromSubmissionEdit = false
): Application {
  const prequal_state_attributes = applicationData.prequal_state_attributes
    ? applicationData.prequal_state_attributes
    : generateInitialPrequalState();
  const prequal_started_at = prequal_state_attributes.prequal_started_at;
  const prequal_completed_at = prequal_state_attributes.prequal_completed_at;

  const application: Application = {
    ...applicationData,
    capital_needed: applicationData.capital_needed
      ? applicationData.capital_needed.replace(',', '')
      : '',
    capitalNeededNotPresent:
      (applicationData.capital_needed || '') === '' &&
      (fromSubmissionEdit || prequal_state_attributes.other_info_completed),
    prequal_state_attributes: {
      ...prequal_state_attributes,
      prequal_started_at: prequal_started_at
        ? new Date(prequal_started_at)
        : new Date(),
      prequal_completed_at: prequal_completed_at
        ? new Date(prequal_completed_at)
        : null,
    },
  };

  return application;
}

/* eslint-disable promise/prefer-await-to-then */
export function buildSubmission(
  applicationUuid: string,
  getApplication: (applicationUuid: string) => Promise<Application>,
  getAccountOrCustomer: (applicationUuid: string) => Promise<Account>,
  getContacts: (applicationUuid: string) => Promise<Contact[]>,
  fetchCustomerOwners: (customerUuid: string) => Promise<Owner[]>,
  fromSubmissionEdit = false
): Promise<Submission> {
  return Promise.all([
    getApplication(applicationUuid),
    featureFlags.use_owner_not_contact && fromSubmissionEdit
      ? []
      : getContacts(applicationUuid),
  ])
    .then(([application, contacts]) => {
      return Promise.all([
        application,
        fromSubmissionEdit && featureFlags.use_owner_not_contact
          ? fetchCustomerOwners(application.customer_uuid || '')
          : contacts,
        fromSubmissionEdit
          ? getAccountOrCustomer(application.customer_uuid || '')
          : getAccountOrCustomer(applicationUuid),
      ]);
    })
    .then(([application, contacts, account]) => {
      const submission: Submission = {
        ...buildApplication(application, fromSubmissionEdit),
        account: buildAccount(
          account,
          application.prequal_state_attributes === null
            ? fromSubmissionEdit
            : application.prequal_state_attributes.account_completed
        ),
        contacts: buildOwnerModels(
          contacts,
          application.prequal_state_attributes === null
            ? fromSubmissionEdit
            : application.prequal_state_attributes.owners_completed
        ),
        decline: generateEmptyDecline(),
        submission_source: application.submission_source,
      };
      return submission;
    });
}

/* eslint-enable promise/prefer-await-to-then */
