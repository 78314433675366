import React, { useContext, useMemo, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  DescriptionList,
  Divider,
  Flex,
  Grid,
  Icon,
  Loading,
  Modal,
  Subheading,
  Text,
  TextArea,
} from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { isAfterUnderwriting } from 'helpers/utils';
import { Submission } from '../DealScoringContainer.types';
import {
  useRefreshCompositeScore,
  useScoringComposite,
} from '../DealScoringFetchHooks';
import { StipulationManager } from '../../StipulationManager/StipulationManager';
import { exceptionRequestContext } from '../../SubmissionUnderwriting/ExceptionRequest/ExceptionRequestContext';
import { RevenueSummaryTable } from './RevenueSummaryTable';
import { Ledger } from './ledger.types';
import {
  LedgerToolbox,
  SAVE_LEDGER_SUCCESS_TEXT,
  SEND_LEDGER_SUCCESS_TEXT,
} from './LedgerToolbox';
import { OffersContainer } from './Offers/OffersContainer';
import { BuyRatesContainer } from './BuyRates/BuyRatesContainer';
import {
  useGetOverriddenValidations,
  useGetUser,
  useLedgerOffers,
  useValidationOverride,
} from './ledgerHooks';
import { useLedgersValidationContext } from './LedgerValidationContext/LedgerValidationContext';
import { OverrideValidationCard } from './OverrideValidation/OverrideValidationCard';
import { LedgerCompositeScore } from './LedgerCompositeScore';

export type LedgerContainerProps = {
  ledger: Ledger;
  currentSubmissionUuid: string;
  submission: Submission;
  refetchLedgers: () => void;
  isRenewal?: boolean;
};

export const areAllElementsInArray = (
  sourceArray: string[] = [],
  targetArray: string[] = []
): boolean => {
  const lowerCaseBSet = new Set(targetArray.map((item) => item.toLowerCase()));

  return sourceArray.every((item) => lowerCaseBSet.has(item.toLowerCase()));
};

export const LedgerContainer = ({
  ledger,
  currentSubmissionUuid,
  submission,
  refetchLedgers,
  isRenewal,
}: LedgerContainerProps): JSX.Element => {
  const { modifyExceptionRequest, updateExceptionRequest } = useContext(
    exceptionRequestContext
  );
  const {
    ledgerToUpdate,
    handleChangeLedger,
    isProgramExplanationInvalid,
    isProgramExplanationRequired,
  } = useLedgersValidationContext();

  const [successText, setSuccessText] = useState<string | undefined>('');
  const [isOverrideValidationModalOpen, setIsOverrideValidationModalOpen] =
    useState(false);
  const [isFormNotSaved, setIsFormNotSaved] = useState<boolean>(false);
  const [overrideNotes, setOverrideNotes] = useState('');

  const {
    data: compositeScore,
    loading: compositeScoreLoading,
    error: compositeScoreError,
  } = useScoringComposite(submission.uuid);

  const {
    data: overriddenValidations,
    loading: overriddenValidationsLoading,
    error: overriddenValidationsError,
    refetch: overriddenValidationsRefetch,
  } = useGetOverriddenValidations(submission.uuid);

  const [
    overrideValidations,
    { loading: overrideValidationsLoading, error: overrideValidationsError },
  ] = useValidationOverride();

  const {
    data: offersData,
    error: offersError,
    loading: offersLoading,
    refetch: refetchOffers,
  } = useLedgerOffers({
    submissionUuid: submission.uuid,
    ledgerId: ledger.id,
  });

  const handleAction = async (
    action: () => Promise<MutationResponse>,
    successMessage: string,
    afterFetchAction?: () => void
  ): Promise<void> => {
    setSuccessText(undefined);

    const { success } = await action();

    if (success) {
      setSuccessText(successMessage);
      refetchLedgers?.();
      afterFetchAction?.();
      setIsFormNotSaved(false);
      if (modifyExceptionRequest?.isLedgerOpen) {
        updateExceptionRequest({
          isLedgerCloned: true,
        });
      }
      [SAVE_LEDGER_SUCCESS_TEXT, SEND_LEDGER_SUCCESS_TEXT].includes(
        successMessage
      ) && refetchOffers();
    }
  };

  const handleSendLedger = async (
    patchLedger: () => Promise<MutationResponse>,
    successMessage: string,
    closeConfirmationModal: () => void
  ): Promise<void> => {
    setSuccessText(undefined);

    const { success: actionSuccess } = await patchLedger();

    if (actionSuccess) {
      overriddenValidationsRefetch();
      refetchLedgers();
      closeConfirmationModal();
      setSuccessText(successMessage);
      if (modifyExceptionRequest.isLedgerOpen) {
        updateExceptionRequest({
          isLedgerOpen: false,
          shouldModifyException: false,
          shouldReFetchException: true,
        });
      }
    }
  };

  const handleOverrideReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void =>
    handleChangeLedger({
      overrideBuyRatesReason: event.target.value,
    });

  const editIsEnabled = useMemo(
    () => !!ledgerToUpdate.overrideBuyRates && ledgerToUpdate.program !== 0,
    [ledgerToUpdate]
  );

  const handleOverrideValidations = async (): Promise<void> => {
    if (!submission) {
      return;
    }

    const { success } = await overrideValidations({
      submissionUuid: submission.uuid,
      updateBody: {
        note: overrideNotes,
        stage: submission.stage,
        missing_attributes: ledger.validationErrorMessage || [],
      },
    });

    if (success) {
      setIsOverrideValidationModalOpen(false);
      overriddenValidationsRefetch();
    }
  };

  const latestOverriddenValidation =
    overriddenValidations?.[overriddenValidations.length - 1];

  const { data: underwriter } = useGetUser(
    latestOverriddenValidation?.createdById
  );

  const [refreshScore, { data: refreshScoreData, error: refreshScoreError }] =
    useRefreshCompositeScore(submission.uuid);

  const isRenewalSubmission = submission.type === 'Renewal';
  const isInProcessingStage = submission?.stage === 'Processing';
  const isCurrentSubmission = submission?.uuid === currentSubmissionUuid;
  const shouldDisableToolbox =
    (submission && isAfterUnderwriting(submission.stage)) ||
    isInProcessingStage ||
    !isCurrentSubmission;
  const overrideValidationText = (): string =>
    ledger.validationErrorMessage && ledger.validationErrorMessage.length > 1
      ? 'Override validations'
      : 'Override validation';

  const refetchLedgersComplete = async (): Promise<void> => {
    refetchLedgers();
    await refreshScore();
  };

  return (
    <Flex flexDirection={'column'} gap={3}>
      {successText && (
        <Box m={3}>
          <Banner variant="success" autoDismissTime={5000} dismissable={'auto'}>
            {successText}
          </Banner>
        </Box>
      )}

      {isFormNotSaved && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      {compositeScoreError && <Banner>{compositeScoreError.message}</Banner>}
      {refreshScoreError && <Banner>{refreshScoreError.message}</Banner>}
      {overriddenValidationsError && (
        <Banner>{overriddenValidationsError.message}</Banner>
      )}

      <Modal
        trigger={<></>}
        isOpen={isOverrideValidationModalOpen}
        onOpenChange={() => {
          setIsOverrideValidationModalOpen((prev) => !prev);
        }}
        title={`${overrideValidationText()}?`}
        body={
          overriddenValidationsLoading ? (
            <Loading />
          ) : (
            <Flex gap={2} flexDirection="column" alignItems="flex-start">
              {overrideValidationsError && (
                <Banner>{overrideValidationsError.message}</Banner>
              )}

              <Icon name={'circle-exclamation'} color="danger" size="lg" />

              <Flex gap={1} flexDirection="column" alignItems="flex-start">
                <Text>
                  This submission cannot be sent until it has the following
                  data:
                </Text>

                {ledger.validationErrorMessage &&
                  ledger.validationErrorMessage.map((item) => (
                    <Text key={item}>- {item}</Text>
                  ))}
              </Flex>

              <TextArea
                label="Note"
                onChange={(e) => {
                  setOverrideNotes(e.target.value);
                }}
              />

              <Flex gap={2}>
                {overrideValidationsLoading ? (
                  <Loading />
                ) : (
                  <Button
                    disabled={!overrideNotes}
                    onClick={handleOverrideValidations}
                  >
                    {overrideValidationText()}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setIsOverrideValidationModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          )
        }
      />

      <LedgerToolbox
        ledger={ledger}
        submissionUuid={submission.uuid}
        shouldDisableToolbox={shouldDisableToolbox}
        handleAction={handleAction}
        editIsEnabled={editIsEnabled}
        handleSendLedger={handleSendLedger}
        setIsOverrideValidationModalOpen={setIsOverrideValidationModalOpen}
        refetchLedgers={refetchLedgersComplete}
      />

      <Grid gutter>
        <Grid.Item xl={12}>
          <OverrideValidationCard
            latestOverriddenValidation={latestOverriddenValidation}
            underwriter={underwriter}
          />

          <LedgerCompositeScore
            compositeScoreLoading={compositeScoreLoading}
            compositeScore={compositeScore}
            refreshedComppositoScore={refreshScoreData}
          />
        </Grid.Item>
      </Grid>

      <Flex flexDirection={'column'}>
        <Subheading>Revenue Summary</Subheading>

        <Box>
          <RevenueSummaryTable
            ledger={ledgerToUpdate}
            amountRequested={submission?.amountRequested}
            isoUuid={submission?.isoUuid}
            onChange={handleChangeLedger}
            readOnly={ledger.lock}
          />
          <Divider orientation="horizontal" />
          <DescriptionList>
            <Grid gutter>
              <Grid.Item xl={4}>
                <DescriptionList.Item>
                  <Flex
                    alignItems={'top'}
                    backgroundColor={'gray-100'}
                    p={2}
                    rounded={true}
                  >
                    <DescriptionList.Term>
                      External ISO Notes
                    </DescriptionList.Term>
                    <DescriptionList.Details>
                      <TextArea
                        label="External ISO Notes"
                        hiddenLabel
                        rows={4}
                        name="externalISONotes"
                        value={ledgerToUpdate.uwNotes}
                        readOnly={ledger.lock}
                        onChange={(event) => {
                          handleChangeLedger({ uwNotes: event.target.value });
                        }}
                      />
                    </DescriptionList.Details>
                  </Flex>
                </DescriptionList.Item>
              </Grid.Item>

              <Grid.Item xl={4}>
                <DescriptionList.Item>
                  <Flex
                    alignItems={'top'}
                    backgroundColor={'gray-100'}
                    p={2}
                    rounded={true}
                  >
                    <DescriptionList.Term>
                      Program Explanation
                    </DescriptionList.Term>
                    <DescriptionList.Details>
                      <TextArea
                        label="Program Explanation"
                        hiddenLabel
                        rows={4}
                        name="programExplanation"
                        readOnly={ledger.lock}
                        value={ledgerToUpdate.programExplanation}
                        onChange={(event) =>
                          handleChangeLedger({
                            programExplanation: event.target.value,
                          })
                        }
                        required={
                          isProgramExplanationInvalid &&
                          isProgramExplanationRequired &&
                          !isRenewalSubmission
                        }
                      />
                      {isProgramExplanationInvalid &&
                        isProgramExplanationRequired && (
                          <Text bold color="danger" size="s">
                            *Required because the program is Starter/Core or
                            does not match with suggested pricing.
                          </Text>
                        )}
                    </DescriptionList.Details>
                  </Flex>
                </DescriptionList.Item>
              </Grid.Item>
              <Grid.Item xl={4}>
                {ledgerToUpdate.overrideBuyRates && (
                  <>
                    <DescriptionList.Item>
                      <Flex
                        alignItems={'top'}
                        backgroundColor={'gray-100'}
                        p={2}
                        rounded={true}
                      >
                        <DescriptionList.Term>
                          Override Explanation
                        </DescriptionList.Term>
                        <DescriptionList.Details>
                          <TextArea
                            label="Override Explanation"
                            hiddenLabel
                            rows={4}
                            readOnly={ledger.lock}
                            name="overrideExplanation"
                            value={ledgerToUpdate.overrideBuyRatesReason}
                            onChange={handleOverrideReason}
                          />
                        </DescriptionList.Details>
                      </Flex>
                    </DescriptionList.Item>
                  </>
                )}
              </Grid.Item>
            </Grid>
          </DescriptionList>

          <BuyRatesContainer
            ledger={ledgerToUpdate}
            readOnly={ledger.lock}
            submissionUuid={submission.uuid}
            handleChangeLedger={handleChangeLedger}
          />
        </Box>
      </Flex>

      <OffersContainer
        customerUuid={submission?.customerUuid}
        eligibleForWeekly={ledgerToUpdate.eligibleForWeekly}
        handleChangeLedger={handleChangeLedger}
        isRenewal={isRenewal}
        ledger={ledger}
        readOnly={ledger.lock}
        submissionUuid={submission.uuid}
        offersData={offersData}
        offersError={offersError}
        offersLoading={offersLoading}
      />

      <StipulationManager submissionUuid={submission.uuid} />
    </Flex>
  );
};
