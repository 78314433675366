import React from 'react';
import {
  formatCurrency,
  Loading,
  Table,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import {
  useRenewalComparisonSubmission,
  useSubmissionCompositeScore,
} from '../renewalComparisonHooks';

type CurrentSubmissionFundingInfoTableProps = {
  submissionUuid: string;
  mostRecentAdvanceUuid: string | undefined;
};

const calculateToNetFiftyPercent = (
  previousBalance: string | undefined
): string => {
  if (!previousBalance || isNaN(Number(previousBalance))) {
    return 'Unknown';
  }

  const previousBalanceNumber = Number(previousBalance);

  return `${formatCurrency(previousBalanceNumber * 2)}`;
};

export const CurrentSubmissionFundingInfoTable = ({
  submissionUuid,
  mostRecentAdvanceUuid,
}: CurrentSubmissionFundingInfoTableProps): JSX.Element => {
  // Need the previous funded submission to calculate the "To Net 50%" value
  const {
    data: previousRenewalComparisonsData,
    loading: previousRenewalComparisonLoading,
  } = useRenewalComparisonSubmission(mostRecentAdvanceUuid);

  const { data: compositeScoreData, loading: compositeScoreLoading } =
    useSubmissionCompositeScore(submissionUuid);

  if (previousRenewalComparisonLoading || compositeScoreLoading) {
    return (
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
        </Table.Head>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Loading />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  return (
    <Table>
      <Table.ColGroup>
        <Table.Col />
        <Table.Col />
      </Table.ColGroup>

      <Table.Head>
        <Table.ColumnHeader>Current Submission</Table.ColumnHeader>
        <Table.ColumnHeader />
      </Table.Head>

      <Table.Body>
        <Table.Row>
          {/* Previously named "Minimum To Approve" in old Renewal Comparison */}
          <Table.RowHeader>To Net 50%</Table.RowHeader>
          <Table.Cell>
            {calculateToNetFiftyPercent(
              previousRenewalComparisonsData?.balance
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Tier</Table.RowHeader>
          <Table.Cell>{defaultTo(compositeScoreData?.tier, 'N/A')}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
