import React, { useState } from 'react';
import {
  IconButton,
  Modal,
  Table,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { SubmissionLogs } from '../applicationSnapshot.types';
import { UserFullNameText } from './UserFullNameText';

export type CreditCommitteeAssignmentHistoryModalProps = {
  historyData?: SubmissionLogs[];
};

export const CreditCommitteeAssignmentHistoryModal = ({
  historyData,
}: CreditCommitteeAssignmentHistoryModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      title={'Decision Committee Assignment History'}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      trigger={
        <IconButton
          icon={'history'}
          label={'Decision Committee Assignment History'}
          hiddenLabel
        />
      }
      body={
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Assigned To</Table.ColumnHeader>
            <Table.ColumnHeader>Assigned By</Table.ColumnHeader>
            <Table.ColumnHeader>Created At</Table.ColumnHeader>
            <Table.ColumnHeader>Updated At</Table.ColumnHeader>
          </Table.Head>

          <Table.Body>
            {historyData && historyData.length > 0 ? (
              historyData.map((history) => (
                <Table.Row key={history.createdAt}>
                  {/* Credit Committee data returns a full name string for assignedTo */}
                  <Table.Cell>{history.assignedTo}</Table.Cell>

                  <Table.Cell>
                    <UserFullNameText userId={history.assignedBy} />
                  </Table.Cell>

                  <Table.Cell>
                    {formatDateTimeString(history.createdAt)}
                  </Table.Cell>

                  <Table.Cell>
                    {formatDateTimeString(history.updatedAt)}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  No assignment history data available.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      }
    />
  );
};
