/* eslint-disable promise/prefer-await-to-then */

import React, { useEffect, useState } from 'react';

import {
  Banner,
  Box,
  Loading,
  PageContainer,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { addressToString } from './BusinessSummary';
import { BusinessSummaryHeader } from './BusinessSummaryHeader';
import {
  fetchExperianBusinessCredit,
  ExperianBusinessCreditData,
} from './businessCreditFetchUtils';
// Use this json file to use mock data
// import mockData from './fixtures/business-credit.json';
import { PublicRecordsInterface } from './codecs/PublicRecords.codec';
import { UCCInterface } from './codecs/UniformCommercialCode.codec';
import { InquiriesInterface } from './codecs/Inquiries.codec';
import { BusinessSummaryInterface } from './codecs/BusinessSummary.codec';
import { TradeLinesData } from './codecs/TradeLines.codec';
import { BusinessCreditTabs } from './BusinessCreditTabs';

const getBusinessSummaryData = (
  data: ExperianBusinessCreditData
): BusinessSummaryInterface => data.business_summary;
const getPublicRecordsData = (
  data: ExperianBusinessCreditData
): PublicRecordsInterface => data.public_records;
const getInquiriesData = (
  data: ExperianBusinessCreditData
): InquiriesInterface => data.inquiries;
const getUCCData = (data: ExperianBusinessCreditData): UCCInterface =>
  data.uniform_commercial_code_filings;
const getTradelinesData = (data: ExperianBusinessCreditData): TradeLinesData =>
  data.trade_lines;

export interface BusinessCreditProps {
  referenceId?: string;
  referenceType?: string;
}

export const BusinessCredit = ({
  referenceId,
  referenceType,
}: BusinessCreditProps): JSX.Element => {
  const [data, setData] = useState<ExperianBusinessCreditData | null>(
    // swap these two lines to use mockData
    // mockData
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!data) {
      if (!referenceId || !referenceType) {
        setError(
          'Cannot fetch Experian data. Missing referenceId and/or referenceType'
        );
        setLoading(false);
      } else {
        fetchExperianBusinessCredit(referenceId, referenceType)
          /* eslint-disable promise/prefer-await-to-then */
          .then((response) => {
            setData(response);
          })
          .catch((e: unknown) => {
            const errorObject = toError(e);
            setError(errorObject.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [referenceId, referenceType, data]);

  useEffect(() => {
    document.title = 'Business Credit';
  }, []);

  return (
    <Box>
      {loading && <Loading />}
      <Box>
        {data && (
          <BusinessSummaryHeader
            name={getBusinessSummaryData(data).business_name}
            id={getBusinessSummaryData(data).business_identification_number}
            address={addressToString(getBusinessSummaryData(data))}
          />
        )}
      </Box>
      <PageContainer>
        {data && (
          <BusinessCreditTabs
            public_records={getPublicRecordsData(data)}
            ucc={getUCCData(data)}
            inquiries={getInquiriesData(data)}
            business_summary={getBusinessSummaryData(data)}
            trade_lines={getTradelinesData(data)}
          />
        )}

        {error && <Banner dismissable={false}>{error}</Banner>}
      </PageContainer>
    </Box>
  );
};
