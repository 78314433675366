import Rollbar from 'rollbar';
import { Logger } from './logger';
import { Ilogger, UserLogMeta } from './types';

/**
 * Symbol name for a method that sets information about the
 * current user on the logger implementation. There is a
 * VERY strong chance that you do not need to use this method.
 *
 * Calling this method will overwrite the user data that is
 * sent along with every call to the Logger, so we really
 * only want to call this once, at the top of the component
 * hierarchy. Please discuss your use case with a frontend
 * SME before using this.
 */
export const setUserLogMeta = Symbol('LogClient.setUserLogMeta');

export interface SetUserLogMeta {
  (user: UserLogMeta): void;
}

export interface LogClientInterface {
  [setUserLogMeta]: SetUserLogMeta;

  info(message: string | Error, data?: unknown): void;

  warn(message: string | Error, data?: unknown): void;

  error(message: Error, data?: unknown): void;
}

export class LogClient implements LogClientInterface {
  private logger;

  constructor(logger: Ilogger = Logger) {
    this.logger = logger;
  }

  warn = (message: string | Error, data?: unknown): void => {
    this.logger.warn(message, data);
  };

  info = (message: string | Error, data?: unknown): void => {
    this.logger.info(message, data);
  };

  error = (message: Error, data?: unknown): void => {
    this.logger.error(message, data);
  };

  [setUserLogMeta] = (user: UserLogMeta): void => {
    if (this.logger instanceof Rollbar) {
      this.logger.configure({
        payload: {
          person: {
            id: user.id,
          },
          custom: {
            person_role: user.role,
            person_sub_role: user.sub_role,
          },
        },
      });
    }
  };
}
