import React, { createContext, ReactNode, useState } from 'react';

export interface ModifyExceptionRequestProps {
  isLedgerOpen: boolean;
  isLedgerCloned: boolean;
  shouldModifyException: boolean;
  shouldReFetchException: boolean;
}

interface ExceptionRequestContextType {
  modifyExceptionRequest: ModifyExceptionRequestProps;
  updateExceptionRequest: (newValue?: {
    isLedgerOpen?: boolean;
    isLedgerCloned?: boolean;
    shouldModifyException?: boolean;
    shouldReFetchException?: boolean;
  }) => void;
}

export const exceptionRequestContext =
  createContext<ExceptionRequestContextType>({
    modifyExceptionRequest: {
      isLedgerOpen: false,
      isLedgerCloned: false,
      shouldModifyException: false,
      shouldReFetchException: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateExceptionRequest: (): void => {},
  });

interface ExceptionRequestProviderProps {
  children: ReactNode;
}

export const ExceptionRequestProvider: React.FC<
  ExceptionRequestProviderProps
> = ({ children }) => {
  const [modifyExceptionRequest, setModifyExceptionRequest] =
    useState<ModifyExceptionRequestProps>({
      isLedgerOpen: false,
      isLedgerCloned: false,
      shouldModifyException: false,
      shouldReFetchException: false,
    });

  const updateExceptionRequest = (newValue?: {
    isLedgerOpen?: boolean;
    isLedgerCloned?: boolean;
    shouldModifyException?: boolean;
    shouldReFetchException?: boolean;
  }): void => {
    if (newValue) {
      const obj: ModifyExceptionRequestProps = {
        ...modifyExceptionRequest,
        ...newValue,
      };
      setModifyExceptionRequest(obj);
    }
  };

  return (
    <exceptionRequestContext.Provider
      value={{ modifyExceptionRequest, updateExceptionRequest }}
    >
      {children}
    </exceptionRequestContext.Provider>
  );
};
