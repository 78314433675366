import React from 'react';
import { Text, Link } from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { AnalyticsGatewayClient } from 'api/AnalyticsGatewayClient';
import { useUserContext } from 'contexts/UserContext';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { Attachment } from './attachmentManager.types';

export interface AttachmentDownloadLinkProps {
  attachment: Attachment;
}

export const AttachmentDownloadLink = ({
  attachment,
}: AttachmentDownloadLinkProps): JSX.Element => {
  const { isPrequalContext, primaryId } = useAttachmentManagerContext();
  const user = useUserContext();

  const onDownloadLinkClick = (): void => {
    const logData = {
      field_name: 'Attachment Title',
      object_uuid: attachment.uuid,
      name: attachment.fileName,
      action: 'Preview/Download',
      object_type: 'Attachment',
    };

    void AnalyticsGatewayClient.createLogSender(
      user,
      'Attachments Manager'
    )(logData);
  };

  return (
    <>
      {attachment.fileDeletedAt ? (
        <Text>{attachment.fileName}</Text>
      ) : (
        <Link
          href={
            new URL(
              isPrequalContext
                ? `/api/v1/applications/${primaryId}/attachments/${attachment.uuid}`
                : `/api/v2/submissions/${primaryId}/attachments/${attachment.uuid}`,
              UNDERWRITING_BASE_URL()
            )
          }
          newTab
          onClick={onDownloadLinkClick}
        >
          {attachment.fileName}
        </Link>
      )}
    </>
  );
};
