import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.scss';
import { datadogLogs } from '@datadog/browser-logs';
import { Application } from './Application';
import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  IS_MOCKS_ENABLED,
  REACT_APP_ENV,
} from './constants/globals';

const enableMocks = async (): Promise<void> => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser');

    await worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
};

datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN(),
  site: DATADOG_SITE(),
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'internal_frontend',
  env: REACT_APP_ENV(),
});

const container = document.getElementById('root');

// we're disabling no non null assertion here because that is what
// is recommended by the React team
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

// Starts the mock service worker locally.
// Note: Use `npx msw init public/` to generate the mockServiceWorker.js file.
const isMocksEnabled = IS_MOCKS_ENABLED();

const renderApplication = async (): Promise<void> => {
  if (isMocksEnabled) {
    await enableMocks();
  }

  root.render(<Application />);
};

void renderApplication();
