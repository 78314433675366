import React from 'react';
import { Sheet } from '@forward-financing/fast-forward';

import { LedgerOffer } from './offers.types';
import { formatLedgerOfferField, isLedgerOfferKey } from './offers.utils';

type OfferCellProps = {
  fieldName: string;
  handleBlur?: (
    months: number,
    fieldName: keyof LedgerOffer,
    value: string
  ) => void;
  isEditable?: boolean;
  isValid: boolean;
  key: number;
  months: number;
  setValue: (value: string | ((prev: string) => string)) => void;
  value: string;
};

/**
 * Renders a cell in the offers table with optional editing capabilities.
 */
export const OffersTableCell = ({
  fieldName,
  handleBlur,
  isEditable,
  isValid,
  key,
  months,
  setValue,
  value,
}: OfferCellProps): JSX.Element | null => {
  if (!isLedgerOfferKey(fieldName)) return null;

  const onFocusHandler = (): void => {
    // Remove any occurrences of %, $, or , from the previous value
    setValue((prev) => prev.replace(/%|\$|,/g, ''));
  };

  const onBlurHandler = (): void => {
    if (isValid) {
      setValue((prev) =>
        formatLedgerOfferField({ [fieldName]: prev }, fieldName)
      );

      handleBlur?.(months, fieldName, value?.replace(/%|\$|,/g, ''));
    }
  };

  const onKeyDownHandler: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      onBlurHandler();
    }
  };

  return isEditable ? (
    <Sheet.TextCell
      backgroundColor={isValid ? undefined : 'red-100'}
      key={`${key}-${months}`}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onKeyDown={onKeyDownHandler}
      onValueChange={(val) => setValue(val)}
      value={String(value)}
    />
  ) : (
    <Sheet.TextCell key={`${key}-${months}`} value={value} />
  );
};
