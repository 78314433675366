import React, { useCallback, useState } from 'react';
import { capitalize } from 'lodash';
import {
  Banner,
  Card,
  Flex,
  Loading,
  Pagination,
} from '@forward-financing/fast-forward';
import { PaymentsSummaryTable } from './PaymentsSummaryTable';
import { useGetSummaries } from './PaymentsDashboardHooks';

type PaymentsSummaryProps = {
  status: string;
};

export const PaymentsSummary = ({
  status,
}: PaymentsSummaryProps): JSX.Element => {
  const [page, setPage] = useState(1);

  const {
    data: paymentSummaryData,
    loading: paymentSummaryLoading,
    error: paymentSummaryError,
  } = useGetSummaries(status, page);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Card title={capitalize(status)} collapsible>
      {paymentSummaryLoading && <Loading size="medium" />}
      {paymentSummaryError && <Banner>{paymentSummaryError.message}</Banner>}
      {!paymentSummaryLoading && paymentSummaryData && (
        <>
          <PaymentsSummaryTable
            data={paymentSummaryData.summaries}
            status={status}
          />
          <Flex flexDirection="row-reverse">
            <Pagination
              numPages={paymentSummaryData.totalPages}
              currentPage={paymentSummaryData.currentPage}
              onPageClick={handlePageChange}
            />
          </Flex>
        </>
      )}
    </Card>
  );
};
