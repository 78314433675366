import { CustomerResponse, OwnerResponse } from 'types/api/underwriting/types';
import {
  UseGetApiCustomerResponse,
  useGetApiCustomer,
} from 'apiHooks/underwriting/customerFetchHooks';

import {
  UseGetBatchApiOwnersResponse,
  useGetBatchApiOwners,
} from 'apiHooks/underwriting/ownerFetchHooks';
import { Customer, Owner } from './applicationSummary.types';

const toCustomer = (customer?: CustomerResponse): Customer | undefined => {
  if (!customer) {
    return undefined;
  }

  return {
    doingBusinessAsName: customer.name,
    accountAddress: customer.address ?? undefined,
    industryName: customer?.industry_name || undefined,
    startedOnDate: customer?.started_on || undefined,
    businessPhone: customer?.phone || undefined,
    monthlyRevenue: customer.monthly_revenue || undefined,
  };
};

interface UseCustomerResult extends Omit<UseGetApiCustomerResponse, 'data'> {
  customer: Customer | undefined;
}

export const useCustomer = (customerUuid?: string): UseCustomerResult => {
  const { data, error, ...rest } = useGetApiCustomer(customerUuid);

  const customer = toCustomer(data);

  return {
    customer,
    error,
    ...rest,
  };
};

const toOwners = (owners?: OwnerResponse[]): Owner[] => {
  if (!owners) {
    return [];
  }

  // Guarantee that ownershipPercentage is a number or undefined.
  const percentageToNumber = (
    percentage: string | number | null
  ): number | undefined => {
    if (typeof percentage === 'number') {
      return percentage;
    } else if (typeof percentage === 'string') {
      return Number(percentage);
    }

    return undefined;
  };

  return owners.map((owner) => {
    const ownershipPercentage = percentageToNumber(owner.ownership_percentage);

    return {
      uuid: owner.uuid,
      name: `${owner.first_name} ${owner.last_name}`,
      homePhone: owner.home_phone || undefined,
      cellPhone: owner.cell_phone || undefined,
      ownershipPercentage,
    };
  });
};

interface UseOwnersResult extends Omit<UseGetBatchApiOwnersResponse, 'data'> {
  owners?: Owner[];
}

export const useOwners = (ownerUuids?: string[]): UseOwnersResult => {
  const { data, error, ...rest } = useGetBatchApiOwners(ownerUuids);

  return {
    owners: toOwners(data),
    error,
    ...rest,
  };
};
