import React from 'react';
import {
  Banner,
  Divider,
  Flex,
  formatDateString,
  Link,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { useExperianConsumer } from './ownerOverviewHooks';
import { CreditReport } from './types';

export interface CreditReportProps {
  submissionUuid: string | undefined;
  ownerUuid: string | undefined;
  creditReport: CreditReport | undefined;
  canArchive: boolean;
}

export const CreditReportTable = ({
  submissionUuid,
  ownerUuid,
  creditReport,
  canArchive,
}: CreditReportProps): JSX.Element => {
  const {
    data: experianConsumerData,
    loading: experianConsumerLoading,
    error: experianConsumerError,
  } = useExperianConsumer(submissionUuid, ownerUuid);

  const bkPlusScore = experianConsumerData?.bkPlusScore;
  const ficoScore = experianConsumerData?.ficoScore;
  const vantageScore = experianConsumerData?.vantageScore;

  const creditReportStatus = experianConsumerLoading ? ' Loading...' : '';

  const formattedExperianConsumerOldestTradeLine = experianConsumerData
    ? formatDateString(experianConsumerData.oldestTradeLine)
    : '';

  const hasCreditReport = !!experianConsumerData;

  const hasArchive = featureFlags.experian_consumer_3pi_archive
    ? !!experianConsumerData
    : canArchive;

  // 404 Not Found is just a missing credit report, not an error.
  const hasExperianConsumerError =
    experianConsumerError && experianConsumerError?.statusCode !== 404;

  const archiveUrl = new URL(
    `/dashboard/owners/${ownerUuid}/experian_consumer_archive`,
    window.location.origin
  );

  const showLinks = !!experianConsumerData;

  const detailedCreditReportLink =
    featureFlags.experian_consumer_3pi_detailed_page
      ? new URL(
          `/dashboard/consumer-credit-report/${experianConsumerData?.referenceUuid}`,
          window.location.origin
        )
      : new URL(
          creditReport?.creditReportExperianPath || '',
          UNDERWRITING_BASE_URL()
        );

  return (
    <>
      {hasExperianConsumerError && (
        <Banner dismissable={false} variant="error">
          {experianConsumerError?.message}
        </Banner>
      )}

      <Flex gap={3}>
        <Subheading variant="section">{`Credit Report${creditReportStatus}`}</Subheading>
        {showLinks && (
          <Flex alignItems="center" justifyContent="center">
            <Link newTab href={detailedCreditReportLink}>
              Experian
            </Link>

            {hasArchive && (
              <>
                <Divider orientation="vertical" />
                <Link newTab href={archiveUrl}>
                  Archive
                </Link>
              </>
            )}
          </Flex>
        )}
      </Flex>

      {hasCreditReport ? (
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>FICO</Table.RowHeader>
              <Table.Cell>{ficoScore}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>BK Plus</Table.RowHeader>
              <Table.Cell>{bkPlusScore}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Vantage Score</Table.RowHeader>
              <Table.Cell>{vantageScore}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Total Debt to High Credit</Table.RowHeader>
              <Table.Cell>
                {!!experianConsumerData &&
                  experianConsumerData.totalDebtToHighCredit &&
                  `${experianConsumerData.totalDebtToHighCredit}%`}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Total Delinquent Trade Lines</Table.RowHeader>
              <Table.Cell>
                {experianConsumerData?.totalDelinquentTradeLines}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.RowHeader>Oldest Trade Line</Table.RowHeader>
              <Table.Cell>
                {formattedExperianConsumerOldestTradeLine}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <Text>No Credit Report Data</Text>
      )}
    </>
  );
};
