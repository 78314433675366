import { useCallback } from 'react';
import {
  fetchSummaries,
  fetchSummaryDetails,
  FetchSummaryDetailsParams,
} from 'api/funding/paymentSummaryFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  PaybackSummariesResponse,
  PaybackSummaryDetailsResponse,
} from 'types/api/funding/types';

export type UseFetchPaymentSummariesResponse =
  UseGenericQueryResponse<PaybackSummariesResponse>;

export const useApiGetPaymentSummaries = (
  status: string,
  page?: number
): UseFetchPaymentSummariesResponse => {
  const fetcher = useCallback(() => {
    return fetchSummaries(status, page);
  }, [status, page]);

  return useGenericQuery<PaybackSummariesResponse>(fetcher);
};

export type UseFetchPaymentSummaryDetailsResponse =
  UseGenericQueryResponse<PaybackSummaryDetailsResponse>;

export const useApiGetPaymentSummaryDetails = (
  params: FetchSummaryDetailsParams
): UseFetchPaymentSummaryDetailsResponse => {
  const fetcher = useCallback(() => {
    return fetchSummaryDetails({
      date: params.date,
      page: params.page,
      provider: params.provider,
      status: params.status,
    });
  }, [params.date, params.page, params.provider, params.status]);

  return useGenericQuery<PaybackSummaryDetailsResponse>(fetcher);
};
