import React, { useContext, useEffect } from 'react';
import {
  Box,
  Card,
  Grid,
  Loading,
  Text,
} from '@forward-financing/fast-forward';

import { useExceptionRequests } from '../../ExceptionsRequest/exceptionsRequestHooks';
import { ExceptionRequestDetails } from './ExceptionRequestDetails';
import { exceptionRequestContext } from './ExceptionRequestContext';

type ExceptionRequestContainerProps = {
  submissionUuid: string;
};

export const ExceptionRequestContainer = ({
  submissionUuid,
}: ExceptionRequestContainerProps): JSX.Element => {
  const { modifyExceptionRequest, updateExceptionRequest } = useContext(
    exceptionRequestContext
  );
  //TODO change to new Hooks after Eva is done working on new hooks
  const {
    requests,
    loading: requestsLoading,
    refetch: refetchExceptionRequests,
  } = useExceptionRequests(submissionUuid);

  useEffect(() => {
    if (modifyExceptionRequest.shouldReFetchException) {
      refetchExceptionRequests();
      updateExceptionRequest({
        shouldReFetchException: false,
      });
    }
  }, [
    modifyExceptionRequest.shouldReFetchException,
    refetchExceptionRequests,
    updateExceptionRequest,
  ]);

  if (requestsLoading) {
    return (
      <Box p={3}>
        <Loading size="large" />
      </Box>
    );
  }

  const showEmptyMessage = !requests?.length;
  const showExceptionRequest = !!requests?.length;

  return (
    <Grid.Item>
      <Card title={'Exception Request(s)'} collapsible>
        {showEmptyMessage && (
          <Text>
            There are currently no exception requests for this submission.
          </Text>
        )}

        {showExceptionRequest &&
          requests.map((exceptionRequest) => (
            <ExceptionRequestDetails
              exceptionRequest={exceptionRequest}
              key={exceptionRequest.id}
              refetchExceptionRequests={refetchExceptionRequests}
              submissionUuid={submissionUuid}
              requests={requests}
            />
          ))}
      </Card>
    </Grid.Item>
  );
};
