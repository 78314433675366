import React, { useState } from 'react';
import {
  Banner,
  Button,
  Flex,
  Loading,
  Modal,
} from '@forward-financing/fast-forward';

import { useDeclineExceptionRequest } from 'components/ExceptionsRequest/exceptionsRequestHooks';
import { useUserContext } from 'contexts/UserContext';
import { DeclineReasons } from './DeclineReasons';
import { DeclineNotes } from './DeclineNotes';

type ExceptionRequestDeclineModalProps = {
  exceptionId: number;
  refetchExceptionRequests: () => void;
};

export const ExceptionRequestDeclineModal = ({
  exceptionId,
  refetchExceptionRequests,
}: ExceptionRequestDeclineModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [notes, setNotes] = useState('');

  const [
    declineRequest,
    { loading: isDeclineLoading, error: errorFromDeclineRequest },
  ] = useDeclineExceptionRequest(exceptionId);
  const currentUser = useUserContext();

  const notesChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => setNotes(event.target.value);

  const isDeclineDisabled = !selectedReason?.length || !notes?.length;

  const handleDecline = async (): Promise<void> => {
    const { success } = await declineRequest({
      declineReason: selectedReason,
      declineNotes: notes,
      reviewerId: currentUser.id,
    });

    if (success) {
      setIsOpen(false);
      refetchExceptionRequests();
    }
  };

  return (
    <Modal
      title="Decline exception request"
      isOpen={isOpen}
      onOpenChange={() => !isDeclineLoading && setIsOpen((prev) => !prev)}
      trigger={
        <Button startIcon="x" variant="danger">
          Decline request
        </Button>
      }
      body={
        <Flex flexDirection="column" gap={3}>
          <DeclineReasons
            selectedReason={selectedReason}
            setSelectedReason={(val) => setSelectedReason(val)}
          />

          <DeclineNotes notes={notes} setNotes={notesChangeHandler} />

          {errorFromDeclineRequest && (
            <Banner dismissable>{errorFromDeclineRequest.message}</Banner>
          )}

          <Flex flexDirection="row" gap={3}>
            <Button
              variant="danger"
              disabled={isDeclineDisabled || isDeclineLoading}
              onClick={handleDecline}
            >
              Decline request
            </Button>

            {isDeclineLoading && <Loading />}

            <Button
              variant="text"
              onClick={() => setIsOpen(false)}
              disabled={isDeclineLoading}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
