import React from 'react';
import {
  formatCurrency,
  formatDateString,
  Loading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { capitalize } from 'lodash';
import {
  useRenewalComparisonSubmission,
  useSubmissionCreditDecision,
} from '../renewalComparisonHooks';

const UNABLE_TO_FETCH = <Text italic>Unable to fetch</Text>;

type MostRecentAdvanceFundingInfoTableProps = {
  mostRecentAdvanceUuid: string | undefined;
};

const percentageRepaidText = (percentage?: string): string | JSX.Element => {
  if (!percentage) {
    return UNABLE_TO_FETCH;
  }

  // `percentage` should look like a stringified float,
  // but if it doesn't, return it as is
  if (isNaN(Number(percentage))) {
    return percentage;
  }

  return `${Number(percentage).toFixed(2)}%`;
};

const didCustomerTakeLessText = (
  fundedAmount?: number,
  approvalAmount?: number
): string | JSX.Element => {
  if (!fundedAmount || !approvalAmount) {
    return UNABLE_TO_FETCH;
  }

  return approvalAmount > fundedAmount ? 'Yes' : 'No';
};

export const MostRecentAdvanceFundingInfoTable = ({
  mostRecentAdvanceUuid,
}: MostRecentAdvanceFundingInfoTableProps): JSX.Element => {
  const {
    data: previousRenewalComparisonsData,
    loading: previousRenewalComparisonsLoading,
  } = useRenewalComparisonSubmission(mostRecentAdvanceUuid);

  const {
    data: previousSubmissionCreditDecisionData,
    loading: previousSubmissionCreditDecisionLoading,
  } = useSubmissionCreditDecision(mostRecentAdvanceUuid);

  if (!mostRecentAdvanceUuid) {
    return <></>;
  }

  if (
    previousRenewalComparisonsLoading ||
    previousSubmissionCreditDecisionLoading
  ) {
    return (
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Most Recent Funded Advance</Table.ColumnHeader>
        </Table.Head>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Loading />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  return (
    <Table>
      <Table.ColGroup>
        <Table.Col />
        <Table.Col />
      </Table.ColGroup>

      <Table.Head>
        <Table.ColumnHeader>Most Recent Funded Advance</Table.ColumnHeader>
        <Table.ColumnHeader />
      </Table.Head>

      <Table.Body>
        <Table.Row>
          <Table.RowHeader>% Repaid</Table.RowHeader>
          <Table.Cell>
            {percentageRepaidText(
              previousRenewalComparisonsData?.percentageRepaid
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Current Balance</Table.RowHeader>
          <Table.Cell>
            {previousRenewalComparisonsData?.balance &&
            !isNaN(Number(previousRenewalComparisonsData?.balance))
              ? formatCurrency(Number(previousRenewalComparisonsData?.balance))
              : UNABLE_TO_FETCH}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Last Payment</Table.RowHeader>
          <Table.Cell>
            {previousRenewalComparisonsData?.lastPaymentDate
              ? formatDateString(
                  previousRenewalComparisonsData?.lastPaymentDate
                )
              : UNABLE_TO_FETCH}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Most Recent Payment Status</Table.RowHeader>
          <Table.Cell>
            {previousRenewalComparisonsData?.mostRecentPaybackStatus
              ? capitalize(
                  previousRenewalComparisonsData?.mostRecentPaybackStatus
                )
              : UNABLE_TO_FETCH}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>Did Customer Take Less?</Table.RowHeader>
          <Table.Cell>
            {didCustomerTakeLessText(
              previousRenewalComparisonsData?.amount,
              previousSubmissionCreditDecisionData?.maxApprovalAmount
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
