import React from 'react';
import { Button, Loading } from '@forward-financing/fast-forward';
import { SendToPrequalBody } from 'components/Navbar/SubmissionNavbar/navbarFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';

export interface SendToPrequalButtonProps {
  refetchSubmission: () => void;
  isLoading: boolean;
  sendToPrequal: (input: SendToPrequalBody) => Promise<MutationResponse>;
}
export const SendToPrequalButton = ({
  refetchSubmission,
  sendToPrequal,
  isLoading,
}: SendToPrequalButtonProps): JSX.Element => {
  const handleSendToPrequal = async (): Promise<void> => {
    await sendToPrequal({ eventName: 'data_review_complete', attributes: {} });
    refetchSubmission();
  };

  return (
    <>
      <Button
        key="sendToPrequal"
        startIcon="gears"
        onClick={handleSendToPrequal}
        disabled={isLoading}
      >
        Send To Prequal
        {isLoading && <Loading size="small" />}
      </Button>
    </>
  );
};
