import React from 'react';
import {
  Currency,
  formatDateString,
  Table,
} from '@forward-financing/fast-forward';
import { Summary } from './PaymentsDashboard.types';

type PaymentsSummaryTableProps = {
  data: Summary[];
  status: string;
};

export const PaymentsSummaryTable = ({
  data,
  status,
}: PaymentsSummaryTableProps): JSX.Element => {
  const getIndexLink = (
    date: string,
    provider: string,
    sameDay: boolean
  ): URL => {
    const params = new URLSearchParams({
      date,
      provider,
      status,
      sameDay: sameDay.toString(),
    });
    return new URL(
      `/dashboard/payments/index?${params}`,
      window.location.origin
    );
  };

  return (
    <Table>
      <Table.ColGroup>
        <Table.Col />
        <Table.Col />
        <Table.Col />
        <Table.Col />
        <Table.Col />
        <Table.Col />
      </Table.ColGroup>
      <Table.Head>
        <Table.ColumnHeader>Date</Table.ColumnHeader>
        <Table.ColumnHeader>Provider</Table.ColumnHeader>
        <Table.ColumnHeader>Same-day?</Table.ColumnHeader>
        <Table.ColumnHeader>Count</Table.ColumnHeader>
        <Table.ColumnHeader>Amount</Table.ColumnHeader>
        <Table.ColumnHeader>Details</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        {data.map(({ uuid, date, provider, sameDay, count, amount }) => (
          <Table.Row key={uuid}>
            <Table.Cell>{formatDateString(date)}</Table.Cell>
            <Table.Cell>{provider}</Table.Cell>
            <Table.Cell>{sameDay ? 'Yes' : 'No'}</Table.Cell>
            <Table.Cell>{count}</Table.Cell>
            <Table.Cell>
              {!isNaN(amount) && <Currency amount={amount} />}
            </Table.Cell>
            <Table.LinkCell href={getIndexLink(date, provider, sameDay)}>
              Index
            </Table.LinkCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
