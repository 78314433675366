import { useGetApiLexisNexisCustomerReports } from 'apiHooks/underwriting/lexisNexisCustomerFetchHooks';
import {
  BusinessPaynetResponse,
  LexisNexisCustomerReport,
} from 'types/api/underwriting/types';
import { useGetApiBusinessPaynetReport } from 'apiHooks/underwriting/customerFetchHooks';

export type LatestPaynetReportsMap = Record<string, BusinessPaynetResponse>;
export const useGetLatestPaynetReports = (
  submissionUuid: string
): LatestPaynetReportsMap => {
  const { data } = useGetApiBusinessPaynetReport(submissionUuid);

  /*
    It's sorted from oldest to newest, because in reduce call
    we will get the latest report
  */
  const sorted =
    data?.sort((a, b) => a.created_at.localeCompare(b.created_at)) ?? [];

  return sorted.reduce<LatestPaynetReportsMap>((acc, current) => {
    acc[current.full_payload.paynet_id] = current;
    return acc;
  }, {});
};
export type LatestLexisNexisReportsMap = Record<
  string,
  LexisNexisCustomerReport
>;
export const useGetLatestLexisNexisReportsMap = (
  submissionUuid: string
): LatestLexisNexisReportsMap => {
  const { data } = useGetApiLexisNexisCustomerReports(submissionUuid);

  /*
    It's sorted from oldest to newest, because in reduce call
    we will get the latest report
  */
  const sorted =
    data?.documents?.sort((a, b) => a.created_at.localeCompare(b.created_at)) ??
    [];

  return sorted.reduce<LatestLexisNexisReportsMap>((acc, current) => {
    if (!current.report_identifier) {
      return acc;
    }

    acc[current.report_identifier] = current;
    return acc;
  }, {});
};
