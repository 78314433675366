import React, { createContext, useContext } from 'react';
import { AttachmentManagerContextData } from './attachmentManager.types';

const AttachmentManagerContext = createContext<AttachmentManagerContextData>({
  isPrequalContext: false,
  primaryId: '',
  documentTags: { selectable: {}, nonSelectable: {} },
  supportedFileExtensions: [],
  reloadAttachmentManager: () => {
    // intentionally empty. This code will never be executed
  },
  customerLegalName: '',
  customerDba: '',
  ownerNames: [],
  isSalesforce: false,
  validReasons: {},
  entityType: '',
});

export const useAttachmentManagerContext = (): AttachmentManagerContextData =>
  useContext(AttachmentManagerContext);

interface AttachmentManagerProviderProps {
  value: AttachmentManagerContextData;
  children: React.ReactNode;
}

export const AttachmentManagerProvider = ({
  value,
  children,
}: AttachmentManagerProviderProps): JSX.Element => {
  return (
    <AttachmentManagerContext.Provider value={value}>
      {children}
    </AttachmentManagerContext.Provider>
  );
};
