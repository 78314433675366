import { useCallback } from 'react';
import {
  createApplicationAttachment,
  createAttachment,
  fetchAccount,
  removeApplicationAttachment,
  removeAttachment,
  updateApplicationAttachment,
  updateAttachment,
} from 'api/underwriting/attachmentFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  AccountResponse,
  CreateAttachmentRequestBody,
  UpdateAttachmentRequestBody,
} from 'types/api/underwriting/types';

export type UseGetApiAccountResponse = UseGenericQueryResponse<AccountResponse>;

export const useGetApiAccount = (
  applicationUuid: string
): UseGetApiAccountResponse => {
  const fetchFunction = useCallback(() => {
    return fetchAccount(applicationUuid);
  }, [applicationUuid]);

  return useGenericQuery<AccountResponse>(fetchFunction);
};

export interface UseCreateAttachmentArgs {
  submissionUuid: string;
  createBody: CreateAttachmentRequestBody;
}

export const useApiCreateAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseCreateAttachmentArgs[]
> => {
  const createAttachmentBatchFunction = (
    args: UseCreateAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, createBody }) =>
        createAttachment(submissionUuid, createBody)
      )
    );
  };

  return useGenericMutation(createAttachmentBatchFunction);
};

export type UseCreateApplicationAttachmentArgs = {
  applicationUuid: string;
  createBody: CreateAttachmentRequestBody;
};

export const useApiCreateApplicationAttachmentBatch =
  (): UseGenericMutationResponse<
    MutationResponse[],
    UseCreateApplicationAttachmentArgs[]
  > => {
    const createAttachmentBatchFunction = (
      args: UseCreateApplicationAttachmentArgs[]
    ): Promise<MutationResponse[]> => {
      return Promise.all(
        args.map(({ applicationUuid, createBody }) =>
          createApplicationAttachment(applicationUuid, createBody)
        )
      );
    };

    return useGenericMutation(createAttachmentBatchFunction);
  };

export interface UseDeleteAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
}

export const useApiDeleteAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseDeleteAttachmentArgs[]
> => {
  const deleteAttachmentBatchFunction = (
    args: UseDeleteAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, attachmentUuid }) =>
        removeAttachment(submissionUuid, attachmentUuid)
      )
    );
  };

  return useGenericMutation(deleteAttachmentBatchFunction);
};

export type UseDeleteApplicationAttachmentArgs = {
  applicationUuid: string;
  attachmentUuid: string;
};

export const useApiDeleteApplicationAttachmentBatch =
  (): UseGenericMutationResponse<
    MutationResponse[],
    UseDeleteApplicationAttachmentArgs[]
  > => {
    const deleteAttachmentBatchFunction = (
      args: UseDeleteApplicationAttachmentArgs[]
    ): Promise<MutationResponse[]> => {
      return Promise.all(
        args.map(({ applicationUuid, attachmentUuid }) =>
          removeApplicationAttachment(applicationUuid, attachmentUuid)
        )
      );
    };

    return useGenericMutation(deleteAttachmentBatchFunction);
  };

export interface UseUpdateAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
  body: UpdateAttachmentRequestBody;
}

export const useApiUpdateAttachmentBatch = (): UseGenericMutationResponse<
  MutationResponse[],
  UseUpdateAttachmentArgs[]
> => {
  const updateAttachmentBatchFunction = (
    args: UseUpdateAttachmentArgs[]
  ): Promise<MutationResponse[]> => {
    return Promise.all(
      args.map(({ submissionUuid, attachmentUuid, body }) =>
        updateAttachment(submissionUuid, attachmentUuid, body)
      )
    );
  };

  return useGenericMutation(updateAttachmentBatchFunction);
};

export type UseUpdateApplicationAttachmentArgs = {
  applicationUuid: string;
  attachmentUuid: string;
  body: UpdateAttachmentRequestBody;
};

export const useApiUpdateApplicationAttachmentBatch =
  (): UseGenericMutationResponse<
    MutationResponse[],
    UseUpdateApplicationAttachmentArgs[]
  > => {
    const updateAttachmentBatchFunction = (
      args: UseUpdateApplicationAttachmentArgs[]
    ): Promise<MutationResponse[]> => {
      return Promise.all(
        args.map(({ applicationUuid, attachmentUuid, body }) =>
          updateApplicationAttachment(applicationUuid, attachmentUuid, body)
        )
      );
    };

    return useGenericMutation(updateAttachmentBatchFunction);
  };
