import React, { useContext, useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateTimeString,
  IconButton,
  Loading,
  Modal,
  Text,
} from '@forward-financing/fast-forward';
import { isEqual } from 'lodash';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { exceptionRequestContext } from '../../SubmissionUnderwriting/ExceptionRequest/ExceptionRequestContext';
import { Ledger } from './ledger.types';
import {
  useCloneLedger,
  useDeleteLedger,
  usePatchLedger,
  useSendToCreditCommittee,
} from './ledgerHooks';
import { useLedgersValidationContext } from './LedgerValidationContext/LedgerValidationContext';

const CLONE_SUCCESS_TEXT = 'Ledger cloned successfully';
const SEND_TO_COMMITTEE_SUCCESS_TEXT = 'Ledger sent to committee successfully';
const DELETE_SUCCESS_TEXT = 'Ledger deleted successfully';
export const SAVE_LEDGER_SUCCESS_TEXT = 'Ledger saved successfully';
export const SEND_LEDGER_SUCCESS_TEXT = 'Ledger sent successfully';

type LedgerToolboxProps = {
  ledger: Ledger;
  shouldDisableToolbox: boolean;
  submissionUuid: string;
  handleAction: (
    action: () => Promise<MutationResponse>,
    successMessage: string
  ) => Promise<void>;
  editIsEnabled: boolean;
  handleSendLedger: (
    patchLedger: () => Promise<MutationResponse>,
    successMessage: string,
    closeConfirmationModal: () => void
  ) => Promise<void>;
  setIsOverrideValidationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  refetchLedgers: () => void;
};

export const LedgerToolbox = ({
  handleAction,
  ledger,
  submissionUuid,
  editIsEnabled,
  handleSendLedger,
  shouldDisableToolbox,
  setIsOverrideValidationModalOpen,
  refetchLedgers,
}: LedgerToolboxProps): JSX.Element => {
  const { modifyExceptionRequest } = useContext(exceptionRequestContext);

  const { ledgerToUpdate, isProgramExplanationInvalid } =
    useLedgersValidationContext();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [sendLedgerModalIsOpen, setSendLedgerModalIsOpen] = useState(false);

  const [cloneLedger, { error: hasCloningError, loading: isCloningLoading }] =
    useCloneLedger();

  const [deleteLedger, { error: hasDeleteError, loading: isDeleteLoading }] =
    useDeleteLedger();

  const [
    sendToCommittee,
    { error: hasSendToCommitteeError, loading: isSendToCommitteeLoading },
  ] = useSendToCreditCommittee();

  const [
    patchLedger,
    { loading: isPatchLedgerLoading, error: hasPatchLedgerError },
  ] = usePatchLedger();

  const { isSaveDisabled } = useLedgersValidationContext();

  const handleClone = async (): Promise<void> => {
    await handleAction(
      () => cloneLedger({ submissionUuid, ledgerId: ledger.id }),
      CLONE_SUCCESS_TEXT
    );
  };

  useEffect(
    () => {
      if (
        !modifyExceptionRequest?.isLedgerCloned &&
        modifyExceptionRequest?.isLedgerOpen
      ) {
        void handleClone();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      modifyExceptionRequest?.isLedgerCloned,
      modifyExceptionRequest?.isLedgerOpen,
    ]
  );

  const handleDelete = async (): Promise<void> => {
    await handleAction(
      () => deleteLedger({ submissionUuid, ledgerId: ledger.id }),
      DELETE_SUCCESS_TEXT
    );
    setDeleteModalOpen(false);
  };

  const saveIfNecessary = async (): Promise<MutationResponse> => {
    if (!isEqual(ledgerToUpdate, ledger)) {
      return await patchLedger({
        ledger: ledgerToUpdate,
        ledgerId: ledger.id,
        submissionUuid,
      });
    }

    // If we don't need to save, return a successful response.
    return { success: true };
  };

  const handleSendToCommittee = async (): Promise<void> => {
    const { success } = await saveIfNecessary();

    success &&
      (await handleAction(async () => {
        return await sendToCommittee({ submissionUuid, ledgerId: ledger.id });
      }, SEND_TO_COMMITTEE_SUCCESS_TEXT));
  };

  const handleSaveLedger = async (): Promise<void> => {
    await handleAction(
      () =>
        patchLedger({
          ledger: ledgerToUpdate,
          ledgerId: ledger.id,
          submissionUuid,
        }),
      SAVE_LEDGER_SUCCESS_TEXT
    );
  };

  const handleConfirmationSendLedger = async (): Promise<void> => {
    await handleSendLedger(
      () =>
        patchLedger({
          ledger: { ...ledgerToUpdate, lock: true },
          ledgerId: ledger.id,
          submissionUuid,
        }),
      SEND_LEDGER_SUCCESS_TEXT,
      () => setSendLedgerModalIsOpen(false)
    );
  };

  useEffect(() => {
    if (
      hasPatchLedgerError &&
      hasPatchLedgerError.message === '422: Validation override required'
    ) {
      setIsOverrideValidationModalOpen(true);
    }
  }, [hasPatchLedgerError, setIsOverrideValidationModalOpen]);

  const shouldDisableSaveButton =
    isSaveDisabled ||
    (!editIsEnabled && isEqual(ledgerToUpdate, ledger)) ||
    ledgerToUpdate.program === 0 ||
    ledger.lock ||
    (ledgerToUpdate.overrideBuyRates &&
      ledgerToUpdate.overrideBuyRatesReason.trim().length === 0);

  const shouldDisableSendButton =
    isSaveDisabled ||
    isPatchLedgerLoading ||
    ledger.lock ||
    isProgramExplanationInvalid;

  const patchLedgerErrorBanner = (): JSX.Element => {
    if (!hasPatchLedgerError) {
      return <></>;
    }

    const { message } = hasPatchLedgerError;

    if (message.startsWith('422:')) {
      return message.includes('Validation override required') ? (
        <></>
      ) : (
        <Banner dismissable>{message.split(': ')[1]}</Banner>
      );
    }

    return <Banner dismissable>{message}</Banner>;
  };

  return (
    <>
      {hasCloningError && (
        <Banner dismissable>{hasCloningError.message}</Banner>
      )}
      {hasDeleteError && <Banner dismissable>{hasDeleteError.message}</Banner>}
      {hasSendToCommitteeError && (
        <Banner dismissable>{hasSendToCommitteeError.message}</Banner>
      )}
      {patchLedgerErrorBanner()}

      <Flex justifyContent="space-between">
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          mx={2}
          justifySelf={'flex-start'}
          gap={3}
        >
          {ledger.approvedDate && (
            <Box>
              <Text bold>
                Saved and Sent On {formatDateTimeString(ledger.approvedDate)}
              </Text>
            </Box>
          )}
          <Button startIcon={'refresh'} onClick={refetchLedgers}>
            Refresh Ledger
          </Button>
        </Flex>

        <Flex>
          <IconButton
            icon={['far', 'save']}
            label="Save"
            onClick={handleSaveLedger}
            disabled={shouldDisableSaveButton || shouldDisableToolbox}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'paper-plane']}
                label="Send ledger"
                disabled={shouldDisableSendButton || shouldDisableToolbox}
              />
            }
            isOpen={sendLedgerModalIsOpen}
            onOpenChange={() => setSendLedgerModalIsOpen((prev) => !prev)}
            title="Confirm Ledger Lock"
            size="auto"
            body={
              <>
                <Text bold>
                  After saving, the ledger will be locked, are you sure you want
                  to proceed?
                </Text>
                {isPatchLedgerLoading && <Loading />}

                <Button
                  onClick={handleConfirmationSendLedger}
                  variant={'primary'}
                  disabled={
                    isPatchLedgerLoading || ledgerToUpdate.program === 0
                  }
                >
                  Save & send ledger
                </Button>
              </>
            }
          />
          <IconButton
            icon="people-line"
            label="Send to Decision Committee"
            onClick={handleSendToCommittee}
            disabled={
              isSendToCommitteeLoading || ledger.lock || shouldDisableToolbox
            }
          />
          <IconButton
            icon={['far', 'clone']}
            label="Clone"
            disabled={isCloningLoading || shouldDisableToolbox}
            onClick={handleClone}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'trash-can']}
                label="Delete"
                variant="danger"
                disabled={ledger.lock || shouldDisableToolbox}
              />
            }
            isOpen={deleteModalOpen}
            onOpenChange={() => setDeleteModalOpen((prev) => !prev)}
            title={'Delete ledger'}
            size={'auto'}
            body={
              <>
                <Text bold>Are you sure you want to delete this ledger?</Text>
                {isDeleteLoading && <Loading />}

                <Button
                  onClick={handleDelete}
                  variant={'danger'}
                  disabled={isDeleteLoading}
                >
                  Delete
                </Button>
              </>
            }
          />
        </Flex>
      </Flex>
    </>
  );
};
