import {
  Banner,
  Card,
  Flex,
  Grid,
  PageContainer,
  StickyContainer,
} from '@forward-financing/fast-forward';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnderwritingAttachmentManager } from 'components/AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentManager';
import { featureFlags } from 'helpers/featureFlags';
import { Toolbox } from 'components/SubmissionUnderwriting/Toolbox/Toolbox';
import { DealScoringCardContainer } from 'components/DealScoring/DealScoringCardContainer';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import { ApplicationSnapshotContainer } from './ApplicationSnapshot/ApplicationSnapshotContainer';
import { BusinessOverviewSection } from './BusinessOverview/BusinessOverviewSection';
import { FraudScoringContainer } from './FraudScoring/FraudScoringContainer';
import { OwnerOverviewSection } from './OwnerOverview/OwnerOverviewSection';
import { RiskFactorsContainer } from './RiskFactors/RiskFactorsContainer';

import { SubmissionEmailContentContainer } from './SubmissionEmailContent/SubmissionEmailContentContainer';
import { MatchedRecordsContainer } from './MatchedRecords/MatchedRecordsContainer';
import { ApplicationSummaryContainer } from './ApplicationSummary/ApplicationSummaryContainer';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';
import { PotentialFraudContainer } from './PotentialFraud/PotentialFraudContainer';
import { HighRiskReviewContainer } from './HighRiskReview/HighRiskReviewContainer';
import { ExceptionRequestContainer } from './ExceptionRequest/ExceptionRequestContainer';

type Params = {
  submissionUuid: string;
};

export const SubmissionUnderwritingContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const {
    data: submission,
    error: errorSubmission,
    refetch: refetchSubmission,
  } = useGetSubmission(submissionUuid);

  useEffect(() => {
    document.title = submission?.submissionName || 'Underwriting App';
  }, [submission]);

  useScrollRestoration(`submission-underwriting-container-${submissionUuid}`);

  // If a submission is not in any of the below stages, we can assume it is in or
  // beyond the underwriting process.
  const isStageUnderwritingOrLater =
    submission?.stageName &&
    !['Renewal Prospecting', 'Renewals (Ineligible)', 'Processing'].includes(
      submission.stageName
    );

  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false} variant="error">
          {errorSubmission.message}
        </Banner>
      )}
      <Toolbox
        submissionUuid={submissionUuid}
        submission={submission}
        refetchSubmission={refetchSubmission}
      />
      <StickyContainer stickTo="top">
        <PageContainer>
          <ApplicationSummaryContainer submission={submission} />
        </PageContainer>
      </StickyContainer>
      <PageContainer>
        <Grid gutter>
          {featureFlags.exception_request_rewrite && (
            <ExceptionRequestContainer submissionUuid={submissionUuid} />
          )}
          <Grid.Item m={6}>
            {submission && (
              <ApplicationSnapshotContainer submission={submission} />
            )}
          </Grid.Item>
          <Grid.Item m={6}>
            <Flex gap={3} flexDirection="column">
              {submission && (
                <SubmissionEmailContentContainer submission={submission} />
              )}
              <FraudScoringContainer submission={submission} />
              <RiskFactorsContainer submissionUuid={submissionUuid} />
              {submission &&
                isStageUnderwritingOrLater &&
                (featureFlags.show_high_risk_review_section ? (
                  <HighRiskReviewContainer
                    submissionUuid={submissionUuid}
                    customerUuid={submission.customerUuid}
                  />
                ) : (
                  <PotentialFraudContainer submissionUuid={submissionUuid} />
                ))}
            </Flex>
          </Grid.Item>
          <Grid.Item>
            <Card collapsible title="ISO Attachments">
              <UnderwritingAttachmentManager
                submissionUuid={submissionUuid}
                submission={submission}
                refetchSubmission={refetchSubmission}
              />
            </Card>
          </Grid.Item>
          <Grid.Item>
            <MatchedRecordsContainer submission={submission} />
          </Grid.Item>
          <Grid.Item>
            <OwnerOverviewSection
              submissionUuid={submissionUuid}
              submission={submission}
            />
          </Grid.Item>
          <Grid.Item>
            <BusinessOverviewSection
              submissionUuid={submissionUuid}
              submission={submission}
            />
          </Grid.Item>
        </Grid>
      </PageContainer>

      <DealScoringCardContainer
        submissionUuid={submissionUuid}
        customerUuid={submission?.customerUuid}
      />
    </>
  );
};
