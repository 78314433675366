import React from 'react';
import { Flex, Table, Text, Tooltip } from '@forward-financing/fast-forward';
import { startCase } from 'lodash';
import { featureFlags } from 'helpers/featureFlags';
import { RenewalScoreComposite } from '../DealScoring.types';

// Ratings are on a scale of 1-2-3 or 1-3-5 or 1-3-5-7
const ratingWithColorCode = (
  maxRating: 3 | 5 | 7,
  rating?: number
): JSX.Element => {
  if (!rating) {
    return <>N/A</>;
  }

  let colorCode = <></>;
  let tooltipContent = '';

  if (rating === 1) {
    colorCode = (
      <span role="img" aria-label="red">
        🔴
      </span>
    );
    tooltipContent = 'High Risk';
  } else if (rating === maxRating) {
    colorCode = (
      <span role="img" aria-label="green">
        🟢
      </span>
    );
    tooltipContent = 'Low Risk';
  } else {
    colorCode = (
      <span role="img" aria-label="yellow">
        🟡
      </span>
    );
    tooltipContent = 'Medium Risk';
  }

  return (
    <>
      {rating}{' '}
      <Tooltip position="right" content={tooltipContent} trigger={colorCode} />
    </>
  );
};

export interface RenewalCompositeScoreTableProps {
  scoreData?: RenewalScoreComposite;
}

// No test file exists for this component because
// it has 100% coverage from utilization inside the
// RenewalScoringComposite & PastAdvances components
export const RenewalCompositeScoreTable = ({
  scoreData,
}: RenewalCompositeScoreTableProps): JSX.Element => {
  if (!scoreData) {
    return <></>;
  }

  const {
    industryRisk,
    timesFunded,
    ficoChange,
    revenueChange,
    paymentHistory,
    stacking,
  } = scoreData || {};

  const version = scoreData.version;

  const repaidPercentage =
    version === 'renewal_composite_score'
      ? scoreData.repaidPercentage
      : undefined;

  const bkPlus =
    featureFlags.update_renewal_composite_score &&
    version === 'renewal_composite_score_v2'
      ? scoreData.bkPlus
      : undefined;

  return (
    <Table caption="Renewal Deal Composite Score">
      <Table.Head>
        <Table.ColumnHeader> </Table.ColumnHeader>
        <Table.ColumnHeader>Rating</Table.ColumnHeader>
        <Table.ColumnHeader>Data Point</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Number of X&apos;s Renewed
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {featureFlags.update_renewal_composite_score
              ? ratingWithColorCode(7, timesFunded?.score)
              : ratingWithColorCode(5, timesFunded?.score)}
          </Table.Cell>
          <Table.Cell>{timesFunded?.value || 'N/A'}</Table.Cell>
        </Table.Row>

        {version === 'renewal_composite_score' && (
          <Table.Row>
            <Table.RowHeader>
              <Flex alignItems="center" gap={1}>
                Repaid %
              </Flex>
            </Table.RowHeader>
            <Table.Cell>
              {ratingWithColorCode(5, repaidPercentage?.score)}
            </Table.Cell>
            <Table.Cell>
              {repaidPercentage?.value
                ? `${repaidPercentage?.value.toFixed(1)}%`
                : 'N/A'}
            </Table.Cell>
          </Table.Row>
        )}

        {featureFlags.update_renewal_composite_score &&
          version === 'renewal_composite_score_v2' && (
            <Table.Row>
              <Table.RowHeader>
                <Flex alignItems="center" gap={1}>
                  BK Plus
                </Flex>
              </Table.RowHeader>
              <Table.Cell>{ratingWithColorCode(5, bkPlus?.score)}</Table.Cell>
              <Table.Cell>
                {bkPlus?.value ? `${bkPlus?.value}` : 'N/A'}
              </Table.Cell>
            </Table.Row>
          )}

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Industry Risk
            </Flex>
          </Table.RowHeader>
          <Table.Cell>{ratingWithColorCode(5, industryRisk?.score)}</Table.Cell>
          <Table.Cell>
            {/* startCase: low_risk becomes Low Risk */}
            {industryRisk?.value ? startCase(industryRisk?.value) : 'N/A'}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Payment History
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(5, paymentHistory?.score)}
          </Table.Cell>
          <Table.Cell>
            <Flex flexDirection="column" gap={1}>
              <Text>
                Value:{' '}
                {typeof paymentHistory?.value === 'number'
                  ? `${paymentHistory?.value.toFixed(1)}`
                  : 'N/A'}
              </Text>
            </Flex>
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Revenue Change
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(3, revenueChange?.score)}
          </Table.Cell>
          <Table.Cell>
            {revenueChange?.value
              ? `${revenueChange?.value.toFixed(1)}%`
              : 'N/A'}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              FICO Change
            </Flex>
          </Table.RowHeader>
          <Table.Cell>{ratingWithColorCode(3, ficoChange?.score)}</Table.Cell>
          <Table.Cell>{ficoChange?.value || 'N/A'}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Stacking
            </Flex>
          </Table.RowHeader>
          <Table.Cell>{ratingWithColorCode(3, stacking?.score)}</Table.Cell>
          <Table.Cell>
            <Flex flexDirection="column" gap={1}>
              <Text>
                Pre Gross:{' '}
                {stacking?.preFfGross
                  ? `${stacking?.preFfGross.toFixed(2)}%`
                  : 'N/A'}
              </Text>
            </Flex>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
