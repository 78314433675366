import React from 'react';
import {
  Accordion,
  Flex,
  Subheading,
  Table,
  Tag,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo, startCase } from 'lodash';
import {
  SuggestedIndustryByVendor,
  SuggestedIndustryData,
} from './suggestedIndustry.types';

export type SuggestedIndustryProps = {
  suggestedIndustry: SuggestedIndustryData | undefined;
  otherSuggestions: SuggestedIndustryByVendor[] | undefined;
};

const normalizeVendor = (vendor: string): string => {
  switch (vendor) {
    case 'RelativitySix':
      return 'Relativity6';

    case 'BaseLayer':
      return 'Baselayer';

    default:
      return startCase(vendor);
  }
};

export const SuggestedIndustry = ({
  suggestedIndustry,
  otherSuggestions,
}: SuggestedIndustryProps): JSX.Element => {
  return (
    <Flex flexDirection="column" gap={3}>
      <Flex flexDirection="column" alignItems={'flex-start'}>
        <Subheading variant="subsection">Suggested Industry</Subheading>

        <Flex gap={2} alignItems={'center'}>
          <Text>{defaultTo(suggestedIndustry?.industry, 'N/A')}</Text>

          {suggestedIndustry?.isProhibited && (
            <Tag bgColor="danger">Prohibited</Tag>
          )}
        </Flex>
      </Flex>

      <Accordion type="single" collapsible>
        <Accordion.Item value="suggestions">
          <Accordion.Trigger>
            <Flex>View Suggestions</Flex>
          </Accordion.Trigger>
          <Accordion.Content>
            <Table>
              <Table.Head>
                <Table.ColumnHeader>Vendor</Table.ColumnHeader>
                <Table.ColumnHeader>NAICS Code</Table.ColumnHeader>
                <Table.ColumnHeader>Confidence</Table.ColumnHeader>
                <Table.ColumnHeader>NAICS Description</Table.ColumnHeader>
                <Table.ColumnHeader>Industry</Table.ColumnHeader>
              </Table.Head>

              <Table.Body>
                {otherSuggestions?.map((suggestion) => (
                  <Table.Row
                    key={`${suggestion.vendor}-${suggestion.naicsCode}`}
                  >
                    <Table.Cell>
                      {normalizeVendor(suggestion.vendor)}
                    </Table.Cell>

                    <Table.Cell>
                      {defaultTo(suggestion.naicsCode, 'N/A')}
                    </Table.Cell>

                    <Table.Cell>
                      {typeof suggestion.confidence === 'number'
                        ? `${suggestion.confidence.toFixed(1)}%`
                        : 'N/A'}
                    </Table.Cell>

                    <Table.Cell>
                      {defaultTo(suggestion.naicsDescription, 'N/A')}
                    </Table.Cell>

                    <Table.Cell>
                      <Flex gap={2} alignItems={'center'}>
                        <Text>{defaultTo(suggestion.industry, 'N/A')}</Text>

                        {suggestion.isProhibited && (
                          <Tag bgColor="danger">Prohibited</Tag>
                        )}
                      </Flex>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};
