import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  Subheading,
} from '@forward-financing/fast-forward';
import { Attachment } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import {
  useDeleteApplicationAttachment,
  useDeleteAttachment,
} from './attachmentManagerHooks';

export interface DeleteAttachmentModalProps {
  selectedAttachments: Attachment[];
}

export const DeleteAttachmentModal = (
  props: DeleteAttachmentModalProps
): JSX.Element => {
  const { isPrequalContext, primaryId, reloadAttachmentManager } =
    useAttachmentManagerContext();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string | null>(null);

  const [selectedIds, setSelectedIds] = useState(
    props.selectedAttachments.map((att) => att.uuid)
  );

  const [removeAttachment, { error: removeAttachmentError }] =
    useDeleteAttachment();

  const [removePrequalAttachment, { error: removePrequalAttachmentError }] =
    useDeleteApplicationAttachment();

  useEffect(() => {
    if (removeAttachmentError) {
      setErrorMessages(
        `Something went wrong deleting one or more attachment: ${removeAttachmentError.message}`
      );
    } else {
      setErrorMessages(null);
    }
  }, [removeAttachmentError]);

  useEffect(() => {
    if (removePrequalAttachmentError) {
      setErrorMessages(
        `Something went wrong deleting one or more attachment: ${removePrequalAttachmentError.message}`
      );
    } else {
      setErrorMessages(null);
    }
  }, [removePrequalAttachmentError]);

  const handleOpenChange = (): void => {
    setSelectedIds(props.selectedAttachments.map((att) => att.uuid));

    setIsOpen((p) => !p);
    setErrorMessages(null);
  };

  const handleDelete = async (): Promise<void> => {
    let deleteSuccess;
    if (isPrequalContext) {
      const attachments = selectedIds.map((id) => ({
        applicationUuid: primaryId,
        attachmentUuid: id,
      }));

      const { success } = await removePrequalAttachment(attachments);
      deleteSuccess = success;
    } else {
      const attachments = selectedIds.map((id) => ({
        submissionUuid: primaryId,
        attachmentUuid: id,
      }));

      const { success } = await removeAttachment(attachments);
      deleteSuccess = success;
    }

    if (deleteSuccess) {
      handleOpenChange();
      reloadAttachmentManager();
    }
  };

  return (
    <Modal
      title="Delete Document(s)"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {errorMessages && (
            <Banner dismissable={false}>{errorMessages}</Banner>
          )}
          <Subheading>
            You have selected to delete the following documents:
          </Subheading>
          <Flex flexDirection="column" gap={2}>
            {props.selectedAttachments.map((att) => (
              <Box key={att.uuid}>
                <Checkbox
                  label={att.fileName}
                  checked={selectedIds.includes(att.uuid)}
                  onCheckboxChange={(newCheckedVal) => {
                    if (newCheckedVal) {
                      setSelectedIds((prev) => [...prev, att.uuid]);
                    } else {
                      setSelectedIds((prev) =>
                        prev.filter((id) => id !== att.uuid)
                      );
                    }
                  }}
                />
              </Box>
            ))}
          </Flex>
          <Flex mt={3} justifyContent="center" gap={3}>
            <Button
              variant="danger"
              startIcon="trash"
              onClick={handleDelete}
              disabled={selectedIds.length === 0}
            >
              Delete Document(s)
            </Button>
            <Button
              variant="secondary"
              startIcon="x"
              onClick={handleOpenChange}
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      }
      trigger={
        <Button
          startIcon="trash-alt"
          disabled={props.selectedAttachments.length === 0}
          variant={'danger'}
        >
          Delete Document(s)
        </Button>
      }
    />
  );
};
