import {
  Accordion,
  Banner,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  formatDateString,
  Grid,
  Icon,
  Link,
  Subheading,
  Tabs,
  Text,
  Tooltip,
} from '@forward-financing/fast-forward';

import React, { useState } from 'react';

import { FUNDING_ALIAS_URL } from 'constants/globals';
import { toError } from 'helpers/errorUtils';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';
import { updateDocument } from '../OwnerOverview/ownerOverviewFetchUtils';
import {
  useGetLatestLexisNexisReportsMap,
  useGetLatestPaynetReports,
} from '../LastPulledOnDate/lastPulledOnDateHooks';
import { Submission } from '../SubmissionUnderwritingContainer.types';
import { BusinessSummaryTable } from './BusinessSummaryTable';

import {
  useCustomer,
  useBusinessCustomerLexisNexisOverview,
  useBusinessCustomerLexisNexisReports,
  useWebPresence,
  useExperianMetaData,
  usePullCustomerCreditReport,
  useLexisNexisCustomerSearchResults,
  usePullCustomerLexisNexisReport,
  usePaynetSearchResults,
  usePullPaynetReport,
} from './businessOverviewHooks';
import { RelatedExecutivesTable } from './RelatedExecutivesTable';
import { UccTable } from './UccTable';
import { LexisNexisVerifiedTable } from './LexisNexisVerifiedTable';
import { PublicRecordsCountTable } from './PublicRecordsCountTable';
import { LexisNexisSearchResultsTable } from './LexisNexisSearchResultsTable';
import { CustomerReportDeleteConfirmationModal } from './CustomerReportDeleteConfirmationModal';
import { PaynetSearchResultsTable } from './PaynetResultsTable';
import { SOSFieldsTable } from './SOSFieldsTable';

export interface BusinessOverviewProps {
  submissionUuid: string;
  submission?: Submission;
}

export const BusinessOverviewSection = ({
  submissionUuid,
  submission,
}: BusinessOverviewProps): JSX.Element => {
  const [documentsUpdateError, setDocumentsUpdateError] = useState<string>();
  const { role } = useUserContext();

  const {
    customer: customerBusinessSummary,
    loading: customerLoading,
    error: customerError,
  } = useCustomer(submission?.customerUuid);

  const {
    customerLexisNexisReports,
    loading: reportsLoading,
    error: reportsError,
    refetch: refetchCustomerLexisNexis,
  } = useBusinessCustomerLexisNexisReports(submissionUuid);

  const {
    experianMetaData,
    loading: experianMetaDataLoading,
    error: experianMetaDataError,
  } = useExperianMetaData(submissionUuid);

  const {
    data: webPresence,
    loading: webPresenceLoading,
    error: webPresenceError,
  } = useWebPresence(submissionUuid);
  const {
    overviews,
    loading: overviewsLoading,
    error: overviewsError,
  } = useBusinessCustomerLexisNexisOverview(submissionUuid);
  const [
    pullCustomerCreditReport,
    {
      success: pullCustomerCreditSuccess,
      loading: pullCustomerCreditLoading,
      error: pullCustomerCreditError,
    },
  ] = usePullCustomerCreditReport(submissionUuid);

  const [canNotPullError, setCanNotPullError] = useState<string | undefined>();

  const handlePullCustomerCredit = (): void => {
    try {
      if (!submission?.customerUuid) {
        throw new Error('Missing Customer UUID');
      }
      if (!customerBusinessSummary) {
        throw new Error('Missing customer data');
      }
      if (!customerBusinessSummary.fullAddress) {
        throw new Error('Missing customer address');
      }

      // this method handles any thrown errors internally
      void pullCustomerCreditReport({
        customerUuid: submission.customerUuid,
        street: customerBusinessSummary.fullAddress.street1,
        city: customerBusinessSummary.fullAddress.city,
        state: customerBusinessSummary.fullAddress.state,
        zip: customerBusinessSummary.fullAddress.zip,
        fein: customerBusinessSummary.fein ?? undefined,
        name: customerBusinessSummary.legalName,
      });
    } catch (e: unknown) {
      const error = toError(e);
      setCanNotPullError(
        `Unable to pull customer credit report: ${error.message}`
      );
    }
  };
  const latestLexisNexisReportsMap =
    useGetLatestLexisNexisReportsMap(submissionUuid);
  const {
    documents: lexisNexisSearchDocuments,
    loading: lexisNexisSearchResultsLoading,
    error: lexisNexisSearchResultsError,
  } = useLexisNexisCustomerSearchResults(
    submissionUuid,
    latestLexisNexisReportsMap
  );

  const [
    pullPaynet,
    {
      data: pullPaynetData,
      loading: pullPaynetLoading,
      error: pullPaynetError,
    },
  ] = usePullPaynetReport(submissionUuid);

  const latestPaynetReportsMap = useGetLatestPaynetReports(submissionUuid);
  const {
    results: paynetSearchResults,
    loading: paynetSearchResultsLoading,
    error: paynetSearchResultsError,
  } = usePaynetSearchResults(submissionUuid, latestPaynetReportsMap);
  const [
    pullLexisNexis,
    { data: pullLexisNexisSuccess, error: pullLexisNexisError },
  ] = usePullCustomerLexisNexisReport(submissionUuid);

  const handleUpdateDocument = async (
    documentId: number,
    primary: boolean
  ): Promise<void> => {
    try {
      await updateDocument(documentId, primary);
      void refetchCustomerLexisNexis();
    } catch (e: unknown) {
      const error = toError(e);
      setDocumentsUpdateError(error.message);
    }
  };

  const lexisNexisPullDate =
    customerLexisNexisReports &&
    customerLexisNexisReports[0] &&
    customerLexisNexisReports[0].createdAt &&
    formatDateString(customerLexisNexisReports[0].createdAt);

  const experianPullDate =
    experianMetaData?.fetchedAt && formatDateString(experianMetaData.fetchedAt);

  const BusinessSummary: JSX.Element = (
    <>
      <Box marginTop={2}>
        <Flex
          flexDirection={'row'}
          gap={1}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Flex alignItems={'center'} gap={1}>
            Lexis Nexis
            {lexisNexisPullDate ? (
              <Tooltip
                content={<>Last Pulled Date {lexisNexisPullDate}</>}
                trigger={
                  <span>
                    <Icon name="check-circle" color="success" />
                  </span>
                }
              />
            ) : (
              <Icon name="circle-minus" />
            )}
          </Flex>
          <Divider orientation="vertical" />
          <Flex alignItems={'center'} gap={1}>
            Credit
            {experianPullDate ? (
              <Tooltip
                content={<>Last Pulled Date {experianPullDate}</>}
                trigger={
                  <span>
                    <Icon name="check-circle" color="success" />
                  </span>
                }
              />
            ) : (
              <Icon name="circle-minus" />
            )}
          </Flex>
        </Flex>
      </Box>
      <Box backgroundColor="gray-200" padding={3}>
        <Flex flexDirection={'column'} gap={2}>
          {submission && (
            <BusinessSummaryTable
              customer={customerBusinessSummary}
              experianMetaData={experianMetaData}
              submission={submission}
              webPresence={webPresence}
              useOfFunds={submission?.useOfFunds}
            />
          )}
          {featureFlags.show_sos_fields && (
            <Card
              title="SOS Business Data"
              collapsible
              initialOpenState={false}
            >
              <SOSFieldsTable submissionUuid={submissionUuid} />
            </Card>
          )}
        </Flex>
      </Box>
    </>
  );

  if (
    customerLoading ||
    reportsLoading ||
    webPresenceLoading ||
    experianMetaDataLoading ||
    overviewsLoading ||
    lexisNexisSearchResultsLoading ||
    paynetSearchResultsLoading
  ) {
    // let's not use a loading spinner for now. We can revisit later
    return <></>;
  }

  /**
   * The below logic is complicated a bit by the fact that we have two different
   * sets of logic for when to display this section depending on the feature
   * flag state.
   *
   * If the feature flag is on, we want to display the section any time the
   * documents array contains one or more documents. If the feature flag is
   * off, we assume there is one document and display the section only if the
   * that document contains at least one result.
   *
   * This logic will become much simpler once the feature flag is removed.
   *
   * -@bradleyden
   * Nov 29, 2023
   */
  const showLexisNexisSearchSection =
    lexisNexisSearchDocuments &&
    lexisNexisSearchDocuments.length > 0 &&
    (featureFlags.send_multiple_ln_search_results ||
      (lexisNexisSearchDocuments[0] &&
        lexisNexisSearchDocuments[0]?.results?.length > 0));

  // The report tab we want open by default. This will be the primary
  // report if one was set, or the most recent report if not.
  const defaultReport =
    customerLexisNexisReports?.find((report) => report.primary) ||
    customerLexisNexisReports?.[0];

  return (
    <Card title="Business Overview" collapsible={true}>
      {customerError && (
        <Banner dismissable={false} variant="error">
          {customerError}
        </Banner>
      )}
      {experianMetaDataError &&
        experianMetaDataError !== 'Experian not pulled' && (
          <Banner dismissable={false} variant="error">
            {experianMetaDataError}
          </Banner>
        )}
      {webPresenceError && (
        <Banner dismissable={false} variant="error">
          {webPresenceError.message}
        </Banner>
      )}
      {reportsError && (
        <Banner dismissable={false} variant="error">
          {reportsError}
        </Banner>
      )}
      {overviewsError && (
        <Banner dismissable={false} variant="error">
          {overviewsError}
        </Banner>
      )}
      {lexisNexisSearchResultsError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisSearchResultsError}
        </Banner>
      )}
      {pullLexisNexisError && (
        <Banner dismissable={false} variant="error">
          {pullLexisNexisError.message}
        </Banner>
      )}
      {pullPaynetError && (
        <Banner dismissable={false} variant="error">
          {pullPaynetError.message}
        </Banner>
      )}
      {paynetSearchResultsError && (
        <Banner dismissable={false} variant="error">
          {paynetSearchResultsError}
        </Banner>
      )}

      <Grid gutter>
        <Grid.Item xs={12} m={12} l={12}>
          <Accordion type="multiple" defaultValue={['lexisNexis', 'paynet']}>
            {lexisNexisSearchDocuments && showLexisNexisSearchSection && (
              <Accordion.Item value="lexisNexis">
                <Accordion.Trigger>
                  LexisNexis Results (
                  {featureFlags.send_multiple_ln_search_results
                    ? lexisNexisSearchDocuments.length
                    : lexisNexisSearchDocuments[0]?.results?.length}
                  )
                </Accordion.Trigger>
                <Accordion.Content>
                  <LexisNexisSearchResultsTable
                    submissionUuid={submissionUuid}
                    searchResults={lexisNexisSearchDocuments}
                  />
                </Accordion.Content>
              </Accordion.Item>
            )}
            {paynetSearchResults && (
              <Accordion.Item value="paynet">
                <Accordion.Trigger>
                  Paynet Results ({paynetSearchResults.length})
                </Accordion.Trigger>
                <Accordion.Content>
                  <PaynetSearchResultsTable
                    searchResults={paynetSearchResults}
                    submissionUuid={submissionUuid}
                  />
                </Accordion.Content>
              </Accordion.Item>
            )}
          </Accordion>
        </Grid.Item>
        <Grid.Item xs={12} m={12} l={12}>
          {canNotPullError && (
            <Banner dismissable={false} variant="error">
              {canNotPullError}
            </Banner>
          )}
          {pullCustomerCreditError && (
            <Banner dismissable={false} variant="error">
              {pullCustomerCreditError.message}
            </Banner>
          )}
          {pullCustomerCreditSuccess && (
            <Banner dismissable={false} variant="success">
              Experian Commercial Report was pulled successfully! It takes
              anywhere from 5 seconds to 4 minutes to process the data, so
              please allow a little bit of time before refreshing the page. If
              you are still not able to view the report after 4 minutes, please
              contact the tech support.
            </Banner>
          )}
          {pullLexisNexisSuccess && (
            <Banner dismissable={false} variant="success">
              <Flex flexDirection={'row'} gap={2}>
                <Icon name="circle-check" size="2x" />
                <Text>
                  LexisNexis was pulled successfully! It takes anywhere from 5
                  seconds to 4 minutes to process the data, so please allow a
                  little bit of time before refreshing the page. If you are
                  still not able to view the report after 4 minutes, please
                  contact the tech support.
                </Text>
              </Flex>
            </Banner>
          )}
          {pullPaynetData?.success && (
            <Banner dismissable={false} variant="success">
              <Flex flexDirection={'row'} gap={2}>
                <Icon name="circle-check" size="2x" />
                <Text>
                  Paynet was pulled successfully! It takes anywhere from 5
                  seconds to 4 minutes to process the data, so please allow a
                  little bit of time before refreshing the page. If you are
                  still not able to view the report after 4 minutes, please
                  contact the tech support.
                </Text>
              </Flex>
            </Banner>
          )}
          <Flex gap={2} mb={2}>
            <Button
              startIcon={'square-poll-vertical'}
              onClick={() => pullLexisNexis({ force: true })}
            >
              Pull LexisNexis
            </Button>
            <Button
              startIcon={'refresh'}
              onClick={() => pullLexisNexis({ force: false })}
            >
              Refresh LexisNexis
            </Button>
            <Button
              disabled={pullCustomerCreditLoading}
              startIcon="square-poll-vertical"
              onClick={handlePullCustomerCredit}
            >
              Pull Credit
            </Button>
            <Button
              disabled={pullPaynetLoading}
              startIcon="square-poll-vertical"
              onClick={() => pullPaynet({})}
            >
              Pull Paynet
            </Button>
          </Flex>
          <Flex alignItems="center">
            <Link
              newTab
              href={
                new URL(
                  `/dashboard/${submissionUuid}/business_report_search`,
                  window.location.origin
                )
              }
            >
              <Icon name="magnifying-glass" /> Manual Report Search
            </Link>
            <Divider orientation="vertical" />
            <Link href={new URL(FUNDING_ALIAS_URL)} newTab>
              <Icon name="users" /> Show Funding Aliases
            </Link>
          </Flex>
        </Grid.Item>
      </Grid>
      {customerLexisNexisReports?.length ? (
        <Tabs defaultValue={defaultReport?.documentId?.toString()}>
          <Tabs.List>
            {customerLexisNexisReports?.map((report) => (
              <Tabs.Trigger
                key={report.documentId}
                value={report.documentId.toString()}
              >
                <Flex gap={2}>
                  {report.primary && <Icon name="flag" />}
                  {report.companyName} {formatDateString(report.createdAt)}
                </Flex>
              </Tabs.Trigger>
            ))}
          </Tabs.List>
          {customerLexisNexisReports?.map((report) => {
            const overviewForReport = overviews?.find(
              (overview) => overview.documentId === report.documentId
            );
            return (
              <Tabs.Content
                key={report.documentId}
                value={report.documentId.toString()}
              >
                <Grid gutter>
                  {documentsUpdateError && (
                    <Banner dismissable={false} variant="error">
                      {documentsUpdateError}
                    </Banner>
                  )}
                  <Grid.Item>
                    <Flex flexDirection={'row'} gap={2}>
                      <Button
                        startIcon={'flag'}
                        onClick={() =>
                          handleUpdateDocument(report.documentId, true)
                        }
                        disabled={report.primary}
                      >
                        {report.primary
                          ? 'Flagged as Primary Report'
                          : 'Flag Report as Primary'}
                      </Button>

                      {role === 'admin' && (
                        <CustomerReportDeleteConfirmationModal
                          document={report}
                          afterDelete={refetchCustomerLexisNexis}
                        />
                      )}
                    </Flex>
                  </Grid.Item>

                  <Grid.Item xs={12} m={6} xl={6}>
                    {BusinessSummary}
                  </Grid.Item>

                  <Grid.Item xs={12} m={6} xl={6}>
                    <Subheading variant="section">
                      Customer Lexis Nexis Reports
                    </Subheading>

                    <Box backgroundColor="gray-200" padding={3}>
                      <LexisNexisVerifiedTable
                        documentId={report.documentId}
                        businessName={report.companyName}
                        address={report.address}
                        businessPhone={report.companyPhoneNumber}
                        established={report.established}
                        submissionUuid={submissionUuid}
                      />
                    </Box>

                    <Box backgroundColor="gray-200" padding={3} marginTop={2}>
                      <RelatedExecutivesTable executives={report.executives} />
                    </Box>

                    <Box backgroundColor="gray-200" padding={3} marginTop={2}>
                      <PublicRecordsCountTable
                        lexisNexisOverview={overviewForReport}
                        submissionUuid={submissionUuid}
                      />
                    </Box>
                  </Grid.Item>

                  {submission?.customerUuid && (
                    <Grid.Item>
                      <Box backgroundColor="gray-200" padding={3}>
                        <UccTable
                          uccs={report.uccs}
                          reportId={report.documentId}
                          customerUuid={submission?.customerUuid}
                          submissionUuid={submissionUuid}
                        />
                      </Box>
                    </Grid.Item>
                  )}
                </Grid>
              </Tabs.Content>
            );
          })}
        </Tabs>
      ) : (
        <Grid gutter>
          <Grid.Item xs={12} m={6} xl={6}>
            {BusinessSummary}
          </Grid.Item>
        </Grid>
      )}
    </Card>
  );
};
