import * as React from 'react';
import { useParams } from 'react-router-dom';
import { FundingClient } from '../../api/FundingClient';
import { UnderwritingClient } from '../../api/UnderwritingClient';
import { Application, Account } from '../../api/UnderwritingClient/codecs';
import { AuthClient } from '../../api/AuthClient';
import { GOOGLE_MAPS_SOURCE } from '../../constants/globals';
import { AnalyticsGatewayClient } from '../../api/AnalyticsGatewayClient';
import { useUpdateApplication } from './WizardFetchHooks';
import { PrequalForm } from '.';

type Params = {
  uuid: string;
};

/* istanbul ignore next */
const loadGooglePlacesLibrary = (): void => {
  const script = document.createElement('script');
  script.src = GOOGLE_MAPS_SOURCE;
  script.charset = 'utf-8';
  script.async = true;
  document.head.appendChild(script);
};

export const PrequalFormContainer = (): JSX.Element => {
  const { uuid } = useParams<Params>();

  const [updateApplication] = useUpdateApplication();

  const upsertApplication = (
    accountUuid: string,
    application: Application
  ): Promise<Application> =>
    application.uuid && application.uuid.length > 1
      ? UnderwritingClient.updateApplication(application)
      : UnderwritingClient.createApplication(accountUuid, application);

  const upsertAccount = (account: Account): Promise<Account> =>
    account.uuid && account.uuid.length
      ? UnderwritingClient.updateAccount(account)
      : UnderwritingClient.createAccount(account);

  return (
    <PrequalForm
      getPartners={FundingClient.fetchPartners}
      getIndustryTypes={UnderwritingClient.fetchIndustryTypes}
      getUsStates={UnderwritingClient.fetchStates}
      getAuthenticatedUser={AuthClient.fetchAuthenticatedUser}
      getApplication={UnderwritingClient.fetchApplication}
      getAccount={UnderwritingClient.fetchAccount}
      getContacts={UnderwritingClient.fetchContacts}
      getOwners={UnderwritingClient.fetchCustomerOwners}
      upsertApplicationContacts={UnderwritingClient.upsertApplicationContacts}
      upsertApplication={upsertApplication}
      upsertAccount={upsertAccount}
      createOpportunity={UnderwritingClient.createOpportunity}
      declineApplication={UnderwritingClient.declineApplication}
      applicationUuid={uuid}
      loadGooglePlacesLibrary={loadGooglePlacesLibrary}
      sendUnmaskedFieldLogs={AnalyticsGatewayClient.createLogSender}
      updateApplication={updateApplication}
    />
  );
};
