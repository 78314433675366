import React, { useCallback } from 'react';
import {
  Flex,
  Subheading,
  Loading,
  Banner,
} from '@forward-financing/fast-forward';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { SuggestedPricingContainer } from '../SuggestedPricingContainer';
import { useCreateCalculatedOffer } from '../ledgerHooks';
import { Ledger } from '../ledger.types';
import { OffersTable } from './OffersTable';
import { LedgerOffer } from './offers.types';

export type OffersContainerProps = {
  customerUuid?: string;
  eligibleForWeekly: boolean;
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  isRenewal?: boolean;
  ledger: Ledger;
  readOnly?: boolean;
  submissionUuid: string;
  offersData: LedgerOffer[] | undefined;
  offersError: Error | undefined;
  offersLoading: boolean;
};

export const OffersContainer = ({
  customerUuid,
  eligibleForWeekly,
  handleChangeLedger,
  isRenewal,
  ledger,
  offersData,
  offersError,
  offersLoading,
  readOnly,
  submissionUuid,
}: OffersContainerProps): JSX.Element => {
  const [calculateOffer, { error: calculateOfferError }] =
    useCreateCalculatedOffer();

  const handleCalculateOffer = useCallback(
    async (
      offer: Partial<LedgerOffer> | undefined,
      months: string
    ): Promise<MutationResponse> => {
      if (!offer) return { success: false, response: null };

      return calculateOffer({
        submissionUuid: submissionUuid,
        ledgerId: ledger.id,
        ledger: ledger,
        offer,
        months,
      });
    },
    [calculateOffer, ledger, submissionUuid]
  );

  return (
    <Flex flexDirection="column">
      <Subheading>Offer</Subheading>

      {offersLoading && <Loading />}

      {!offersLoading && offersError && <Banner>{offersError.message}</Banner>}
      {calculateOfferError && <Banner>{calculateOfferError.message}</Banner>}

      {!isRenewal && (
        <SuggestedPricingContainer
          ledger={ledger}
          submissionUuid={submissionUuid}
          customerUuid={customerUuid}
        />
      )}

      {!offersLoading && offersData && (
        <OffersTable
          ledgerCreationDate={ledger.createdAt}
          fetchedOffers={offersData}
          readOnly={readOnly}
          handleChangeLedger={handleChangeLedger}
          handleOfferCalculation={handleCalculateOffer}
          eligibleForWeekly={eligibleForWeekly}
        />
      )}
    </Flex>
  );
};
