import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';

export const scoreToBgColor7 = new Map<
  number | undefined,
  { bgColor: ColorVariant; borderColor: ColorVariant }
>([
  [undefined, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [0, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [1, { bgColor: 'red-200', borderColor: 'red-600' }],
  [2, { bgColor: 'red-100', borderColor: 'red-500' }],
  [3, { bgColor: 'orange-100', borderColor: 'orange-500' }],
  [4, { bgColor: 'yellow-100', borderColor: 'yellow-500' }],
  [5, { bgColor: 'success-100', borderColor: 'success-500' }],
  [6, { bgColor: 'success-300', borderColor: 'success-600' }],
  [7, { bgColor: 'success-400', borderColor: 'success-800' }],
]);

export const scoreToBgColor5 = new Map<
  number | undefined,
  { bgColor: ColorVariant; borderColor: ColorVariant }
>([
  [undefined, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [0, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [1, { bgColor: 'red-100', borderColor: 'red-500' }],
  [2, { bgColor: 'orange-100', borderColor: 'orange-500' }],
  [3, { bgColor: 'yellow-100', borderColor: 'yellow-500' }],
  [4, { bgColor: 'success-100', borderColor: 'success-500' }],
  [5, { bgColor: 'success-300', borderColor: 'success-600' }],
]);

export const scoreToBgColor3 = new Map<
  number | undefined,
  { bgColor: ColorVariant; borderColor: ColorVariant }
>([
  [undefined, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [0, { bgColor: 'gray-100', borderColor: 'gray-800' }],
  [1, { bgColor: 'red-100', borderColor: 'red-500' }],
  [2, { bgColor: 'yellow-100', borderColor: 'yellow-500' }],
  [3, { bgColor: 'success-400', borderColor: 'success-800' }],
]);
