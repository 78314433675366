import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  Banner,
  Box,
  Divider,
  Flex,
  formatDateTimeString,
  Grid,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { featureFlags } from 'helpers/featureFlags';
import {
  ExceptionRequest,
  ExceptionRequestStatus,
} from '../../ExceptionsRequest/exceptionsRequest.types';
import { ExceptionRequestOfferTable } from './ExceptionRequestOfferTable';
import { ExceptionRequestActionButtons } from './ActionButtons/ExceptionRequestActionButtons';
import {
  useBankingSuggestedPricing,
  useGetLedger,
  useUnderwritingSuggestedPricing,
} from './submisionExceptionRequestHooks';
import { ExceptionSuggestedPricing } from './ExceptionSuggestedPricing';

const STATUS_READABLE_NAMES: Record<ExceptionRequestStatus, string> = {
  pending_review: 'Pending Assignment',
  in_progress: 'In Progress',
  reviewed: 'Reviewed',
};

const STATUS_BADGE_COLORS: Record<ExceptionRequestStatus, ColorVariant> = {
  pending_review: 'yellow-200',
  in_progress: 'yellow-200',
  reviewed: 'green-200',
};

export interface ExceptionRequestProps {
  exceptionRequest: ExceptionRequest;
  refetchExceptionRequests: () => void;
  submissionUuid: string;
  requests: ExceptionRequest[];
}

const StyledBox = styled(Box)`
  &&&&& {
    border-radius: 6px;
  }
`;

export const ExceptionRequestDetails = ({
  exceptionRequest,
  refetchExceptionRequests,
  submissionUuid,
  requests,
}: ExceptionRequestProps): JSX.Element => {
  const shouldShowReviewer =
    ['in_progress', 'reviewed'].includes(exceptionRequest.status) &&
    !!exceptionRequest.reviewerUser;
  const {
    data: bankingSuggestedPricingData,
    error: bankingSuggestedPricingError,
  } = useBankingSuggestedPricing(exceptionRequest.offer?.underwritingLedgerId);
  const { data: ledgerData } = useGetLedger(
    exceptionRequest.offer?.underwritingLedgerId
  );
  const {
    data: underwritingSuggestedPricingData,
    error: underwritingngSuggestedPricingError,
  } = useUnderwritingSuggestedPricing(submissionUuid, {
    sourceKey: defaultTo(ledgerData?.ledgerId?.toString(), ''),
    // Hardcode the sourceType to Underwriting::Ledger for now
    sourceType: 'Underwriting::Ledger',
    revenue: defaultTo(ledgerData?.revenue?.toString(), ''),
    financingsCount: defaultTo(ledgerData?.financingCount?.toString(), ''),
    financingsGross: defaultTo(ledgerData?.financingGross, ''),
  });

  const suggestedPricingData =
    featureFlags.exception_request_uw_suggested_pricing
      ? underwritingSuggestedPricingData
      : bankingSuggestedPricingData;

  return (
    <Box key={exceptionRequest.id.toString()} backgroundColor={'gray-200'}>
      {bankingSuggestedPricingError && (
        <Banner variant="danger" dismissable={false}>
          {bankingSuggestedPricingError.message}
        </Banner>
      )}
      {underwritingngSuggestedPricingError && (
        <Banner variant="danger" dismissable={false}>
          {underwritingngSuggestedPricingError.message}
        </Banner>
      )}
      <Accordion
        collapsible={true}
        defaultValue={requests[0].id.toString()}
        type="single"
      >
        <Accordion.Item value={exceptionRequest.id.toString()}>
          <Accordion.Trigger>
            <Flex flexDirection={'row'} gap={3} alignItems={'center'}>
              <Text bold={true} size={'l'}>
                {formatDateTimeString(exceptionRequest.submittedAt)}
              </Text>
              <StyledBox
                backgroundColor={STATUS_BADGE_COLORS[exceptionRequest.status]}
                px={1}
              >
                <Text bold size="s">
                  {STATUS_READABLE_NAMES[exceptionRequest.status]}
                  {/* eslint-disable-next-line no-nested-ternary, no-negated-condition*/}
                  {exceptionRequest.status !== 'reviewed'
                    ? null
                    : exceptionRequest.declineNotes
                    ? ' / Decline'
                    : ' / Approval'}
                </Text>
              </StyledBox>
            </Flex>
          </Accordion.Trigger>
          <Accordion.Content>
            <Divider />
            {shouldShowReviewer && (
              <>
                <Grid gutter>
                  <Grid.Item m={3}>
                    <Flex flexDirection={'column'}>
                      <Text bold size={'l'}>
                        Reviewer
                      </Text>
                      <Text>{exceptionRequest.reviewerUser}</Text>
                      {exceptionRequest.reviewCompletedAt && (
                        <Text>
                          {formatDateTimeString(
                            exceptionRequest.reviewCompletedAt
                          )}
                        </Text>
                      )}
                    </Flex>
                  </Grid.Item>
                  {exceptionRequest.declineNotes && (
                    <Grid.Item m={9}>
                      <Flex flexDirection={'column'}>
                        <Text bold size={'l'}>
                          Decline Notes
                        </Text>
                        <Flex flexDirection="row" gap={1}>
                          <Text bold>Reason:</Text>
                          <Text>{exceptionRequest.declineReason}</Text>
                        </Flex>
                        <Text>{exceptionRequest.declineNotes}</Text>
                      </Flex>
                    </Grid.Item>
                  )}
                </Grid>

                <Divider />
              </>
            )}
            <Grid gutter>
              <Grid.Item m={3}>
                <Flex flexDirection={'column'}>
                  <Text bold size={'l'}>
                    Requested By
                  </Text>
                  <Text>{exceptionRequest.requestingUser}</Text>
                  <Text>
                    {formatDateTimeString(exceptionRequest.submittedAt)}
                  </Text>
                </Flex>
              </Grid.Item>
              <Grid.Item m={9}>
                <Flex flexDirection={'column'}>
                  <Text bold size={'l'}>
                    Request Note
                  </Text>
                  <Text>{exceptionRequest.offerExceptionNotes}</Text>
                </Flex>
              </Grid.Item>
            </Grid>
            <Box mt={3} mb={3}>
              <Grid>
                <Grid.Item xl={10}>
                  <ExceptionRequestOfferTable
                    exceptionRequest={exceptionRequest}
                  />
                </Grid.Item>
              </Grid>
            </Box>
            {suggestedPricingData && (
              <Flex alignItems={'center'} mt={2} gap={1}>
                <Text bold> System Suggested Pricing</Text>
                <ExceptionSuggestedPricing
                  suggestedPricingData={suggestedPricingData}
                />
              </Flex>
            )}
            {exceptionRequest.stipExceptionNotes && (
              <Box mt={3}>
                <Text bold size={'l'}>
                  Request to Waive Stipulations / Additional Documents
                </Text>
                <Text>{exceptionRequest.stipExceptionNotes}</Text>
              </Box>
            )}

            <ExceptionRequestActionButtons
              exceptionStatus={exceptionRequest.status}
              exceptionId={exceptionRequest.id}
              refetchExceptionRequests={refetchExceptionRequests}
            />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
