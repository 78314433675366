import React from 'react';
import { ExceptionRequestSuggestedPricing } from './exceptionRequest.types';
import { Flex, Tooltip, Text, Icon } from '@forward-financing/fast-forward';

type ExceptionSuggestedPricingProps = {
  suggestedPricingData: ExceptionRequestSuggestedPricing;
};
export const ExceptionSuggestedPricing = ({
  suggestedPricingData,
}: ExceptionSuggestedPricingProps): JSX.Element => {
  return (
    <Tooltip
      variant="gray-900"
      content={
        <>
          <Flex gap={2}>
            <Text bold>Funding Amount</Text>{' '}
            {suggestedPricingData.fundingAmount}
          </Flex>
          <Flex gap={2}>
            <Text bold>Term Length</Text> {suggestedPricingData.termLength}
          </Flex>
          <Flex gap={2}>
            <Text bold>Buy Rate</Text> {suggestedPricingData.buyRate}
          </Flex>
          <Flex gap={2}>
            <Text bold>Program</Text> {suggestedPricingData.programType}
          </Flex>
        </>
      }
      position="bottom"
      trigger={
        <span>
          <Icon name="info-circle" data-testid="suggested-pricing-icon" />{' '}
        </span>
      }
    />
  );
};
