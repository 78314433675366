import { useCallback, useEffect, useState } from 'react';
import { AuthClient } from 'api/AuthClient';
import { User } from 'api/AuthClient/codecs';
import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  useGetApiSubmission,
  UseGetApiSubmissionResponse,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { toError } from 'helpers/errorUtils';
import {
  AttachmentResponse,
  CreateAttachmentRequestBody,
  DocumentTags,
  OwnerResponse,
  UpdateAttachmentRequestBody,
  ValidFileExtensions,
} from 'types/api/underwriting/types';
import {
  useGetApiCustomer,
  UseGetApiCustomerResponse,
} from 'apiHooks/underwriting/customerFetchHooks';
import {
  useGetApiSubmissionOwners,
  useGetBatchApiOwners,
  UseGetBatchApiOwnersResponse,
} from 'apiHooks/underwriting/ownerFetchHooks';
import {
  fetchAttachmentsByApplicationUuid,
  fetchAttachmentsBySubmissionUuid,
  fetchDocumentTags,
  fetchFileExtensions,
} from 'api/underwriting/attachmentFetchUtils';
import {
  useApiCreateApplicationAttachmentBatch,
  useApiCreateAttachmentBatch,
  useApiDeleteApplicationAttachmentBatch,
  useApiDeleteAttachmentBatch,
  useApiUpdateApplicationAttachmentBatch,
  useApiUpdateAttachmentBatch,
  useGetApiAccount,
} from 'apiHooks/underwriting/attachmentFetchHooks';
import { convertFileToBase64String } from 'helpers/fileUtils';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { featureFlags } from 'helpers/featureFlags';
import {
  Account,
  Attachment,
  AttachmentDocumentTags,
  AttachmentSubmission,
  CreateAttachmentInput,
  Customer,
  Owner,
  UpdateAttachmentInput,
} from './attachmentManager.types';

const attachmentResponseToAttachment = (
  att: AttachmentResponse
): Attachment => ({
  applicationUuid: att.application_uuid,
  /*
   * This appears to be a MIME type.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  contentType: att.content_type,
  createdAt: att.created_at,
  description: att.description ?? undefined,
  documentTags: att.document_tags,
  expiresOn: att.expires_on ?? undefined,
  fileDeletedAt: att.file_deleted_at ?? undefined,
  fileName: att.file_name,
  source: att.source,
  submissionUuid: att.submission_uuid ?? undefined,
  updatedAt: att.updated_at,
  uuid: att.uuid,
  uploadedBy: att.uploaded_by ?? undefined,
});

interface UseGetAttachmentsResult {
  attachments?: Attachment[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useAttachments = (
  submissionUuid: string
): UseGetAttachmentsResult => {
  const [attachments, setAttachments] = useState<Attachment[] | undefined>(
    undefined
  );
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
  const [attachmentsError, setAttachmentsError] = useState<
    string | undefined
  >();

  const getAttachments = useCallback(async (): Promise<void> => {
    try {
      const att = await fetchAttachmentsBySubmissionUuid(submissionUuid);

      const formattedAtts = att.map(attachmentResponseToAttachment);

      setAttachments(formattedAtts);
    } catch (e: unknown) {
      const error = toError(e);

      setAttachmentsError(error.message);
    } finally {
      setAttachmentsLoading(false);
    }
  }, [submissionUuid]);

  useEffect(() => {
    void getAttachments();
  }, [submissionUuid, getAttachments]);

  return {
    loading: attachmentsLoading,
    attachments,
    error: attachmentsError,
    refetch: getAttachments,
  };
};

export const useApplicationAttachments = (
  applicationUuid: string
): UseGetAttachmentsResult => {
  const [attachments, setAttachments] = useState<Attachment[] | undefined>(
    undefined
  );
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
  const [attachmentsError, setAttachmentsError] = useState<
    string | undefined
  >();

  const getAttachments = useCallback(async (): Promise<void> => {
    try {
      const att = await fetchAttachmentsByApplicationUuid(applicationUuid);

      const formattedAtts = att.map(attachmentResponseToAttachment);

      setAttachments(formattedAtts);
    } catch (e: unknown) {
      const error = toError(e);

      setAttachmentsError(error.message);
    } finally {
      setAttachmentsLoading(false);
    }
  }, [applicationUuid]);

  useEffect(() => {
    void getAttachments();
  }, [applicationUuid, getAttachments]);

  return {
    loading: attachmentsLoading,
    attachments,
    error: attachmentsError,
    refetch: getAttachments,
  };
};

interface UseExtensionsResult {
  supportedFileExtensions?: ValidFileExtensions;
  loading: boolean;
  error?: string;
}

export const useSupportedFileExtensions = (): UseExtensionsResult => {
  const [supportedFileExtensions, setSupportedFileExtensions] = useState<
    ValidFileExtensions | undefined
  >(undefined);
  const [extensionsLoading, setExtensionsLoading] = useState<boolean>(true);
  const [extensionsError, setExtensionsError] = useState<string | undefined>();

  useEffect(() => {
    const getFileExtensions = async (): Promise<void> => {
      try {
        const extensions = await fetchFileExtensions();
        setSupportedFileExtensions(extensions);
      } catch (e: unknown) {
        const error = toError(e);

        setExtensionsError(error.message);
      } finally {
        setExtensionsLoading(false);
      }
    };
    void getFileExtensions();
  }, []);

  return {
    supportedFileExtensions,
    loading: extensionsLoading,
    error: extensionsError,
  };
};

interface UseUnderwriterUserResult {
  underwriters?: User[];
  loading: boolean;
  error?: string;
}

export const useUnderwriterUsers = (): UseUnderwriterUserResult => {
  const [underwritersError, setUnderwritersError] = useState<string>();
  const [underwriters, setUnderwriters] = useState<User[] | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUnderwritters = async (): Promise<void> => {
      try {
        const response = await AuthClient.fetchUsersByRole('underwriter');
        const sortedUnderwriters = [...response].sort((a, b) =>
          a.first_name > b.last_name ? 1 : -1
        );
        setUnderwriters(sortedUnderwriters);
      } catch (e: unknown) {
        const error = toError(e);
        setUnderwritersError(`Underwriters list: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void getUnderwritters();
  }, []);

  return {
    underwriters,
    loading,
    error: underwritersError,
  };
};

interface UseProcessingUserResult {
  processors?: User[];
  loading: boolean;
  error?: string;
}

export const useProcessingUsers = (): UseProcessingUserResult => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [processors, setProcessors] = useState<User[] | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUnderwritters = async (): Promise<void> => {
      try {
        const data = await AuthClient.fetchUsersByRole('processing');
        setProcessors(data);
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Processing Users list: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void getUnderwritters();
  }, []);

  return {
    processors: processors,
    loading,
    error: errorMessage,
  };
};

type DocumentTagsResult = {
  documentTags?: AttachmentDocumentTags;
  loading: boolean;
  error?: string;
};

export const useDocumentTags = (): DocumentTagsResult => {
  const [documentTags, setDocumentTags] = useState<DocumentTags | undefined>();
  const [documentTagsLoading, setDocumentTagsLoading] = useState<boolean>(true);
  const [documentTagsError, setDocumentTagsError] = useState<
    string | undefined
  >();

  useEffect(() => {
    const getDocumentTags = async (): Promise<void> => {
      try {
        const tags = await fetchDocumentTags();
        setDocumentTags(tags);
      } catch (e: unknown) {
        const error = toError(e);

        setDocumentTagsError(error.message);
      } finally {
        setDocumentTagsLoading(false);
      }
    };
    void getDocumentTags();
  }, []);

  return {
    documentTags: documentTags && {
      selectable: documentTags?.selectable,
      nonSelectable: documentTags?.non_selectable,
    },
    loading: documentTagsLoading,
    error: documentTagsError,
  };
};

export const useAccount = (
  applicationUuid: string
): UseGenericQueryResponse<Account> => {
  return useGenericFeatureQuery(
    useGetApiAccount,
    // No data transformation needed due to the current API response (as of Nov '23).
    (response?: Account) => response,
    applicationUuid
  );
};

interface SubmissionResult {
  submission?: AttachmentSubmission;
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toSubmission = (
  data: UseGetApiSubmissionResponse['data']
): AttachmentSubmission | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    uuid: data.uuid,
    stageName: data.stage_name,
    subStage: data.sub_stage ?? undefined,
    underwriterSignOffTimestamp:
      data.underwriter_sign_off_timestamp ?? undefined,
    underwriterSignOffId: data.underwriter_sign_off_id ?? undefined,
    underwriterSignOffName: data.underwriter_sign_off_name ?? undefined,
    customerUuid: data.customer_uuid,
    ownerUuids: data.owner_uuids,
    submissionName: data.name,
  };
};

export const useSubmission = (
  submissionUuid: string | undefined
): SubmissionResult => {
  const { data, loading, error, refetch } = useGetApiSubmission(submissionUuid);

  return {
    submission: toSubmission(data),
    loading,
    error,
    refetch,
  };
};

type UpdateBody = {
  underwriterSignOffId: number;
};

type UseUpdateUnderwriterSignOffResult = [
  (input: UpdateBody) => Promise<MutationResponse>,
  { data?: AttachmentSubmission; error?: Error }
];

export const useUpdateUnderwriterSignOff = (
  submissionUuid: string
): UseUpdateUnderwriterSignOffResult => {
  const [updateSubmission, { data, error }] = useUpdateApiSubmission();

  const updateFunction = (args: UpdateBody): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid,
      updateBody: {
        underwriter_sign_off_id: args.underwriterSignOffId,
      },
    });
  };

  return [
    updateFunction,
    {
      data: toSubmission(data),
      error,
    },
  ];
};

interface CustomerResult {
  customer?: Customer;
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toCustomer = (
  data: UseGetApiCustomerResponse['data']
): Customer | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    uuid: data.uuid,
    legalName: data.legal_name,
    dba: data.name,
    entityType: data.entity_type ?? undefined,
  };
};

export const useCustomer = (customerUuid?: string): CustomerResult => {
  const { data, loading, error, refetch } = useGetApiCustomer(customerUuid);

  return {
    customer: toCustomer(data),
    loading,
    error,
    refetch,
  };
};

const toOwner = (owner: OwnerResponse): Owner => {
  return {
    uuid: owner.uuid,
    name: `${owner.first_name} ${owner.last_name}`,
  };
};

type UseOwnerResult = UseGenericQueryResponse<Owner[]>;

interface OwnerResult {
  data?: Owner[];
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toOwners = (data: UseGetBatchApiOwnersResponse['data']): Owner[] => {
  if (!data) {
    return [];
  }

  return data.map((owner) => {
    return {
      uuid: owner.uuid,
      name: `${owner.first_name} ${owner.last_name}`,
    };
  });
};

export const useOwnersByOwnerUuids = (ownerUuids?: string[]): OwnerResult => {
  const { data, loading, error, refetch } = useGetBatchApiOwners(ownerUuids);

  return {
    data: toOwners(data),
    loading,
    error,
    refetch,
  };
};

const useOwnersBySubmissionUuid = (submissionUuid?: string): UseOwnerResult => {
  return useGenericFeatureQuery(
    useGetApiSubmissionOwners,
    (data) => data?.map(toOwner),
    submissionUuid
  );
};

export const useOwners = (
  submissionUuid?: string,
  ownerUuids?: string[]
): OwnerResult => {
  const owners = useOwnersBySubmissionUuid(submissionUuid);
  const ownersByOwnerId = useOwnersByOwnerUuids(ownerUuids);
  return featureFlags.fetch_owners_by_submissionUuid ? owners : ownersByOwnerId;
};

type UseCreateAttachmentResponse = [
  (args: CreateAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface CreateAttachmentArgs {
  submissionUuid: string;
  createBody: CreateAttachmentInput;
}

export const useCreateAttachment = (): UseCreateAttachmentResponse => {
  const [createFn, { error, ...rest }] = useApiCreateAttachmentBatch();

  const createFunction = async (
    args: CreateAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const createArgs = Promise.all(
      args.map(async ({ submissionUuid, createBody }) => {
        const fileString = await convertFileToBase64String(createBody.file);

        const body: CreateAttachmentRequestBody = {
          file: fileString,
          file_name: createBody.file.name,
          source: createBody.source,
          description: '',
          document_tags: [],
        };

        return { submissionUuid, createBody: body };
      })
    );

    const resolvedArgs = await createArgs;

    return createFn(resolvedArgs);
  };

  return [
    createFunction,
    {
      error,
      ...rest,
    },
  ];
};

type UseCreateApplicationAttachmentResponse = [
  (args: CreateApplicationAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

type CreateApplicationAttachmentArgs = {
  applicationUuid: string;
  createBody: CreateAttachmentInput;
};

export const useCreateApplicationAttachment =
  (): UseCreateApplicationAttachmentResponse => {
    const [createFn, { error, ...rest }] =
      useApiCreateApplicationAttachmentBatch();

    const createFunction = async (
      args: CreateApplicationAttachmentArgs[]
    ): Promise<MutationResponse> => {
      const createArgs = Promise.all(
        args.map(async ({ applicationUuid, createBody }) => {
          const fileString = await convertFileToBase64String(createBody.file);

          const body: CreateAttachmentRequestBody = {
            file: fileString,
            file_name: createBody.file.name,
            source: createBody.source,
            description: '',
            document_tags: [],
          };

          return { applicationUuid, createBody: body };
        })
      );

      const resolvedArgs = await createArgs;

      return createFn(resolvedArgs);
    };

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

type UseDeleteAttachmentResponse = [
  (args: DeleteAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface DeleteAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
}

export const useDeleteAttachment = (): UseDeleteAttachmentResponse => {
  const [deleteFn, { error, ...rest }] = useApiDeleteAttachmentBatch();

  const deleteFunction = async (
    args: DeleteAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const deleteArgs = args.map(({ submissionUuid, attachmentUuid }) => {
      return {
        submissionUuid,
        attachmentUuid,
      };
    });

    return deleteFn(deleteArgs);
  };

  return [
    deleteFunction,
    {
      error,
      ...rest,
    },
  ];
};

type UseDeleteApplicationAttachmentResponse = [
  (args: DeleteApplicationAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

type DeleteApplicationAttachmentArgs = {
  applicationUuid: string;
  attachmentUuid: string;
};

export const useDeleteApplicationAttachment =
  (): UseDeleteApplicationAttachmentResponse => {
    const [deleteFn, { error, ...rest }] =
      useApiDeleteApplicationAttachmentBatch();

    const deleteFunction = async (
      args: DeleteApplicationAttachmentArgs[]
    ): Promise<MutationResponse> => {
      const deleteArgs = args.map(({ applicationUuid, attachmentUuid }) => {
        return {
          applicationUuid,
          attachmentUuid,
        };
      });

      return deleteFn(deleteArgs);
    };

    return [
      deleteFunction,
      {
        error,
        ...rest,
      },
    ];
  };

type UseUpdateAttachmentResponse = [
  (args: UpdateAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface UpdateAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
  input: UpdateAttachmentInput;
}

export const useUpdateAttachment = (): UseUpdateAttachmentResponse => {
  const [updateFn, { error, ...rest }] = useApiUpdateAttachmentBatch();

  const updateFunction = async (
    args: UpdateAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const updateArgs = args.map(({ submissionUuid, attachmentUuid, input }) => {
      return {
        submissionUuid,
        attachmentUuid,
        body: {
          file_name: input.fileName,
          description: input.description,
          document_tags: input.documentTags,
          source: input.source,
          expires_on: input.expiresOn,
        } as UpdateAttachmentRequestBody,
      };
    });

    return updateFn(updateArgs);
  };

  return [
    updateFunction,
    {
      error,
      ...rest,
    },
  ];
};

type UseUpdateApplicationAttachmentResponse = [
  (args: UpdateApplicationAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

type UpdateApplicationAttachmentArgs = {
  applicationUuid: string;
  attachmentUuid: string;
  input: UpdateAttachmentInput;
};

export const useUpdateApplicationAttachment =
  (): UseUpdateApplicationAttachmentResponse => {
    const [updateFn, { error, ...rest }] =
      useApiUpdateApplicationAttachmentBatch();

    const updateFunction = async (
      args: UpdateApplicationAttachmentArgs[]
    ): Promise<MutationResponse> => {
      const updateArgs = args.map(
        ({ applicationUuid, attachmentUuid, input }) => {
          return {
            applicationUuid,
            attachmentUuid,
            body: {
              file_name: input.fileName,
              description: input.description,
              document_tags: input.documentTags,
              source: input.source,
              expires_on: input.expiresOn,
            } as UpdateAttachmentRequestBody,
          };
        }
      );

      return updateFn(updateArgs);
    };

    return [
      updateFunction,
      {
        error,
        ...rest,
      },
    ];
  };
