import React, { useMemo, useState } from 'react';
import { Flex, IconButton, Sheet } from '@forward-financing/fast-forward';

import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { Ledger } from '../ledger.types';
import { generateOffersTableColumnHeaders } from '../Offers/offers.utils';
import { LEDGER_TABLES_MONTHS } from '../constants';
import { BuyRatesTableCell } from './BuyRatesTableCell';
import { BuyRates, MonthRate } from './buyRates.types';
import {
  formatRate,
  getBuyRateByMonths,
  isBuyRates,
  toLedgerBuyRates,
} from './buyRates.utils';

type BuyRatesTableProps = {
  data: BuyRates;
  isEditable: boolean;
  handleOverrideBuyRates: (
    monthRates: MonthRate[]
  ) => Promise<MutationResponse>;
  handleChangeLedger: (updates: Partial<Ledger>) => void;
  ledgerCreationDate: string;
  readOnly?: boolean;
  handleResetBuyRates: () => Promise<MutationResponse>;
};

export const BuyRatesTable = ({
  data,
  ledgerCreationDate,
  isEditable,
  handleOverrideBuyRates,
  handleChangeLedger,
  readOnly,
  handleResetBuyRates,
}: BuyRatesTableProps): JSX.Element => {
  const [buyRates, setBuyRates] = useState<MonthRate[]>(data.buyRateTable);
  const [factorRates, setFactorRates] = useState<MonthRate[]>(
    data.totalFactorRateTable
  );

  const columnNames = useMemo(
    () => generateOffersTableColumnHeaders(ledgerCreationDate),
    [ledgerCreationDate]
  );

  const handleUpdate = async (payload: MonthRate): Promise<void> => {
    const updatedRates = buyRates?.map((rate) => {
      if (rate.months === payload.months) {
        return payload;
      }
      return rate;
    });

    const { success, response } = await handleOverrideBuyRates(updatedRates);

    if (success && isBuyRates(response)) {
      handleChangeLedger({ ...toLedgerBuyRates(response) });

      setBuyRates(response.buyRateTable);
      setFactorRates(response.totalFactorRateTable);
    }
  };

  const handleReset = async (): Promise<void> => {
    const { success, response } = await handleResetBuyRates();

    if (success && isBuyRates(response)) {
      handleChangeLedger({ ...toLedgerBuyRates(response) });

      setBuyRates(response.buyRateTable);
      setFactorRates(response.totalFactorRateTable);
    }
  };

  return (
    <div id="ledgers-sheet-table">
      <Sheet>
        <Sheet.Head>
          <Sheet.ColumnHeader>Term Length (Months)</Sheet.ColumnHeader>
          {columnNames.map((header) => (
            <Sheet.ColumnHeader minColumnWidth="small" key={header}>
              {header}
            </Sheet.ColumnHeader>
          ))}
        </Sheet.Head>

        <Sheet.Body>
          <Sheet.Row>
            <Sheet.RowHeader>
              <Flex flexDirection="row" alignItems="center">
                Buy Rate
                {isEditable && (
                  <IconButton
                    icon="arrow-left-rotate"
                    label="Reset"
                    hiddenLabel
                    onClick={handleReset}
                  />
                )}
              </Flex>
            </Sheet.RowHeader>

            {LEDGER_TABLES_MONTHS.map((months) => (
              <BuyRatesTableCell
                isEditable={isEditable && !readOnly}
                handleUpdate={handleUpdate}
                months={getBuyRateByMonths(buyRates, months)?.months}
                rate={getBuyRateByMonths(buyRates, months)?.rate}
                key={`${getBuyRateByMonths(buyRates, months)?.months}-buy-rate`}
              />
            ))}
          </Sheet.Row>

          <Sheet.Row>
            <Sheet.RowHeader>Max Factor Rate</Sheet.RowHeader>

            {LEDGER_TABLES_MONTHS.map((months) => (
              <Sheet.TextCell
                key={`${
                  getBuyRateByMonths(factorRates, months)?.months
                }-max-factor-rate`}
                value={formatRate(
                  getBuyRateByMonths(factorRates, months)?.rate
                )}
              />
            ))}
          </Sheet.Row>
        </Sheet.Body>
      </Sheet>
    </div>
  );
};
