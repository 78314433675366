import React, { useEffect } from 'react';
import {
  Banner,
  Box,
  Flex,
  formatDateString,
  formatDateTimeString,
  formatPhoneNumber,
  Icon,
  IconButton,
  Loading,
  Subheading,
  Table,
  Tag,
  Text,
} from '@forward-financing/fast-forward';
import { IndependentSalesOrganization } from '../ApplicationSnapshot/applicationSnapshot.types';
import { useLazySimilarOwners, useOwner } from './matchedRecordsHooks';
import {
  CustomerSubmission,
  Owner,
  SimilarOwner,
  SimilarOwnerSubmission,
} from './matchedRecords.types';

export const verifyMatchingAddress = (
  street1?: string,
  city?: string,
  owner?: Owner
): boolean => {
  if (street1 && city && owner?.address?.street1 && owner?.address?.city) {
    return (
      owner.address.street1.toLowerCase().trim() ===
        street1.toLowerCase().trim() &&
      owner.address.city.toLowerCase().trim() === city.toLowerCase().trim()
    );
  }

  return false;
};

/**
 * We want a sorted list of only submissions that have a dateAppReceived
 * OR are in the Renewal Prospecting or Renewals (Ineligible) stages.
 */
export const filterAndSortSubmissions = (
  similarOwner: SimilarOwner
): SimilarOwnerSubmission[] =>
  similarOwner.submissions
    .filter((submission) => {
      return (
        submission.dateAppReceived ||
        ['Renewal Prospecting', 'Renewals (Ineligible)'].includes(
          submission.stageName || ''
        )
      );
    })
    .sort((a, b) =>
      (a.dateAppReceived ?? 0) >= (b.dateAppReceived ?? 0) ? -1 : 1
    );

export interface SimilarOwnersTableProps {
  ownerUuid: string;
  currentSubmissionUuid: string;
  submissions: CustomerSubmission[] | undefined;
  isos: IndependentSalesOrganization[] | undefined;
}

export interface SubmissionSubRowProps {
  submission?: SimilarOwnerSubmission;
  renderSubstage: (subStage?: string) => string | undefined;
}

export const SubmissionSubRow = ({
  submission,
  renderSubstage,
}: SubmissionSubRowProps): JSX.Element => {
  return (
    <>
      <Table.Cell>{submission?.isoName || 'N/A'}</Table.Cell>
      {submission?.uuid ? (
        <Table.LinkCell
          href={
            new URL(`/submissions/${submission.uuid}`, window.location.origin)
          }
          newTab
        >
          {submission?.dateAppReceived &&
          !Number.isNaN(Date.parse(submission.dateAppReceived))
            ? formatDateString(submission.dateAppReceived)
            : 'N/A'}
        </Table.LinkCell>
      ) : (
        <Table.Cell>N/A</Table.Cell>
      )}
      <Table.Cell>{submission?.isRenewal && <Tag>Renewal</Tag>}</Table.Cell>
      <Table.Cell>
        {submission
          ? `${submission.stageName} / ${renderSubstage(submission.subStage)}`
          : 'N/A'}
      </Table.Cell>
      <Table.Cell columnWidth="large">
        {(submission?.declineDrivers || '').split(';')?.map((driver) => (
          <Text key={driver}>{driver}</Text>
        ))}
      </Table.Cell>
    </>
  );
};

export const SimilarOwnersTable = ({
  ownerUuid,
  submissions,
  currentSubmissionUuid,
  isos,
}: SimilarOwnersTableProps): JSX.Element => {
  const {
    owner,
    loading: ownerLoading,
    error: ownerError,
  } = useOwner(ownerUuid);

  const [
    fetchSimilarOwners,
    {
      similarOwnersObject: {
        refreshedAt: similarOwnersRefreshed,
        similarOwners,
      },
      loading: similarOwnersLoading,
      error: similarOwnersError,
    },
  ] = useLazySimilarOwners();

  useEffect(() => {
    void fetchSimilarOwners(ownerUuid, false);
  }, [fetchSimilarOwners, ownerUuid]);

  const isCurrentSubmission = (sub: CustomerSubmission): boolean =>
    currentSubmissionUuid === sub.submissionUuid;

  const currentSubmission = submissions?.find((sub) =>
    isCurrentSubmission(sub)
  );

  if (ownerLoading) {
    return <></>;
  }

  if (ownerError || similarOwnersError) {
    return (
      <>
        {ownerError && <Banner variant="error">{ownerError.message}</Banner>}
        {similarOwnersError && (
          <Banner variant="error">{similarOwnersError.message}</Banner>
        )}
      </>
    );
  }

  // istanbul ignore next - this should be impossible to reach
  if (!owner) {
    return (
      <Banner>
        Something has gone terribly wrong, and we are in a state that should be
        impossible. Please create an AST.
      </Banner>
    );
  }

  const currentContactIsoName = isos?.find(
    (s) => s.uuid === currentSubmission?.isoUuid
  )?.name;

  // Extracting the first part of the submission name string before the dash
  // for display purposes
  const currentSubmissionDisplayName =
    currentSubmission?.submissionName.split(' - ')[0];

  const renderSubStage = (subStage?: string): string => {
    if (subStage === 'Credit Committee') {
      return 'Decision Committee';
    }

    return subStage ?? '';
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" gap={2}>
        <Subheading variant="section">Similar Owners</Subheading>
        <Flex alignItems="center" gap={2}>
          {similarOwnersLoading ? (
            <Loading size="small" />
          ) : (
            <IconButton
              icon="refresh"
              label="Refresh similar owners"
              onClick={() => fetchSimilarOwners(ownerUuid, true)}
              hiddenLabel
            />
          )}
          <Text>
            Last Refresh:{' '}
            {similarOwnersRefreshed
              ? formatDateTimeString(similarOwnersRefreshed)
              : 'N/A'}
          </Text>
        </Flex>
      </Flex>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Owner Name</Table.ColumnHeader>
          <Table.ColumnHeader>SSN</Table.ColumnHeader>
          <Table.ColumnHeader abbr="Date of Birth">DOB</Table.ColumnHeader>
          <Table.ColumnHeader>Phone</Table.ColumnHeader>
          <Table.ColumnHeader>Address</Table.ColumnHeader>
          <Table.ColumnHeader>Customer</Table.ColumnHeader>
          <Table.ColumnHeader>ISO</Table.ColumnHeader>
          <Table.ColumnHeader colSpan={2}>Submission</Table.ColumnHeader>
          <Table.ColumnHeader>Stage / Sub-Stage</Table.ColumnHeader>
          <Table.ColumnHeader>Decline Drivers</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell backgroundColor="green-200">
              {`${owner.fullName} (Current Contact)`}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.ssnLastFour}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.birthdate && formatDateString(owner.birthdate)}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {owner.phoneNumber && formatPhoneNumber(owner.phoneNumber)}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200" />
            <Table.Cell backgroundColor="green-200">
              {currentSubmissionDisplayName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentContactIsoName}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission?.dateAppReceived &&
                formatDateString(currentSubmission.dateAppReceived)}
            </Table.Cell>
            {/* This cell is empty because there will never be a renewal Tag in here */}
            <Table.Cell backgroundColor="green-200" />
            <Table.Cell backgroundColor="green-200">
              {`${currentSubmission?.stage} / ${renderSubStage(
                currentSubmission?.subStage
              )}`}
            </Table.Cell>
            <Table.Cell backgroundColor="green-200">
              {currentSubmission?.declineDrivers}
            </Table.Cell>
          </Table.Row>

          {similarOwners?.map((similarOwner, idx) => {
            const submissionsToDisplay = filterAndSortSubmissions(similarOwner);
            /**
             * Each row mapped below represents a new owner, separated by a section divider in the table
             * due to the sectionDivider prop. This divider helps with visual separation of the owners
             * because each owner can have multiple submission rows within it, which are mapped below.
             */
            return (
              <>
                <Table.Row
                  key={`${idx + 1}_${similarOwner.ssnLast4}`}
                  sectionDivider
                >
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      `${similarOwner.firstName} ${similarOwner.lastName}`.toLowerCase() ===
                      owner.fullName.toLowerCase()
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    {similarOwner.firstName} {similarOwner.lastName}
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      similarOwner.ssnLast4 && similarOwner.ssnMatch
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    <Flex gap={2} alignItems="baseline">
                      {similarOwner.ssnLast4}
                      {similarOwner.ssnLast4 && similarOwner.ssnMatch && (
                        <Icon name="check" data-testid="ssn-match-icon" />
                      )}
                    </Flex>
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      similarOwner.bornOn &&
                      owner.birthdate &&
                      formatDateString(similarOwner.bornOn) ===
                        formatDateString(owner.birthdate)
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    {similarOwner.bornOn &&
                      formatDateString(similarOwner.bornOn)}
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      similarOwner.homePhone &&
                      owner.phoneNumber &&
                      formatPhoneNumber(similarOwner.homePhone) ===
                        formatPhoneNumber(owner.phoneNumber)
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    {similarOwner.homePhone &&
                      formatPhoneNumber(similarOwner.homePhone)}
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      verifyMatchingAddress(
                        similarOwner.street1,
                        similarOwner.city,
                        owner
                      )
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    {verifyMatchingAddress(
                      similarOwner.street1,
                      similarOwner.city,
                      owner
                    ) && (
                      <Flex justifyContent={'center'}>
                        <Icon name="check" data-testid="address-match-icon" />
                      </Flex>
                    )}
                  </Table.Cell>
                  <Table.Cell
                    rowSpan={submissionsToDisplay.length || 1}
                    verticalAlign="middle"
                    backgroundColor={
                      similarOwner.customerName &&
                      similarOwner.customerName.toLowerCase() ===
                        currentSubmissionDisplayName?.toLowerCase()
                        ? 'blue-100'
                        : 'white'
                    }
                  >
                    {similarOwner.customerName}
                  </Table.Cell>
                  <SubmissionSubRow
                    submission={submissionsToDisplay[0]}
                    renderSubstage={renderSubStage}
                  />
                </Table.Row>
                {submissionsToDisplay.slice(1).map((submission) => {
                  /**
                   * These submission rows will render nested within the parent owner rows,
                   * which are visually divided by section dividers.
                   *
                   * The first submission was already rendered as part of the previous row
                   * so we slice it from the array and map over the remaining ones here.
                   */
                  return (
                    <Table.Row key={submission.uuid}>
                      <SubmissionSubRow
                        submission={submission}
                        renderSubstage={renderSubStage}
                      />
                    </Table.Row>
                  );
                })}
              </>
            );
          })}
        </Table.Body>
      </Table>
    </Box>
  );
};
