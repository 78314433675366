import React, { ReactNode, useCallback } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Icon,
  Loading,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { formatDate } from 'helpers/string/dateUtils';
import { usePullLexisNexis } from '../OwnerOverview/ownerOverviewHooks';
import { InternalLink } from '../../shared/InternalLink';
import { LastPulledOnDate } from '../LastPulledOnDate/LastPulledOnDate';
import { OwnerLexisNexisManualSearch } from './ownerManualSearch.types';

type OwnerManualSearchTableProps = {
  loading: boolean;
  results?: OwnerLexisNexisManualSearch[];
  submissionUuid?: string;
  ownerUuid?: string;
};

const EmptyResultsWrapper = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => (
  <Table.Row>
    <Table.Cell colSpan={6} textAlign="center">
      <Box my={4}>{children}</Box>
    </Table.Cell>
  </Table.Row>
);

const TableContent = ({
  loading,
  results,
  submissionUuid,
  handleAttachReport,
}: Partial<OwnerManualSearchTableProps> & {
  handleAttachReport: (reportId: string) => Promise<void>;
}): JSX.Element => {
  // Loader
  if (loading) {
    return (
      <EmptyResultsWrapper>
        <Loading />
      </EmptyResultsWrapper>
    );
  }

  // Empty state
  if (!(results && results.length > 0)) {
    return <EmptyResultsWrapper>No results found.</EmptyResultsWrapper>;
  }

  // Renders data
  return (
    <>
      {results.map((result) => (
        <Table.Row key={result.reportId}>
          <Table.Cell>
            {result.name.map((name) => (
              <Box key={name}>{name}</Box>
            ))}
          </Table.Cell>
          <Table.Cell>
            {result.addresses.map((address) => (
              <Box key={address}>{address}</Box>
            ))}
          </Table.Cell>
          <Table.Cell>
            {result.ssn.map((ssn) => (
              <Box key={ssn}>{ssn}</Box>
            ))}
          </Table.Cell>
          <Table.Cell>
            {result.dob?.map((dob) => (
              <Box key={dob}>{dob}</Box>
            ))}
          </Table.Cell>
          <Table.Cell>
            {result.createdAt ? (
              <InternalLink
                to={`/dashboard/submission/${submissionUuid}/owner-lexis-nexis/report-id/${result.reportId}`}
                target={'_blank'}
              >
                {formatDate(new Date(result.createdAt), 'yyyy-MM-dd')}
              </InternalLink>
            ) : (
              ''
            )}
          </Table.Cell>
          <Table.Cell verticalAlign="middle">
            <Button onClick={() => handleAttachReport(result.reportId)}>
              Add to Submission
            </Button>
            {result.lastPulledOnDate && (
              <Box mt={1}>
                <LastPulledOnDate lastPulledOn={result.lastPulledOnDate} />
              </Box>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export const OwnerManualSearchTable = ({
  loading,
  results,
  submissionUuid,
  ownerUuid,
}: OwnerManualSearchTableProps): JSX.Element => {
  const [
    attachReportToEntity,
    { error: lexisNexisPullError, success: lexisNexisSuccess },
  ] = usePullLexisNexis();

  const handleAttachReport = useCallback(
    async (reportId: string): Promise<void> => {
      if (submissionUuid && ownerUuid) {
        await attachReportToEntity(submissionUuid, ownerUuid, false, reportId);
      }
    },
    [submissionUuid, ownerUuid, attachReportToEntity]
  );

  return (
    <Box>
      <Subheading variant="section">LexisNexis Report Data</Subheading>
      {lexisNexisPullError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisPullError}
        </Banner>
      )}
      {lexisNexisSuccess && (
        <Banner dismissable={false} variant="success">
          <Flex flexDirection={'row'} gap={2}>
            <Icon name="circle-check" size="2x" />
            <Text>
              LexisNexis was pulled successfully! It takes anywhere from 5
              seconds to 4 minutes to process the data, so please allow a little
              bit of time before refreshing the page. If you are still not able
              to view the report after 4 minutes, please contact the tech
              support.
            </Text>
          </Flex>
        </Banner>
      )}
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Names</Table.ColumnHeader>
          <Table.ColumnHeader>Addresses</Table.ColumnHeader>
          <Table.ColumnHeader>SSNs</Table.ColumnHeader>
          <Table.ColumnHeader>DOBs</Table.ColumnHeader>
          <Table.ColumnHeader>Associated Submission</Table.ColumnHeader>
          <Table.ColumnHeader>Actions</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <TableContent
            handleAttachReport={handleAttachReport}
            loading={loading}
            results={results}
            submissionUuid={submissionUuid}
          />
        </Table.Body>
      </Table>
    </Box>
  );
};
