import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { isEqual } from 'lodash';
import { Banner, Flex, Icon, Text } from '@forward-financing/fast-forward';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { Ledger } from '../ledger.types';
import { LEDGER_PROGRAM_OPTIONS_MAPPING } from '../constants';
import {
  LedgerValidationContextData,
  OffersValidationState,
  UseOffersValidationReturnType,
  UseProgramValidationReturnType,
} from './LedgerValidationContext.types';
import { LEDGER_VALIDATION_DEFAULT_OFFERS_STATE } from './LedgerValidationContext.constants';

const LedgersValidationContext = createContext<
  LedgerValidationContextData | undefined
>(undefined);

export const useLedgersValidationContext = (): LedgerValidationContextData => {
  const context = useContext(LedgersValidationContext);

  /**
   * Added this check to improve DevEx.
   * In case if someone accidentally uses this hook outside of the provider.
   */
  if (context === undefined) {
    throw new Error(
      'useLedgersValidationContext must be used within a LedgersValidationContextProvider'
    );
  }

  return context;
};

const useOffersValidation = (): UseOffersValidationReturnType => {
  const [areOffersInvalid, setAreOffersInvalid] = useState(false);

  const [offersValidationState, setOffersValidationState] =
    useState<OffersValidationState>(LEDGER_VALIDATION_DEFAULT_OFFERS_STATE);

  /**
   * Memoizing state in order to reduce amount of useEffect calls.
   * We want to trigger useEffect only when
   * offersValidationState values REALLY changes.
   */
  const memoizedOffersValidationState = useDeepCompareMemoize(
    offersValidationState
  );

  useEffect(() => {
    const isInvalid = Object.values(memoizedOffersValidationState).some(
      (valid) => !valid
    );

    setAreOffersInvalid(isInvalid);
  }, [memoizedOffersValidationState]);

  const updateOfferValidationState = useCallback(
    (month: number, isValid: boolean) => {
      setOffersValidationState((prevState) => {
        return {
          ...prevState,
          [month]: isValid,
        };
      });
    },
    []
  );

  return { areOffersInvalid, updateOfferValidationState };
};

const useProgramValidation = (
  programExplanation: string,
  selectedProgram: number,
  submissionType: string
): UseProgramValidationReturnType => {
  const [suggestedProgram, setSuggestedProgram] = useState<string>('');

  const isProgramExplanationRequired = useMemo(() => {
    const isNewDeal = submissionType === 'New Deal';

    // Id 34 corresponds to program 'Starter', id 1 corresponds to program 'High Risk',
    const isRequiredProgram = [34, 1].includes(selectedProgram);
    const doesNotMatchSuggestedPricingProgram =
      suggestedProgram !== LEDGER_PROGRAM_OPTIONS_MAPPING[selectedProgram];

    return (
      isNewDeal && (isRequiredProgram || doesNotMatchSuggestedPricingProgram)
    );
  }, [suggestedProgram, selectedProgram, submissionType]);

  const isProgramExplanationInvalid = useMemo(() => {
    return isProgramExplanationRequired ? !programExplanation.length : false;
  }, [isProgramExplanationRequired, programExplanation]);

  return {
    isProgramExplanationRequired,
    setSuggestedProgram,
    isProgramExplanationInvalid,
  };
};

export const LedgerValidationContextProvider = ({
  children,
  ledger,
  submissionType,
}: React.PropsWithChildren<{
  ledger: Ledger;
  submissionType: string;
}>): JSX.Element => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  /**
   * Memoizing the ledger in order to prevent unnecessary re-renders.
   */
  const memoizedLedger = useDeepCompareMemoize(ledger);
  /**
   * State to track all changes made by users.
   */
  const [ledgerToUpdate, setLedgerToUpdate] = useState<Ledger>(memoizedLedger);

  const handleChangeLedger = useCallback(
    (toUpdate: Partial<Ledger>): void => {
      setLedgerToUpdate((prevLedger) => {
        const updatedLedger = { ...prevLedger, ...toUpdate };
        setHasUnsavedChanges(false);
        if (!isEqual(updatedLedger, ledger)) {
          setHasUnsavedChanges(true);
        }
        return updatedLedger;
      });
    },
    [ledger, setLedgerToUpdate]
  );

  useEffect(() => {
    if (!isEqual(memoizedLedger, ledgerToUpdate)) {
      setLedgerToUpdate(memoizedLedger);
    }
    /**
     * We need to react to changes only in memoizedLedger,
     * otherwise we are unable to update the ledgerToUpdate
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedLedger]);

  const { areOffersInvalid, updateOfferValidationState } =
    useOffersValidation();

  const {
    isProgramExplanationInvalid,
    setSuggestedProgram,
    isProgramExplanationRequired,
  } = useProgramValidation(
    ledgerToUpdate.programExplanation,
    ledgerToUpdate.program,
    submissionType
  );

  const showOverrideBuyRatesReasonWarning =
    ledgerToUpdate.overrideBuyRates &&
    ledgerToUpdate.overrideBuyRatesReason.trim().length === 0;

  /**
   * Save button should be disabled if next conditions are met:
   *  - any of the offers are invalid
   *  - selected program type does not match suggested and explanation is empty
   *
   * BE will not allow us save ledgers if any of problems above appears.
   * So we're preventing unnecessary requests to BE.
   */
  const isSaveDisabled = useMemo(
    () => areOffersInvalid || isProgramExplanationInvalid,
    [areOffersInvalid, isProgramExplanationInvalid]
  );

  const showWarningBanner =
    hasUnsavedChanges || showOverrideBuyRatesReasonWarning;
  return (
    <LedgersValidationContext.Provider
      value={{
        isSaveDisabled,
        updateOfferValidationState,
        ledgerToUpdate,
        setLedgerToUpdate,
        isProgramExplanationRequired,
        setSuggestedProgram,
        isProgramExplanationInvalid,
        handleChangeLedger,
      }}
    >
      {showWarningBanner && (
        <Banner dismissable={false} variant="warning">
          <Flex flexDirection="column">
            {hasUnsavedChanges && (
              <Flex flexDirection="row" gap={2}>
                <Icon name="triangle-exclamation" />
                <Text>This section has unsaved changes.</Text>
              </Flex>
            )}
            {showOverrideBuyRatesReasonWarning && (
              <Flex flexDirection="row" gap={2}>
                <Icon name="triangle-exclamation" />
                <Text>Override Explanation is required.</Text>
              </Flex>
            )}
          </Flex>
        </Banner>
      )}
      {children}
    </LedgersValidationContext.Provider>
  );
};
