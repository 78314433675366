import React from 'react';
import {
  Box,
  Flex,
  formatDateTimeString,
  Icon,
  IconButton,
  Table,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import {
  Attachment,
  AttachmentDocumentTags,
} from '../AttachmentManager/attachmentManager.types';
import { useAttachmentManagerContext } from '../AttachmentManager/AttachmentManagerContext';
import { AttachmentGroup } from '../AttachmentManager/AttachmentGroup';
import { Stipulation } from './stipulationManager.types';
import { StipulationStatus } from './StipulationStatus';
import { EditStipulationModal } from './EditStipulationModal';
import { stipulationDisplayName } from './stipulationHelpers';

export interface StipulationTableProps {
  stipulations: Stipulation[];
  attachments: Attachment[];
  selectedIds: string[];
  onRowSelect: (stipulationId: string, checked: boolean) => void;
  toggleAllChecked: (allChecked: boolean) => void;
}

const isStipTypeOther = (stipulationToCheck: Stipulation): boolean =>
  stipulationToCheck.name.slice(0, 5) === 'Other';

const filterAttachmentsByStipulation = (
  stipulation: Stipulation,
  attachments: Attachment[],
  documentTags: AttachmentDocumentTags
): Attachment[] => {
  // other stips have names like "Other - Hello World",
  // so use that as the default value, and override searchKey if not an "other" stip

  const maybeEntry = Object.entries({
    ...documentTags.selectable,
    ...documentTags.nonSelectable,
  }).find(([_key, value]) => value === stipulation.name);

  if (maybeEntry) {
    // this variable looks like ["balance_sheet", "Balance Sheet"]
    // search based on the snake_case version
    return attachments.filter((att) =>
      att.documentTags.includes(maybeEntry[0])
    );
  }

  return [];
};

export const StipulationTable = ({
  stipulations,
  selectedIds = [],
  attachments,
  onRowSelect,
  toggleAllChecked,
}: StipulationTableProps): JSX.Element => {
  const { documentTags, isSalesforce } = useAttachmentManagerContext();

  /**
   * Why are we sorting this list twice? What the heck is this?
   *
   * So the ultimate goal is to sort the list alphabetically, then force
   * all the "Other - [Some Custom Value]" stips to the end.
   *
   * I did attempt to make the sorting work in one pass, but couldn't
   * find an easy/obvious way to not end up with one of the values
   * arbitrarily at the end.
   *
   * Honestly, we'll never have more than like, 12 stips, so sorting the
   * list twice is a couple of milliseconds worth of work. You're welcome
   * to take a shot at sorting it in one pass though. I won't yuck your yum.
   *
   * That said, if you do change this, it's VERY important that you
   * make a copy of the props, and sort the copy. Array.sort() sorts in
   * place, so if you don't first make a copy, you're modifying props, and
   * that's BAD.
   *
   * @tyrelosaur - Feb 16, 2023
   */
  const sortedStips = [...stipulations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (!isStipTypeOther(a) && isStipTypeOther(b) ? -1 : 1));

  /**
   * Wow man, two of these long comments in one file? What can I say, I
   * like my logic funky. Have some of dat fonky chonky to make up for it:
   * https://www.youtube.com/watch?v=9dvyybO4JmU
   *
   * So what are we doing here? Basically, we need a catch all category for the
   * documents that don't appear in any other stipulation row, so that they
   * don't vanish into the void.
   *
   * So here, we're grabbing all stips that have _no_ document tags, as well
   * as all stips that have a tag which is not one of the existing stips.
   *
   * An alternate approach might be to filter the list of all attachments (list A)
   * to the ones that exist in some stipulation row (list B), then filter List A
   * AGAIN to only the attachments which are NOT in List B. That's slightly different
   * behavior, in that a document might be tagged with an existing stip AND a non-existing
   * stip, so I've opted for this approach.
   *
   * @tyrelosaur - Feb 28, 2023
   */
  const unmatchedAttachments = (): Attachment[] => {
    const stipNames = sortedStips.map((stip) => stip.name);
    return attachments.filter((att) => {
      // grab the attachments with no document tags
      if (att.documentTags.length === 0) {
        return true;
      }

      // also grab the attachments that have tags which we don't have a stip for
      return !att.documentTags.some(
        (tag) =>
          stipNames.includes(documentTags.selectable[tag]) ||
          stipNames.includes(documentTags.nonSelectable[tag])
      );
    });
  };

  return (
    <Box>
      <Icon name="bookmark" /> = Standard stipulation
      <Table>
        <Table.Head>
          <Table.ColumnHeader />
          {!isSalesforce && (
            <Table.CheckboxColumnHeader
              label="Select all stipulations"
              checked={
                !!stipulations.length &&
                stipulations.length === selectedIds.length
              }
              onCheckboxChange={toggleAllChecked}
            />
          )}
          {!isSalesforce && <Table.ColumnHeader>Actions</Table.ColumnHeader>}
          <Table.ColumnHeader>Status</Table.ColumnHeader>
          <Table.ColumnHeader>Stipulation Name</Table.ColumnHeader>
          <Table.ColumnHeader>Received At</Table.ColumnHeader>
          <Table.ColumnHeader>Internal Comments</Table.ColumnHeader>
          {featureFlags.separate_additional_info_and_external_notes ? (
            <Table.ColumnHeader>External Notes</Table.ColumnHeader>
          ) : (
            <Table.ColumnHeader>Additional Info</Table.ColumnHeader>
          )}
          <Table.ColumnHeader>Reason</Table.ColumnHeader>
          <Table.ColumnHeader>Closing Analyst</Table.ColumnHeader>
          <Table.ColumnHeader>Underwriter</Table.ColumnHeader>
          <Table.ColumnHeader>Last Updated</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {sortedStips?.map((stip) => (
            <Table.ExpandableRow
              key={stip.uuid}
              content={
                <Box mb={4}>
                  <AttachmentGroup
                    source="closing_documents"
                    attachments={filterAttachmentsByStipulation(
                      stip,
                      attachments,
                      documentTags
                    )}
                    shouldShowDownload={false}
                  />
                </Box>
              }
            >
              {!isSalesforce && (
                <Table.CheckboxCell
                  label={`Select ${stip.name}`}
                  checked={selectedIds.includes(stip.uuid)}
                  onCheckboxChange={(checked) => {
                    onRowSelect(stip.uuid, checked);
                  }}
                />
              )}
              {!isSalesforce && (
                <Table.Cell>
                  <Flex justifyContent="center">
                    <EditStipulationModal
                      selectedStipulations={[stip]}
                      trigger={
                        <IconButton
                          icon="pencil"
                          label={`Edit ${stip.name}`}
                          hiddenLabel
                        />
                      }
                    />
                  </Flex>
                </Table.Cell>
              )}
              <Table.Cell>
                <StipulationStatus stipulationStatus={stip.status} />
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {!stip.isAdditional && <Icon name="bookmark" />}{' '}
                {stipulationDisplayName(stip.name, stip.additionalInfo)} (
                {
                  filterAttachmentsByStipulation(
                    stip,
                    attachments,
                    documentTags
                  ).length
                }
                )
              </Table.Cell>
              <Table.Cell>
                {stip.receivedAt && formatDateTimeString(stip.receivedAt)}
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {stip.internalComments}
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {stip.notes}
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {stip.reasons?.join(', ')}
              </Table.Cell>
              <Table.Cell>{stip.closingAnalyst?.name}</Table.Cell>
              <Table.Cell>{stip.underwriter?.name}</Table.Cell>
              <Table.Cell>
                {stip.updatedAt && formatDateTimeString(stip.updatedAt)}
              </Table.Cell>
            </Table.ExpandableRow>
          ))}
          <Table.ExpandableRow
            content={
              <Box mb={4}>
                <AttachmentGroup
                  source="closing_documents"
                  attachments={unmatchedAttachments()}
                  shouldShowDownload={false}
                />
              </Box>
            }
          >
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <>Uncategorized documents ({unmatchedAttachments().length})</>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
          </Table.ExpandableRow>
        </Table.Body>
      </Table>
    </Box>
  );
};
