export const UNDERWRITERS_ROLE = 'admin';
export const SALES_ROLE = 'manager';
export const PROCESSING_ROLE = 'processing';

export const ACCESS_API_URL = (): string => {
  return process.env.REACT_APP_ACCESS_API_URL || '';
};
export const AUTH_BASE_URL = (): string => {
  return process.env.REACT_APP_AUTH_BASE_URL || '';
};
export const ANALYTICS_GATEWAY_BASE_URL = (): string => {
  return process.env.REACT_APP_ANALYTICS_GATEWAY_BASE_URL || '';
};
export const ANALYTICS_GATEWAY_API_KEY = (): string => {
  return process.env.REACT_APP_ANALYTICS_GATEWAY_API_KEY || '';
};
export const BANK_BASE_URL = (): string => {
  return process.env.REACT_APP_BANK_BASE_URL || '';
};
export const CRM_GATEWAY_BASE_URL = (): string => {
  return process.env.REACT_APP_CRM_GATEWAY_BASE_URL || '';
};
export const FUNDING_BASE_URL = (): string => {
  return process.env.REACT_APP_FUNDING_APP_API_BASE_URL || '';
};
// The funding "base" url contains /api, which sometimes we don't want
export const FUNDING_URL = (): string => {
  const baseUrl = FUNDING_BASE_URL();
  return baseUrl ? new URL(baseUrl).origin : '';
};
export const GIT_CODE_VERSION = (): string => {
  return process.env.REACT_APP_COMMIT_HASH || '';
};

export const UNDERWRITING_BASE_URL = (): string => {
  return process.env.REACT_APP_UNDERWRITING_DOMAIN || '';
};
export const UNDERWRITING_APPLICATION_URL = (role = PROCESSING_ROLE): string =>
  `${process.env.REACT_APP_UNDERWRITING_DOMAIN}${role}/applications/`;

export const REACT_APP_SUBMISSION_QUEUE_URL = (): string => {
  return process.env.REACT_APP_SUBMISSION_QUEUE_URL || '';
};
export const THIRD_PARTY_INTEGRATIONS_BASE_URL = (): string => {
  return process.env.REACT_APP_THIRD_PARTY_INTEGRATIONS_BASE_URL || '';
};
export const THIRD_PARTY_INTEGRATIONS_API_KEY = (): string => {
  return process.env.REACT_APP_THIRD_PARTY_INTEGRATIONS_API_KEY || '';
};

export const REACT_APP_WAITING_ROOM_BASE_URL = (): string => {
  return process.env.REACT_APP_WAITING_ROOM_BASE_URL || '';
};

export const ML_DATA_PROVIDER_BASE_URL = (): string => {
  return process.env.REACT_APP_ML_DATA_PROVIDER_BASE_URL || '';
};

export const DATADOG_CLIENT_TOKEN = (): string => {
  return process.env.REACT_APP_DATADOG_TOKEN || '';
};

export const REACT_APP_ENV = (): string => {
  return process.env.REACT_APP_DEPLOY_ENV || '';
};

export const DATADOG_SITE = (): string => {
  return process.env.REACT_APP_DATADOG_SITE || '';
};

// Whether we should use MSW endpoints locally
export const IS_MOCKS_ENABLED = (): boolean => {
  return process.env.REACT_APP_ENABLE_MOCKS === 'true' || false;
};

// Date formats
export const UW_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATETIME_FORMAT = 'MM/DD/YY hh:mm';
export const START_DATE = '01/01/2015';

export const MAX_OWNERS = 4;

// Additional Information dropdown data
export const ENTITY_TYPES = [
  { label: 'Sole Proprietor', value: 'Sole Proprietor' },
  {
    label: 'Limited Liability Company (LLC)',
    value: 'Limited Liability Company (LLC)',
  },
  { label: 'Corporation', value: 'Corporation' },
  { label: 'Limited Partnership (LP)', value: 'Limited Partnership (LP)' },
  {
    label: 'Limited Liability Partnership (LLP)',
    value: 'Limited Liability Partnership (LLP)',
  },
  { label: 'General Partnership', value: 'General Partnership' },
];

export const USE_OF_FUNDS = [
  { label: 'Inventory', value: 'Inventory' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Taxes', value: 'Taxes' },
  { label: 'Payroll', value: 'Payroll' },
  { label: 'Debt Refinancing', value: 'Debt Refinancing' },
  { label: 'Business Expansion', value: 'Business Expansion' },
  { label: 'New Location', value: 'New Location' },
  { label: 'Renovation', value: 'Renovation' },
  { label: 'Equipment Purchase', value: 'Equipment Purchase' },
  { label: 'Equipment Repair', value: 'Equipment Repair' },
  { label: 'Hiring Employees', value: 'Hiring Employees' },
  { label: 'Other', value: 'Other' },
  { label: 'Misc. Business Expenses', value: 'Misc. Business Expenses' },
  { label: 'Materials', value: 'Materials' },
  { label: 'Working Capital', value: 'Working Capital' },
];

export enum SubmissionSource {
  OTHER = 'other',
  API = 'api',
  SHORT = 'shortened_form',
  STANDARD = 'standard_form',
  DIRECT = 'direct',
}

// Google API Constants
export const GOOGLE_MAPS_SOURCE = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;

export const needsApprovalInfoStages: string[] = [
  'Contracting',
  'Closing',
  'Funded',
];

// useful to unify error messages or expected empty fields
export const unableToFetchMsg = 'Unable to fetch';
export const notAvailableMsg = 'N/A';

// Algolia constants
export const algoliaSearchLimit = 1000;

/** Duration (in ms) of the exit transition when removing dynamic form items. */
export const DYNAMIC_FORM_ITEM_EXIT_TIMEOUT = 500;

export const FUNDING_ALIAS_URL =
  'https://docs.google.com/spreadsheets/d/15BqOndMqO5t7wjto2-N14fL_Dv4h8ZBNJouvcNo7-n0/edit#gid=0';
