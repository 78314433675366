import React from 'react';
import { Grid, Radio } from '@forward-financing/fast-forward';

type DeclineReasonsProps = {
  selectedReason: string;
  setSelectedReason: (reason: string) => void;
};

const RadioItem = ({ reason }: { reason: string }): JSX.Element => (
  <Radio.Item
    removeBackgroundColor
    reduceControlMargin
    id={reason}
    value={reason}
  >
    {reason}
  </Radio.Item>
);

export const DeclineReasons = ({
  selectedReason,
  setSelectedReason,
}: DeclineReasonsProps): JSX.Element => (
  <Radio
    name="Reason for declining"
    value={selectedReason}
    onChange={setSelectedReason}
    orientation="horizontal"
  >
    <Grid>
      <Grid.Item s={4}>
        {[
          'Business Risk',
          'Outstanding Stips not addressed',
          'Negative Days/Bounced Payments',
          'Original concerns noted not addressed',
          'Total Gross',
        ].map((reason) => (
          <RadioItem reason={reason} key={reason} />
        ))}
      </Grid.Item>
      <Grid.Item s={4}>
        {[
          'Capped due to Industry',
          'Excessive Recent Submissions',
          'No new information provided',
          'Owner Risk',
          'Other',
        ].map((reason) => (
          <RadioItem reason={reason} key={reason} />
        ))}
      </Grid.Item>
      <Grid.Item s={4}>
        {[
          'Cash Flow/Revenue',
          'Missing Competing Offer Information',
          'Open offer with another ISO',
          'Stacking Concerns',
        ].map((reason) => (
          <RadioItem reason={reason} key={reason} />
        ))}
      </Grid.Item>
    </Grid>
  </Radio>
);
