import {
  LexisNexisCustomerSearchResponseResults,
  PaynetResponse,
} from 'types/api/underwriting/types';
import {
  useLazyGetApiLexisNexisCustomerManualSearch,
  useLazyGetApiPaynetManualSearch,
  usePullCustomerLexisNexis,
  usePullPaynet,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  LatestLexisNexisReportsMap,
  LatestPaynetReportsMap,
} from '../LastPulledOnDate/lastPulledOnDateHooks';
import { toAddressString } from '../helpers';
import {
  BusinessManualSearchQueryParams,
  LexisNexisSearchResult,
  PaynetSearchResult,
} from './businessManualSearch.types';

const toLexisNexisManualSearchParams = (
  params: BusinessManualSearchQueryParams
): string => {
  const queryParams = [
    params.companyName && `company_name=${params.companyName}`,
    params.fein && `fein=${params.fein}`,
    params.phone && `phone=${params.phone}`,
    params.street1 && `street1=${params.street1}`,
    params.city && `city=${params.city}`,
    params.state && `state=${params.state}`,
    params.zip && `zip=${params.zip}`,
  ];

  return queryParams.filter(Boolean).join('&');
};

const toLexisNexisCustomerSearchData = (
  result: LexisNexisCustomerSearchResponseResults,
  lexisNexisReports: LatestLexisNexisReportsMap
): LexisNexisSearchResult => {
  return {
    reportId: result.report_identifier,
    name: result.name,
    address: toAddressString(result.address),
    hasUcc: result.has_ucc,
    lastPulledOnDate:
      result.report_identifier &&
      lexisNexisReports[result.report_identifier]?.created_at,
  };
};

type UseLexisNexisCustomerSearchResponse = [
  (
    submissionUuid: string,
    queryParams: BusinessManualSearchQueryParams
  ) => Promise<void>,
  {
    data: LexisNexisSearchResult[];
    loading: boolean;
    error?: Error;
    responseReady: boolean;
  }
];

export const useLexisNexisCustomerManualSearch = (
  latestLexisNexisReportsMap: LatestLexisNexisReportsMap
): UseLexisNexisCustomerSearchResponse => {
  const [fetcher, { data, loading, error, responseReady }] =
    useLazyGetApiLexisNexisCustomerManualSearch();

  const fetchSearchResults = (
    submissionUuid: string,
    queryParams: BusinessManualSearchQueryParams
  ): Promise<void> => {
    return fetcher({
      submissionUuid,
      queryParams: toLexisNexisManualSearchParams(queryParams),
    });
  };

  return [
    fetchSearchResults,
    {
      data:
        data?.results.map((report) =>
          toLexisNexisCustomerSearchData(report, latestLexisNexisReportsMap)
        ) || [],
      loading,
      error,
      responseReady,
    },
  ];
};

type PullLexisNexisBody = {
  force?: boolean;
  reportId?: string;
};

type UsePullCustomerLexisNexisReportResult = [
  (input: PullLexisNexisBody) => Promise<MutationResponse>,
  { data?: { success: true }; loading: boolean; error?: Error }
];

export const usePullCustomerLexisNexisReport = (
  submissionUuid: string
): UsePullCustomerLexisNexisReportResult => {
  const [baseFetchFunction, { data, loading, error }] =
    usePullCustomerLexisNexis();

  const pullReport = async (
    input: PullLexisNexisBody
  ): Promise<MutationResponse> => {
    return await baseFetchFunction({
      submissionUuid,
      force: input.force,
      reportId: input.reportId,
    });
  };

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

type PullPaynetBody = {
  reportId?: string;
};

type UsePullPaynetReportResult = [
  (input: PullPaynetBody) => Promise<MutationResponse>,
  { data?: MutationResponse; loading: boolean; error?: Error }
];

export const usePullPaynetReport = (
  submissionUuid: string
): UsePullPaynetReportResult => {
  const [baseFetchFunction, { data, loading, error }] = usePullPaynet();

  const pullReport = (input: PullPaynetBody): Promise<MutationResponse> => {
    return baseFetchFunction({
      submissionUuid,
      reportId: input.reportId,
    });
  };

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};

const toPaynetManualSearchParams = (
  params: BusinessManualSearchQueryParams
): string => {
  const queryParams = [
    params.companyName && `company_name=${params.companyName}`,
    params.fein && `fein=${params.fein}`,
    params.city && `city=${params.city}`,
    params.state && `state=${params.state}`,
  ];

  return queryParams.filter(Boolean).join('&');
};

const toPaynetSearchData = (
  result: PaynetResponse,
  latestPaynetReportsMap: LatestPaynetReportsMap
): PaynetSearchResult => {
  return {
    name: result.name,
    taxId: result.tax_id,
    city: result.city,
    stateCode: result.state_code,
    paynetId: result.paynet_id,
    lastPulledOnDate: latestPaynetReportsMap[result.paynet_id]?.created_at,
  };
};

type UsePaynetSearchResponse = [
  (
    submissionUuid: string,
    queryParams: BusinessManualSearchQueryParams
  ) => Promise<void>,
  {
    data: PaynetSearchResult[];
    loading: boolean;
    error?: Error;
    responseReady: boolean;
  }
];

export const usePaynetManualSearch = (
  latestPaynetReportsMap: LatestPaynetReportsMap
): UsePaynetSearchResponse => {
  const [fetcher, { data, loading, error, responseReady }] =
    useLazyGetApiPaynetManualSearch();

  const fetchSearchResults = (
    submissionUuid: string,
    queryParams: BusinessManualSearchQueryParams
  ): Promise<void> => {
    return fetcher({
      submissionUuid,
      queryParams: toPaynetManualSearchParams(queryParams),
    });
  };

  const results = data?.results?.map((result) =>
    toPaynetSearchData(result, latestPaynetReportsMap)
  );

  return [
    fetchSearchResults,
    {
      data: results || [],
      loading,
      error,
      responseReady,
    },
  ];
};
