import {
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Form,
  Grid,
  Icon,
  Loading,
  OptionShape,
  Select,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import {
  useGetUsersByRole,
  useUpdateSubmission,
} from './SubmissionEditingHooks';
import {
  FormUpdateState,
  SubmissionAnalysts,
  User,
} from './SubmissionEditing.types';

type AssignmentSectionFormProps = {
  submissionUuid: string;
  submissionAnalysts: SubmissionAnalysts;
  setFormStatus: (value: FormUpdateState) => void;
  formStatus: FormUpdateState;
};

export const AssignmentSectionForm = ({
  submissionAnalysts,
  submissionUuid,
  setFormStatus,
  formStatus,
}: AssignmentSectionFormProps): JSX.Element => {
  const { role, sub_role: subRole } = useUserContext();

  // Initial form state that removes the extra fields
  // provided from the submissionAnalysts prop
  const initialAssignmentForm: SubmissionAnalysts = {
    prequalAnalystName: submissionAnalysts.prequalAnalystName,
    decisionAnalystId: submissionAnalysts.decisionAnalystId,
    underwriter: submissionAnalysts.underwriter,
    processingAnalystId: submissionAnalysts.processingAnalystId,
    allowDecisionAnalystOverwrite: false,
  };

  const [assignmentForm, setAssignmentForm] = useState<SubmissionAnalysts>(
    initialAssignmentForm
  );

  const { data: prequalUsers, error: prequalError } =
    useGetUsersByRole('prequal');

  const { data: processingUsers, error: processingError } =
    useGetUsersByRole('processor');

  const { data: vcaUsers, error: vcaError } = useGetUsersByRole('vca');

  const { data: teamLeadsUsers, error: teamLeadError } = useGetUsersByRole(
    'processing_team_lead'
  );

  const { data: underwritingUsers, error: underwritingError } =
    useGetUsersByRole('underwriter');

  const [
    updateAnalysts,
    { error: updateSubmissionError, loading: updateSubmissionLoading },
  ] = useUpdateSubmission(submissionUuid);

  const userToOptionShape = (
    useNameAsValue: boolean,
    users?: User[]
  ): OptionShape[] => {
    if (!users) {
      return [];
    }

    return users
      ?.map((u) => {
        const fullName = `${u.firstName} ${u.lastName}`;
        return { value: `${useNameAsValue ? fullName : u.id}`, text: fullName };
      })
      .sort((userA, userB) => userA.text.localeCompare(userB.text));
  };

  const prequalDropdownOptions = prequalUsers?.concat(teamLeadsUsers || []);
  // we are concatenating  vca analysts because this teams sometimes help the processing team,
  // so they need to be able to assign themselves to deals
  const processingDropdownOptions = processingUsers?.concat(
    vcaUsers || [],
    teamLeadsUsers || []
  );

  const handleUpdateAnalysts = async (): Promise<void> => {
    setFormStatus(undefined);
    const { success } = await updateAnalysts(assignmentForm);

    if (success) {
      setFormStatus('updated');
    }
  };

  const handleAssignmentFormOnchange = (
    fieldValue: Partial<SubmissionAnalysts>
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');
    const updatedForm = { ...assignmentForm, ...fieldValue };
    if (isEqual(updatedForm, submissionAnalysts)) {
      setFormStatus(undefined);
    }
    setAssignmentForm(updatedForm);
  };

  const canCurrentUserEditAnalyst = (
    field:
      | 'Sub_On Analyst Name'
      | 'Prequal Analyst Name'
      | 'Underwriter Name'
      | 'Processor Name'
  ): boolean => {
    // prequal and processing role can edit underwriter field only
    if (
      ['prequal', 'processing'].includes(subRole || '') &&
      field === 'Underwriter Name'
    ) {
      return true;
    }
    // admin and super_admin and team_leads roles can edit all analysts fields
    else if (['admin', 'super_admin'].includes(role)) {
      return true;
    } else if (role === 'processing' && subRole === 'team_lead') {
      return true;
    }
    return false;
  };

  const shouldOverrideDecisionAnalyst =
    subRole === 'team_lead' || role === 'admin';

  return (
    <Box>
      <Subheading textAlign="center">Assignment Information</Subheading>

      <Divider margin={4} />
      {updateSubmissionError && (
        <Banner dismissable>{updateSubmissionError.message}</Banner>
      )}

      {underwritingError && (
        <Banner dismissable>{underwritingError.message}</Banner>
      )}

      {teamLeadError && <Banner dismissable>{teamLeadError.message}</Banner>}

      {vcaError && <Banner dismissable>{vcaError.message}</Banner>}

      {processingError && (
        <Banner dismissable>{processingError.message}</Banner>
      )}

      {prequalError && <Banner dismissable>{prequalError.message}</Banner>}
      {formStatus === 'unsaved' && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      <Form
        accessibleName="Edit Analysts Information"
        onSubmit={handleUpdateAnalysts}
        allowImplicitSubmission={false}
      >
        {({ fireSubmit }) => (
          <Grid gutter>
            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                {canCurrentUserEditAnalyst('Sub_On Analyst Name') && (
                  <Select
                    label="Sub_On Analyst Name"
                    options={userToOptionShape(true, prequalDropdownOptions)}
                    value={assignmentForm.prequalAnalystName || ''}
                    onValueChange={(newVal) =>
                      handleAssignmentFormOnchange({
                        prequalAnalystName: newVal,
                      })
                    }
                  />
                )}

                {canCurrentUserEditAnalyst('Prequal Analyst Name') && (
                  <Select
                    label="Prequal Analyst Name"
                    options={userToOptionShape(false, prequalDropdownOptions)}
                    value={assignmentForm.decisionAnalystId?.toString() || ''}
                    onValueChange={(newVal) =>
                      // BE does not support any changes to decisionAnalystId field once it has been set.
                      // But to allow team leads and admin users, they have allowDecisionAnalystOverwrite
                      // param that would make these users able to bypass the validation in the BE.
                      handleAssignmentFormOnchange({
                        decisionAnalystId: +newVal,
                        allowDecisionAnalystOverwrite:
                          shouldOverrideDecisionAnalyst,
                      })
                    }
                  />
                )}
              </Flex>
            </Grid.Item>
            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                {canCurrentUserEditAnalyst('Underwriter Name') && (
                  <Select
                    label="Underwriter Name"
                    options={userToOptionShape(true, underwritingUsers)}
                    value={assignmentForm.underwriter || ''}
                    onValueChange={(newVal) =>
                      handleAssignmentFormOnchange({ underwriter: newVal })
                    }
                  />
                )}

                {canCurrentUserEditAnalyst('Processor Name') && (
                  <Select
                    label="Processor Name"
                    options={userToOptionShape(
                      false,
                      processingDropdownOptions
                    )}
                    value={assignmentForm.processingAnalystId?.toString() || ''}
                    onValueChange={(newVal) =>
                      handleAssignmentFormOnchange({
                        processingAnalystId: +newVal,
                      })
                    }
                  />
                )}
              </Flex>
            </Grid.Item>
            <Grid.Item>
              <Flex gap={3} justifyContent={'right'} mb={3}>
                {formStatus === 'updated' && (
                  <Icon size="2x" name="check-circle" />
                )}
                {updateSubmissionLoading && <Loading />}
                <Button onClick={fireSubmit} disabled={updateSubmissionLoading}>
                  Save Assignments
                </Button>
              </Flex>
            </Grid.Item>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
