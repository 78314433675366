import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  Combobox,
  Subheading,
  TextArea,
  SelectableOption,
  Icon,
  Text,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';
import { userFullName } from 'helpers/utils';
import {
  useDeclineDataSavedSubmissionLogs,
  useDeclineSubmission,
  useGetUser,
  useUnderwritingDeclineDrivers,
  useUpdateSubmission,
} from '../../Navbar/SubmissionNavbar/navbarFetchHooks';
import { DeclineForm } from '../../Navbar/SubmissionNavbar/navbar.types';
import { ToolboxSubmission } from './Toolbox.types';

export interface ToolboxDeclineModalProps {
  submission: ToolboxSubmission;
  refetchSubmission: () => void;
}
export const ToolboxDeclineModal = ({
  submission,
  refetchSubmission,
}: ToolboxDeclineModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [declineForm, setDeclineForm] = useState<DeclineForm>({
    previousDecline: false,
    declineDrivers: submission.declineDrivers ?? null,
    declineNotes: submission.declineDriverNotes ?? '',
  });

  const user = useUserContext();

  const {
    data: underwritingDeclineDrivers,
    error: underwritingDeclineDriversError,
    responseReady: underwritingDeclineDriversResponseReady,
  } = useUnderwritingDeclineDrivers(submission.uuid);

  const [
    updateSubmission,
    { loading: updateSubmissionLoading, error: updateSubmissionError },
  ] = useUpdateSubmission(submission.uuid);

  const { data: declineDataSavedLogs } = useDeclineDataSavedSubmissionLogs(
    submission.uuid
  );

  const mostRecentDeclineDataSavedLog = declineDataSavedLogs?.slice(-1)[0];

  const { data: lastSavedUser } = useGetUser(
    mostRecentDeclineDataSavedLog?.savedBy
  );

  const lastSavedUserName =
    lastSavedUser &&
    userFullName(lastSavedUser?.firstName, lastSavedUser?.lastName);

  const lastSavedDate =
    mostRecentDeclineDataSavedLog &&
    formatDateTimeString(mostRecentDeclineDataSavedLog.createdAt);

  /**
   * The BA and UA versions of these hooks return different data structures.
   * UA returns an array of strings directly, BA returns that array nested in an object.
   *
   * This variable is set to always be the array of strings, to make the eventual removal of this
   * feature flag more straightforward.
   */
  const declineDrivers = underwritingDeclineDrivers?.sort();

  const declineDriversError = underwritingDeclineDriversError;

  const declineDriversResponseReady = underwritingDeclineDriversResponseReady;

  const [declineSubmission, { loading: declineLoading, error: declineError }] =
    useDeclineSubmission(submission.uuid);

  const toSelectableOptions = (
    declineDriversData: string[]
  ): SelectableOption[] => {
    return declineDriversData.map((declineDriver) => {
      return { text: declineDriver, value: declineDriver };
    });
  };

  const formValid = declineForm.previousDecline || declineForm.declineDrivers;

  const onDecline = async (): Promise<void> => {
    if (!formValid) {
      setShowFormErrors(true);
      return;
    }

    const { success } = await declineSubmission({
      isPreviousDecline: declineForm.previousDecline,
      declineDrivers: declineForm.previousDecline
        ? ''
        : declineForm.declineDrivers ?? '',
      declineDriverNotes: declineForm.previousDecline
        ? ''
        : declineForm.declineNotes,
      declinedSource: 'underwriting',
      decisionAnalystId: user.id,
    });

    if (success) {
      setIsOpen(false);
      refetchSubmission();
    }
  };

  const onSaveWithoutDecline = async (): Promise<void> => {
    const { success } = await updateSubmission({
      declineDrivers: declineForm.previousDecline
        ? ''
        : declineForm.declineDrivers ?? '',
      declineDriverNotes: declineForm.previousDecline
        ? ''
        : declineForm.declineNotes,
    });

    if (success) {
      setIsOpen(false);
      refetchSubmission();
    }
  };

  if (!declineDriversResponseReady) {
    return <></>;
  }

  // If the submission is in the Declined stage or Digital Closing, we want to disable
  // the button regardless of the feature flag state.
  // If the submission is in the Underwriting stage, we only disable the button
  // when the feature flag is off.
  const disableModalButton =
    (submission.stageName === 'Digital Closing' &&
      submission.subStage === 'In Progress') ||
    submission.stageName === 'Declined' ||
    (!featureFlags.decline_from_underwriting_app_only &&
      submission.stageName === 'Underwriting');

  // If the decline driver matches the regex, then the result will be non-null;
  const isDeclineDriverIsoNotRegistered =
    declineForm.declineDrivers?.match(/ISO not registered/i) !== null;

  const isDeclineDriverExistingCustomer =
    declineForm.declineDrivers === 'Existing Customer';

  // If the decline driver is 'Existing Customer' or 'ISO Not registered...',
  // then the decline notes are not required so we skip the check.
  const hasRequiredDeclineNotes =
    !isDeclineDriverExistingCustomer && !isDeclineDriverIsoNotRegistered
      ? Boolean(declineForm.declineNotes)
      : true;

  const disableProceedWithDeclineButton = declineForm.previousDecline
    ? false
    : declineLoading || !declineForm.declineDrivers || !hasRequiredDeclineNotes;

  const disableSaveWithoutDeclineButton = declineForm.previousDecline
    ? false
    : updateSubmissionLoading ||
      !declineForm.declineDrivers ||
      !hasRequiredDeclineNotes;

  const shouldDisplayLastEditedInfo =
    lastSavedDate &&
    lastSavedUserName &&
    (submission.declineDrivers || submission.declineDriverNotes);

  return (
    <Box>
      <Modal
        title="Decline Submission"
        body={
          <>
            <Flex flexDirection="column" gap={2}>
              {declineDriversError && (
                <Banner dismissable={false} variant="error">
                  {declineDriversError.message}
                </Banner>
              )}

              {declineError && (
                <Banner dismissable>{declineError.message}</Banner>
              )}

              {updateSubmissionError && (
                <Banner dismissable>{updateSubmissionError.message}</Banner>
              )}

              <Subheading variant="section">Please provide details.</Subheading>

              <Checkbox
                label="Previous Decline"
                checked={declineForm.previousDecline}
                onCheckboxChange={(newValue) => {
                  setShowFormErrors(false);
                  setDeclineForm((oldValue) => {
                    return { ...oldValue, previousDecline: newValue };
                  });
                }}
              />
              <Combobox
                searchable
                label="Decline Drivers"
                placeholder="Select an option"
                options={
                  declineDrivers ? toSelectableOptions(declineDrivers) : []
                }
                values={
                  declineForm.previousDecline || !declineForm.declineDrivers
                    ? []
                    : [
                        {
                          text: declineForm.declineDrivers,
                          value: declineForm.declineDrivers,
                        },
                      ]
                }
                onValueChange={(newValues: SelectableOption[]) =>
                  setDeclineForm((oldValue) => {
                    return {
                      ...oldValue,
                      declineDrivers: newValues[0]?.value,
                    };
                  })
                }
                disabled={declineForm.previousDecline}
                errorMessage={
                  showFormErrors && !declineForm.declineDrivers
                    ? 'Decline drivers are required'
                    : undefined
                }
              />
              <TextArea
                label="Decline Notes"
                value={
                  declineForm.previousDecline ? '' : declineForm.declineNotes
                }
                onChange={(e) =>
                  setDeclineForm((oldValue) => {
                    return { ...oldValue, declineNotes: e.target.value };
                  })
                }
                disabled={declineForm.previousDecline}
                required={
                  featureFlags.require_decline_notes &&
                  Boolean(declineForm.declineDrivers) &&
                  !isDeclineDriverExistingCustomer &&
                  !isDeclineDriverIsoNotRegistered &&
                  !declineForm.declineNotes
                }
              />

              {featureFlags.allow_decline_data_save_without_decline &&
                shouldDisplayLastEditedInfo && (
                  <Flex mb={1} justifyContent="flex-end">
                    <Text italic>
                      Decline drivers were last saved by {lastSavedUserName} on{' '}
                      {lastSavedDate}
                    </Text>
                  </Flex>
                )}

              <Flex justifyContent="space-between">
                <Button
                  startIcon="thumbs-down"
                  variant="danger"
                  onClick={onDecline}
                  disabled={
                    featureFlags.require_decline_notes
                      ? disableProceedWithDeclineButton
                      : declineLoading
                  }
                >
                  Proceed with Decline
                </Button>

                <Flex gap={2}>
                  {featureFlags.allow_decline_data_save_without_decline && (
                    <Button
                      startIcon="save"
                      onClick={onSaveWithoutDecline}
                      disabled={
                        featureFlags.require_decline_notes
                          ? disableSaveWithoutDeclineButton
                          : !declineForm.declineDrivers ||
                            updateSubmissionLoading
                      }
                    >
                      Save Without Declining
                    </Button>
                  )}

                  <Button
                    startIcon="x"
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        }
        trigger={
          <Button disabled={disableModalButton} startIcon="thumbs-down">
            Decline{' '}
            {submission.stageName !== 'Declined' &&
              submission.declineDrivers && <Icon name="circle-exclamation" />}
          </Button>
        }
        isOpen={isOpen}
        onOpenChange={(open) => {
          setDeclineForm({
            previousDecline: false,
            declineDrivers: submission.declineDrivers ?? null,
            declineNotes: submission.declineDriverNotes ?? '',
          });
          setIsOpen(open);
        }}
      />
    </Box>
  );
};
