import { defaultTo } from 'lodash';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  useApiFetchLedger,
  useFetchLedgerBankingSuggestedPricing,
} from 'apiHooks/banking/ledgerFetchHooks';
import {
  LedgerBankingPricingSuggestionsRaw,
  LedgerResponse,
} from 'types/api/banking/types';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import {
  LedgerUnderwritingPricingSuggestionsGetParams,
  PricingSuggestionResponse,
} from 'types/api/underwriting/types';
import { useFetchLedgerUnderwritingSuggestedPricing } from 'apiHooks/underwriting/suggestedPricingFetchHooks';
import {
  ExceptionRequestSuggestedPricing,
  Ledger,
  UnderwritingPricingSuggestionsParams,
} from './exceptionRequest.types';

const toBankingSuggestedPricing = (
  data: LedgerBankingPricingSuggestionsRaw
): ExceptionRequestSuggestedPricing => {
  return {
    fundingAmount: data.gross.displayed_amount,
    termLength: data.estimated_term,
    buyRate: data.gross_basis.amount,
    programType: data.program_type,
  };
};

export const useBankingSuggestedPricing = (
  ledgerId?: number
): UseGenericQueryResponse<ExceptionRequestSuggestedPricing> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchLedgerBankingSuggestedPricing,
    (data) => data && toBankingSuggestedPricing(data),
    ledgerId
  );

  return {
    error,
    ...rest,
  };
};

const toUnderwritingSuggestedPricing = (
  data: PricingSuggestionResponse
): ExceptionRequestSuggestedPricing => ({
  fundingAmount: data.rounded_amount,
  termLength: data.max_term,
  buyRate: data.inputs.financings_gross,
  programType: data.program_type,
});

const toUnderwritingSuggestedPricingParams = (
  params: UnderwritingPricingSuggestionsParams
): LedgerUnderwritingPricingSuggestionsGetParams => ({
  source_key: params.sourceKey,
  source_type: params.sourceType,
  revenue: params.revenue,
  financings_count: params.financingsCount,
  financings_gross: params.financingsGross,
});

export const useUnderwritingSuggestedPricing = (
  submissionUuid: string,
  params: UnderwritingPricingSuggestionsParams
): UseGenericQueryResponse<ExceptionRequestSuggestedPricing> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchLedgerUnderwritingSuggestedPricing,
    (data) => data && toUnderwritingSuggestedPricing(data.primary_suggestion),
    submissionUuid,
    toUnderwritingSuggestedPricingParams(params)
  );

  return {
    error,
    ...rest,
  };
};

const toLedger = (response: LedgerResponse): Ledger => {
  return {
    ledgerId: defaultTo(response.id, undefined),
    revenue: defaultTo(response.net_deposit_override_cents, undefined),
    financingCount: defaultTo(response.financings_count, undefined),
    financingGross: defaultTo(response.financings_gross, undefined),
  };
};

export const useGetLedger = (
  ledgerId?: number
): UseGenericQueryResponse<Ledger> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useApiFetchLedger,
    (data) => data && toLedger(data),
    ledgerId
  );

  return {
    error,
    ...rest,
  };
};
