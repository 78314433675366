import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  PaybackSummariesResponse,
  PaybackSummaryDetailsResponse,
} from 'types/api/funding/types';
import {
  useApiGetPaymentSummaries,
  useApiGetPaymentSummaryDetails,
} from 'apiHooks/funding/paymentSummaryFetchHooks';
import { FetchSummaryDetailsParams } from 'api/funding/paymentSummaryFetchUtils';
import {
  PaginatedSummaries,
  PaginatedDetailedPayments,
  Summary,
  DetailedPayment,
} from './PaymentsDashboard.types';

export const toSummaries = (
  apiSummary: PaybackSummariesResponse['paybacks']
): Summary[] => {
  return apiSummary.map((payback) => ({
    uuid: payback.uuid,
    amount: Number.parseFloat(payback.amount),
    count: payback.count,
    date: payback.effective_entry_date,
    provider: payback.ach_provider,
    sameDay: payback.same_day_ach,
  }));
};

export const toPaginatedSummaries = (
  apiSummary?: PaybackSummariesResponse
): PaginatedSummaries | undefined => {
  if (apiSummary === undefined) {
    return undefined;
  }

  return {
    summaries: toSummaries(apiSummary.paybacks),
    currentPage: apiSummary.meta.current_page,
    totalPages: apiSummary.meta.total_pages,
  };
};

export const toDetailedPayments = (
  apiSummary: PaybackSummaryDetailsResponse['paybacks']
): DetailedPayment[] => {
  return apiSummary.map((payback) => ({
    id: payback.id,
    date: payback.effective_entry_date,
    provider: payback.ach_provider,
    advance: payback.advance,
    amount: Number.parseFloat(payback.amount),
    description: payback.description,
    sameDay: payback.same_day_ach,
    status: payback.status,
  }));
};

export const toPaginatedDetailedPayments = (
  apiSummary?: PaybackSummaryDetailsResponse
): PaginatedDetailedPayments | undefined => {
  if (apiSummary === undefined) {
    return undefined;
  }

  return {
    payments: toDetailedPayments(apiSummary.paybacks),
    currentPage: apiSummary.meta.current_page,
    totalPages: apiSummary.meta.total_pages,
  };
};

export const useGetSummaries = (
  status: string,
  page: number
): UseGenericQueryResponse<PaginatedSummaries> => {
  return useGenericFeatureQuery(
    useApiGetPaymentSummaries,
    toPaginatedSummaries,
    status,
    page
  );
};

export const useGetDetailedPayments = (
  params: FetchSummaryDetailsParams
): UseGenericQueryResponse<PaginatedDetailedPayments> => {
  return useGenericFeatureQuery(
    useApiGetPaymentSummaryDetails,
    toPaginatedDetailedPayments,
    params
  );
};
