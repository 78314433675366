import {
  Banner,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Form,
  Grid,
  Icon,
  Loading,
  Select,
  Subheading,
  TextArea,
  TextInput,
  Text,
} from '@forward-financing/fast-forward';
import React, { useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import { isEqual } from 'lodash';
import { USE_OF_FUNDS } from 'constants/globals';
import { isNumericString } from 'helpers/validations/ValidationHelpers';
import { useUserContext } from 'contexts/UserContext';
import {
  useBulkUpdateWebPresences,
  useGetIsos,
  useUpdateSubmission,
} from './SubmissionEditingHooks';
import {
  FormUpdateState,
  Submission,
  WebPresence,
} from './SubmissionEditing.types';
import { SUBMISSION_TYPE_NEW_DEAL, SUBMISSION_TYPE_RENEWAL } from './constants';
import { WebPresenceForm } from './WebPresenceForm';

// The union type is used to allow for empty strings to be valid via the z.literal('') method
// The optional method is used to allow for undefined values to be valid
// Thus, these are typed as `URL | '' | undefined`
const webPresenceFormSchema = z.object({
  businessWebsite: z.union([z.string().url().optional(), z.literal('')]),
  facebook: z.union([z.string().url().optional(), z.literal('')]),
  instagram: z.union([z.string().url().optional(), z.literal('')]),
  yelp: z.union([z.string().url().optional(), z.literal('')]),
  other: z.union([z.string().url().optional(), z.literal('')]),
});

export type WebPresenceFormSchemaError = Partial<
  z.inferFormattedError<typeof webPresenceFormSchema>
>;

type SubmissionSectionFormProps = {
  submission: Submission;
  webPresence: WebPresence;
  setFormStatus: (value: FormUpdateState) => void;
  formStatus: FormUpdateState;
};

export const SubmissionSectionForm = ({
  submission,
  webPresence,
  setFormStatus,
  formStatus,
}: SubmissionSectionFormProps): JSX.Element => {
  const { role } = useUserContext();

  // Destructure the submission object to remove the analyst IDs
  // to prevent them from being updated within this form.
  const {
    decisionAnalystId,
    processingAnalystId,
    prequalAnalystName,
    underwriter,
    ...submissionFormData
  } = submission;

  const [submissionForm, setSubmissionForm] =
    useState<Submission>(submissionFormData);
  const [webPresenceForm, setWebPresenceForm] =
    useState<WebPresence>(webPresence);
  const [webPresenceErrors, setWebPresenceErrors] =
    useState<WebPresenceFormSchemaError>({});

  const [capitalNeededNotInApp, setCapitalNeededNotInApp] = useState(
    submission.capitalNeeded === 0
  );

  const submissionFormSchema = z.object({
    capitalNeeded: z
      .union([z.number(), z.undefined()])
      .refine(
        (capital) =>
          capitalNeededNotInApp ||
          (Number.isInteger(capital) && Number(capital) > 0),
        {
          message: 'Amount Needed must be greater than 0.',
        }
      ),
    isoUuid: z
      .string()
      .optional()
      .refine((isoUuid) => isoUuid && isoUuid !== '', {
        message: 'ISO is required.',
      }),
    salesRepEmail: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (submission.type === SUBMISSION_TYPE_NEW_DEAL && !!value) {
            return z.string().email().safeParse(value).success;
          } else {
            return true;
          }
        },
        {
          message: 'Invalid email.',
        }
      ),
    renewalSubmitterEmail: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (submission.type === SUBMISSION_TYPE_RENEWAL && !!value) {
            return z.string().email().safeParse(value).success;
          } else {
            return true;
          }
        },
        {
          message: 'Invalid email.',
        }
      ),
  });

  type SubmissionFormSchemaError = Partial<
    z.inferFormattedError<typeof submissionFormSchema>
  >;
  const [submissionFormError, setSubmissionFormError] =
    useState<SubmissionFormSchemaError>({});

  const isValidSubmissionForm = (): boolean => {
    const validationResult = submissionFormSchema.safeParse(submissionForm);

    if (validationResult.success) {
      setSubmissionFormError({});
    } else {
      setSubmissionFormError(validationResult.error.format());
    }

    return validationResult.success;
  };

  const isValidWebPresenceForm = (): boolean => {
    const validationResult = webPresenceFormSchema.safeParse(webPresenceForm);

    if (validationResult.success) {
      setWebPresenceErrors({});
    } else {
      setWebPresenceErrors(validationResult.error.format());
    }

    return validationResult.success;
  };

  const [
    updateWebPresence,
    { error: updateWebPresenceError, loading: updateWebPresenceLoading },
  ] = useBulkUpdateWebPresences(submission.uuid);

  const [
    updateSubmission,
    { error: updateSubmissionError, loading: updateSubmissionLoading },
  ] = useUpdateSubmission(submission.uuid);

  const { data: isos, error: isosError } = useGetIsos();

  const handleUpdateSubmission = async (): Promise<void> => {
    if (!isValidSubmissionForm() || !isValidWebPresenceForm()) {
      return;
    }

    setFormStatus(undefined);

    const { success: successSubmission } = await updateSubmission(
      submissionForm
    );

    const { success: successWebPresence } = await updateWebPresence(
      webPresenceForm
    );

    if (successSubmission && successWebPresence) {
      setFormStatus('updated');
    }
  };

  const handleSubmissionFormOnchange = (
    updatedField: Partial<Submission>
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');

    const updatedForm = { ...submissionForm, ...updatedField };

    if (isEqual(updatedForm, submission)) {
      setFormStatus(undefined);
    }
    setSubmissionForm(updatedForm);
  };

  const handleWebPresenceFormOnchange = (
    newVal: string,
    field: keyof WebPresence
  ): void => {
    formStatus !== 'unsaved' && setFormStatus('unsaved');

    const updatedForm = { ...webPresenceForm, [field]: newVal };

    if (isEqual(updatedForm, webPresence)) {
      setFormStatus(undefined);
    }
    setWebPresenceForm(updatedForm);
  };

  const isosEmailMap = useMemo(() => {
    const hashmap: { [key: string]: string } = {};
    isos?.map((iso) => (hashmap[iso.value] = iso.email));
    return hashmap;
  }, [isos]);

  const salesRepEmailValue =
    submissionForm.salesRepEmail ||
    (submissionForm.isoUuid && isosEmailMap[submissionForm.isoUuid]);

  useEffect(() => {
    const autoUpdateSalesRepEmail = async (): Promise<void> => {
      // If the sales rep email is not set and the ISO is set, set the sales rep email to the ISO's email,
      // but still allow the user to clear it
      if (salesRepEmailValue && submissionForm.salesRepEmail === undefined) {
        setSubmissionForm({
          ...submissionForm,
          salesRepEmail: salesRepEmailValue,
        });

        // Update the submission with the sales rep email automatically to prevent requiring
        // manual saving of the form
        await updateSubmission({
          salesRepEmail: salesRepEmailValue,
        });
      }
    };

    void autoUpdateSalesRepEmail();
  }, [salesRepEmailValue, submissionForm, updateSubmission]);

  const requestsLoading = updateSubmissionLoading || updateWebPresenceLoading;

  return (
    <Box>
      <Subheading textAlign="center">Submission Information</Subheading>

      <Divider margin={4} />

      {updateSubmissionError && (
        <Banner dismissable>{updateSubmissionError?.message}</Banner>
      )}

      {updateWebPresenceError && (
        <Banner dismissable>{updateWebPresenceError?.message}</Banner>
      )}

      {isosError && <Banner dismissable>{isosError?.message}</Banner>}

      {formStatus === 'unsaved' && (
        <Banner dismissable={false} variant="warning">
          <Flex gap={2}>
            <Icon name="triangle-exclamation" />
            <Text>This section has unsaved changes</Text>
          </Flex>
        </Banner>
      )}

      <Form
        accessibleName="Edit Submission Information"
        onSubmit={handleUpdateSubmission}
        allowImplicitSubmission={false}
      >
        {({ fireSubmit }) => (
          <Grid gutter>
            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                <Box>
                  <TextInput
                    label="Amount Needed"
                    value={
                      submissionForm.capitalNeeded === 0
                        ? '' // Set to empty string to prevent `undefined` edge case that allowed non-numeric values
                        : submissionForm.capitalNeeded
                    }
                    disabled={capitalNeededNotInApp}
                    onValueChange={(newVal) =>
                      isNumericString(newVal) &&
                      handleSubmissionFormOnchange({
                        capitalNeeded: Number(newVal),
                      })
                    }
                    errorMessage={submissionFormError?.capitalNeeded?._errors.join(
                      ' '
                    )}
                    type="number"
                    required={!capitalNeededNotInApp}
                  />
                  {submission.capitalNeeded === 0 && (
                    <Checkbox
                      checked={capitalNeededNotInApp}
                      onCheckboxChange={(checked) => {
                        handleSubmissionFormOnchange({ capitalNeeded: 0 });
                        setCapitalNeededNotInApp(checked);
                      }}
                      label={'Amount Needed not provided'}
                    />
                  )}
                </Box>

                <Select
                  label="Use of Funds"
                  options={USE_OF_FUNDS || []}
                  value={submissionForm.useOfFunds || ''}
                  onValueChange={(newVal) =>
                    handleSubmissionFormOnchange({ useOfFunds: newVal })
                  }
                />

                <Select
                  label="ISO"
                  options={isos || []}
                  value={submissionForm.isoUuid || ''}
                  onValueChange={(newVal) => {
                    handleSubmissionFormOnchange({
                      isoUuid: newVal,
                      ...(isosEmailMap[newVal] && {
                        salesRepEmail: isosEmailMap[newVal],
                      }),
                    });
                  }}
                  errorMessage={submissionFormError?.isoUuid?._errors.join(' ')}
                  required
                  disabled={role !== 'admin'}
                />
              </Flex>
            </Grid.Item>

            <Grid.Item s={12} m={6} l={6}>
              <Flex flexDirection={'column'} gap={2}>
                <TextArea
                  label="Email Content"
                  value={submissionForm.emailContent}
                  rows={3}
                  onChange={(e) =>
                    handleSubmissionFormOnchange({
                      emailContent: e.target.value,
                    })
                  }
                />
                <TextInput
                  label="Partner Sales Rep Email"
                  type="email"
                  value={submissionForm.salesRepEmail}
                  onValueChange={(newVal) =>
                    handleSubmissionFormOnchange({ salesRepEmail: newVal })
                  }
                  errorMessage={submissionFormError?.salesRepEmail?._errors.join(
                    ' '
                  )}
                />
                {submission.type === SUBMISSION_TYPE_RENEWAL && (
                  <TextInput
                    label="Renewal Submitter Email"
                    type="email"
                    value={submissionForm.renewalSubmitterEmail}
                    onValueChange={(newVal) =>
                      handleSubmissionFormOnchange({
                        renewalSubmitterEmail: newVal,
                      })
                    }
                    errorMessage={submissionFormError?.renewalSubmitterEmail?._errors.join(
                      ' '
                    )}
                  />
                )}
              </Flex>
            </Grid.Item>

            <Grid.Item>
              <WebPresenceForm
                webPresenceForm={webPresenceForm}
                handleWebPresenceFormOnchange={handleWebPresenceFormOnchange}
                webPresenceErrors={webPresenceErrors}
              />
            </Grid.Item>

            <Grid.Item>
              <Flex gap={3} justifyContent={'right'}>
                {formStatus === 'updated' && (
                  <Icon size="2x" name="check-circle" />
                )}
                {requestsLoading && <Loading />}

                <Button onClick={fireSubmit} disabled={requestsLoading}>
                  Save Submission
                </Button>
              </Flex>
            </Grid.Item>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
