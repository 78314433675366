import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { THIRD_PARTY_INTEGRATIONS_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import {
  ConsumerCreditEntities,
  ExperianConsumerArchivesResponse,
  ExperianConsumerCreditReportResponse,
  ExperianConsumerResponse,
} from 'types/api/3pi/types';

const threePi = (path: string): URL =>
  new URL(path, THIRD_PARTY_INTEGRATIONS_BASE_URL());

export const fetchExperianConsumer = async (
  submissionUuid: string,
  ownerUuid: string,
  entities?: ConsumerCreditEntities[]
): Promise<ExperianConsumerResponse> => {
  const url = threePi(
    `/api/v2/experian/consumer/${submissionUuid}/owners/${ownerUuid}${
      entities?.length ? `?entities=${entities.join(',')}` : ''
    }`
  );

  const response = await makeInternalAPIRequest<ExperianConsumerResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Report'
    );
  }

  return response.json();
};

export const fetchExperianConsumerArchives = async (
  ownerUuid: string
): Promise<ExperianConsumerArchivesResponse> => {
  // Don't fetch from 3pi if the feature flag is disabled.
  if (!featureFlags.experian_consumer_3pi_archive) {
    return { consumer_credits: [] };
  }

  const url = threePi(
    `api/v2/experian/consumer_credit?ownerUUIDs=${ownerUuid}`
  );

  const response =
    await makeInternalAPIRequest<ExperianConsumerArchivesResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Report Archives for Owner'
    );
  }

  return response.json();
};

export const fetchExperianConsumerCreditReport = async (
  referenceUuid: string
): Promise<ExperianConsumerCreditReportResponse> => {
  if (!featureFlags.experian_consumer_3pi_detailed_page) {
    return { consumer_credit: null, success: true };
  }

  const url = threePi(`api/v2/experian/consumer_credit/${referenceUuid}`);

  const response =
    await makeInternalAPIRequest<ExperianConsumerCreditReportResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Credit Report'
    );
  }

  return response.json();
};
