import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Box,
  Flex,
  PlainDate,
  Banner,
} from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { Attachment } from './attachmentManager.types';
import {
  EditAttachmentForm,
  SingleAttachmentFormState,
} from './EditAttachmentForm';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { AccountInformationPanel } from './UnderwritingAttachmentManager/AccountInformationPanel';
import {
  useUpdateApplicationAttachment,
  useUpdateAttachment,
} from './attachmentManagerHooks';

export interface EditAttachmentsModalProps {
  selectedAttachments: Attachment[];
  onSaveComplete: () => void;
}

export interface FormState {
  [uuid: string]: SingleAttachmentFormState;
}

const attachmentListToFormState = (attachments: Attachment[]): FormState => {
  return attachments
    .sort((a, b) => a.fileName.localeCompare(b.fileName))
    .reduce((acc, att) => {
      const [year, month, day] =
        att.expiresOn?.split('-').map((val) => parseInt(val, 10)) ?? [];

      return {
        ...acc,
        [att.uuid]: {
          name: att.fileName,
          tags: att.documentTags,
          internalComments: att.description ?? '',
          location: att.source,
          expiresOn: att.expiresOn
            ? new PlainDate(year, month, day)
            : undefined,
        },
      };
    }, {} as FormState);
};

export const validateFormState = (
  formState: FormState,
  allAttachments: Attachment[],
  validFileExtensions: string[]
): void => {
  const singleFormStates = Object.entries(formState);

  for (const [uuid, singleFormState] of singleFormStates) {
    const unmodifiedAttachment = allAttachments.find(
      (att) => att.uuid === uuid
    );

    if (!unmodifiedAttachment) {
      throw new Error(
        'Attempting to modify an attachment that does not exist. Please create an AST'
      );
    }

    if (singleFormState.name === '') {
      throw new Error(
        `${unmodifiedAttachment.fileName}: Document name cannot be empty`
      );
    }

    const allowedExtensionsPattern = new RegExp(
      `^.*?\\.(${validFileExtensions.join('|')})$`,
      'i'
    );

    // check that the filename ends with one of our allowed extensions
    if (!allowedExtensionsPattern.test(singleFormState.name)) {
      throw new Error(
        `${
          unmodifiedAttachment.fileName
        }: File name must include one of the following extensions: ${validFileExtensions.join(
          ', '
        )}`
      );
    }

    if (
      singleFormState.tags.includes('other') &&
      singleFormState.internalComments === ''
    ) {
      throw new Error(
        `${unmodifiedAttachment.fileName}: Comments are required if attachment has "Other" tag`
      );
    }

    if (
      singleFormState.tags.includes('drivers_license') &&
      !singleFormState.expiresOn
    ) {
      throw new Error(
        `${unmodifiedAttachment.fileName}: Expiry date is required if attachment has "Drivers License" tag`
      );
    }

    // it would probably be more robust to verify
    // isValidAttachmentSource(location), but we don't
    // have that method, and this is all the user can
    // practically do. AND the backend will error anyway,
    // so probably not worth it to be super robust here
    if (!singleFormState.location) {
      throw new Error(
        `${unmodifiedAttachment.fileName}: A file location must be selected`
      );
    }

    // additional validations can go here
  }
};

export const EditAttachmentsModal = ({
  selectedAttachments,
  onSaveComplete,
}: EditAttachmentsModalProps): JSX.Element => {
  const {
    isPrequalContext,
    primaryId,
    reloadAttachmentManager,
    supportedFileExtensions,
  } = useAttachmentManagerContext();
  const [isOpen, setIsOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const sortedAttachments = [...selectedAttachments].sort((a, b) =>
    a.fileName.localeCompare(b.fileName)
  );

  const [formState, setFormState] = useState<FormState>(
    attachmentListToFormState(sortedAttachments)
  );

  const [updateAttachment, { error: updateAttachmentError }] =
    useUpdateAttachment();

  const [updatePrequalAttachment, { error: updatePrequalAttachmentError }] =
    useUpdateApplicationAttachment();

  const handleOpenChange = (): void => {
    setIsOpen((p) => !p);
    setFormState(attachmentListToFormState(sortedAttachments));
    setErrorMessage(undefined);
  };

  useEffect(() => {
    if (updateAttachmentError) {
      const error = toError(updateAttachmentError);
      setErrorMessage(error.message);
    } else if (updatePrequalAttachmentError) {
      const error = toError(updatePrequalAttachmentError);
      setErrorMessage(error.message);
    } else {
      setErrorMessage(undefined);
    }
  }, [updateAttachmentError, updatePrequalAttachmentError]);

  const onSubmit = async (): Promise<void> => {
    // this method throws if form state is invalid
    validateFormState(formState, sortedAttachments, supportedFileExtensions);

    let updateSuccess;
    if (isPrequalContext) {
      const attachments = Object.entries(formState).map(
        ([attachmentUuid, attachmentChanges]) => {
          return {
            applicationUuid: primaryId,
            attachmentUuid,
            input: {
              fileName: attachmentChanges.name,
              description: attachmentChanges.internalComments,
              documentTags: attachmentChanges.tags,
              source: attachmentChanges.location,
              ...(attachmentChanges.expiresOn && {
                expiresOn: attachmentChanges.expiresOn.toString(),
              }),
            },
          };
        }
      );

      const { success } = await updatePrequalAttachment(attachments);
      updateSuccess = success;
    } else {
      const attachments = Object.entries(formState).map(
        ([attachmentUuid, attachmentChanges]) => {
          return {
            submissionUuid: primaryId,
            attachmentUuid,
            input: {
              fileName: attachmentChanges.name,
              description: attachmentChanges.internalComments,
              documentTags: attachmentChanges.tags,
              source: attachmentChanges.location,
              ...(attachmentChanges.expiresOn && {
                expiresOn: attachmentChanges.expiresOn.toString(),
              }),
            },
          };
        }
      );

      const { success } = await updateAttachment(attachments);
      updateSuccess = success;
    }

    if (updateSuccess) {
      handleOpenChange();
      onSaveComplete();
      reloadAttachmentManager();
    }
  };

  return (
    <Modal
      title="Edit Document(s)"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      trigger={
        <Button
          startIcon="pencil-alt"
          disabled={sortedAttachments.length === 0}
        >
          Update Document(s)
        </Button>
      }
      body={
        <Flex flexDirection="column" gap={3}>
          {errorMessage && (
            <Banner variant="error" dismissable={false}>
              {errorMessage}
            </Banner>
          )}
          <AccountInformationPanel />
          {sortedAttachments.map((att, index) => (
            <Box
              key={att.uuid}
              backgroundColor={index % 2 === 0 ? 'gray-200' : 'white'}
              padding={3}
            >
              <EditAttachmentForm
                fileName={att.fileName}
                onChange={(newSingleFormValue) => {
                  setErrorMessage(undefined);
                  setFormState((prev) => ({
                    ...prev,
                    [att.uuid]: newSingleFormValue,
                  }));
                }}
                formState={formState[att.uuid]}
              />
            </Box>
          ))}
          {/**
           * Display the error message at the top and the bottom
           * because the Modal may scroll on smaller screens, so the
           * user would have to scroll up to see the error message if they
           * have many documents selected.
           *
           * This way, we're good regardless of whether they've scrolled up or down.
           *
           * @tyrelosaur - Feb 28, 2023
           */}
          {errorMessage && (
            <Banner variant="error" dismissable={false}>
              {errorMessage}
            </Banner>
          )}
          <Flex justifyContent="center" gap={2}>
            <Button onClick={onSubmit}>Save</Button>
            <Button variant="secondary" onClick={handleOpenChange}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
