import React, { useContext } from 'react';
import { Button, Flex } from '@forward-financing/fast-forward';

import { ExceptionRequestStatus } from 'components/ExceptionsRequest/exceptionsRequest.types';
import { ExceptionRequestDeclineModal } from '../DeclineModal/ExceptionRequestDeclineModal';
import { exceptionRequestContext } from '../ExceptionRequestContext';

type ExceptionRequestActionButtonsProps = {
  exceptionStatus: ExceptionRequestStatus;
  exceptionId: number;
  refetchExceptionRequests: () => void;
};

export const ExceptionRequestActionButtons = ({
  exceptionStatus,
  exceptionId,
  refetchExceptionRequests,
}: ExceptionRequestActionButtonsProps): JSX.Element | null => {
  const { updateExceptionRequest } = useContext(exceptionRequestContext);

  if (exceptionStatus !== 'pending_review') return null;

  /**
   * TODO: Add click handlers, each will be done as a separate ticket
   */

  return (
    <Flex flexDirection="row" gap={3} mt={4}>
      <Button startIcon="check" variant="primary">
        Offer stands
      </Button>

      <ExceptionRequestDeclineModal
        exceptionId={exceptionId}
        refetchExceptionRequests={refetchExceptionRequests}
      />

      <Button
        startIcon="pencil"
        variant="secondary"
        onClick={() => {
          if (updateExceptionRequest) {
            updateExceptionRequest({
              isLedgerOpen: true,
              isLedgerCloned: false,
              shouldModifyException: true,
            });
          }
        }}
      >
        Modify offer
      </Button>
    </Flex>
  );
};
