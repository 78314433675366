import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  ValidFileExtensions,
  DocumentTags,
  AccountResponse,
  AttachmentResponse,
  CreateAttachmentRequestBody,
  UpdateAttachmentRequestBody,
} from 'types/api/underwriting/types';
import { NetworkError } from 'api/networkError';

export const fetchDocumentTags = async (): Promise<DocumentTags> => {
  const url = new URL(
    `/api/v1/mappings/document_tags`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<DocumentTags>(url, 'GET');

  if (response.ok) {
    return response.json();
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Error fetching document tags. Please reload the page and try again.`
  );
};

export const fetchFileExtensions = async (): Promise<ValidFileExtensions> => {
  const url = new URL(
    `/api/v1/mappings/valid_attachment_extensions`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<ValidFileExtensions>(
    url,
    'GET'
  );

  if (response.ok) {
    return response.json();
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Error fetching file extensions. Please reload the page and try again.`
  );
};

export const fetchAccount = async (
  applicationUuid: string
): Promise<AccountResponse> => {
  const url = new URL(
    `api/v2/applications/${applicationUuid}/accounts`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<AccountResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      `Error fetching application account`
    );
  }

  return response.json();
};

export const downloadAllSubmissionAttachments = async (
  submissionUuid: string,
  sourceKey: string
): Promise<string> => {
  const url = new URL(
    `/api/v2/download_attachment_zip/${submissionUuid}/${sourceKey}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void>(url, 'GET');

  if (response.ok) {
    return response.url;
  }

  if (response.status === 422) {
    throw new NetworkError(422, 'Files are not ready for download');
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to download Attachments for ID ${submissionUuid} with source key ${sourceKey}`
  );
};

export const downloadAllApplicationAttachments = async (
  applicationUuid: string,
  sourceKey: string
): Promise<string> => {
  const url = new URL(
    `api/v1/applications/${applicationUuid}/download_attachments/${sourceKey}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void>(url, 'GET');

  if (response.ok) {
    return response.url;
  }

  if (response.status === 422) {
    throw new NetworkError(422, 'Files are not ready for download');
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to download Attachments for ID ${applicationUuid} with source key ${sourceKey}`
  );
};

export const fetchAttachmentsBySubmissionUuid = async (
  submissionUuid: string
): Promise<AttachmentResponse[]> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/attachments`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<AttachmentResponse[]>(
    url,
    'GET'
  );

  if (response.ok) {
    return response.json();
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to fetch Attachments for ID ${submissionUuid}`
  );
};

export const fetchAttachmentsByApplicationUuid = async (
  applicationUuid: string
): Promise<AttachmentResponse[]> => {
  const url = new URL(
    `api/v1/applications/${applicationUuid}/attachments`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<AttachmentResponse[]>(
    url,
    'GET'
  );

  if (response.ok) {
    return response.json();
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to fetch Attachments for ID ${applicationUuid}`
  );
};

export const createAttachment = async (
  submissionUuid: string,
  body: CreateAttachmentRequestBody
): Promise<{ success: boolean }> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/attachments`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    AttachmentResponse,
    CreateAttachmentRequestBody
  >(url, 'POST', body);

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to create Attachment');
};

export const createApplicationAttachment = async (
  applicationUuid: string,
  body: CreateAttachmentRequestBody
): Promise<{ success: boolean }> => {
  const url = new URL(
    `api/v1/applications/${applicationUuid}/attachments`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    AttachmentResponse,
    CreateAttachmentRequestBody
  >(url, 'POST', body);

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to create Attachment');
};

export const removeAttachment = async (
  submissionUuid: string,
  attachmentUuid: string
): Promise<{ success: true }> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/attachments/${attachmentUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<Response>(url, 'DELETE');

  if (response.ok) {
    return { success: true };
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to delete Attachment with id ${attachmentUuid}`
  );
};

export const removeApplicationAttachment = async (
  applicationUuid: string,
  attachmentUuid: string
): Promise<{ success: true }> => {
  const url = new URL(
    `api/v1/applications/${applicationUuid}/attachments/${attachmentUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<Response>(url, 'DELETE');

  if (response.ok) {
    return { success: true };
  }

  if (response.status === 403) {
    throw new NetworkError(403, 'You are not authorized');
  }

  throw new NetworkError(
    response.status,
    `Failed to delete Attachment with id ${attachmentUuid}`
  );
};

export const updateAttachment = async (
  submissionUuid: string,
  attachmentUuid: string,
  body: UpdateAttachmentRequestBody
): Promise<{ success: true }> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/attachments/${attachmentUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    AttachmentResponse,
    UpdateAttachmentRequestBody
  >(url, 'PATCH', body);

  if (response.ok) {
    return { success: true };
  }

  if (response.status === 400) {
    const responseBody = await response.json();
    // in the case of an error, we get back an object like
    // { file_names: [ 'Please use a valid file extension: bmp, csv, ...' ] }
    throw new NetworkError(
      400,
      `${body.file_name}: Failed to update Attachment. ${Object.values(
        responseBody
      ).join(', ')}`
    );
  }

  throw new NetworkError(
    response.status,
    `Failed to update Attachment ${body.file_name}`
  );
};

export const updateApplicationAttachment = async (
  applicationUuid: string,
  attachmentUuid: string,
  body: UpdateAttachmentRequestBody
): Promise<{ success: true }> => {
  const url = new URL(
    `api/v1/applications/${applicationUuid}/attachments/${attachmentUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    AttachmentResponse,
    UpdateAttachmentRequestBody
  >(url, 'PATCH', body);

  if (response.ok) {
    return { success: true };
  }

  if (response.status === 400) {
    const responseBody = await response.json();
    // in the case of an error, we get back an object like
    // { file_names: [ 'Please use a valid file extension: bmp, csv, ...' ] }
    throw new NetworkError(
      400,
      `${body.file_name}: Failed to update Attachment. ${Object.values(
        responseBody
      ).join(', ')}`
    );
  }

  throw new NetworkError(
    response.status,
    `Failed to update Attachment ${body.file_name}`
  );
};
