import { formatCurrency } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { z } from 'zod';

import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';
import { toDate } from 'helpers/string/dateUtils';
import { displayPercentage } from 'helpers/utils';
import {
  LEDGER_TABLES_IDX_MONTH_NAME_MAP,
  LEDGER_TABLES_MONTHS,
} from '../constants';
import {
  DOLLAR_FIELDS,
  OFFERS_TABLE_FIELD_NAME_LABEL_MAP,
  PERCENT_FIELDS,
} from './offers.constants';
import { LedgerOffer, OffersTableState } from './offers.types';

/**
 * Generates an array of strings in the format $monthNumber - $monthName.
 * The month number is calculated from the creation date, counting n months
 * from that date for each item in the OFFERS_TABLE_MONTHS array.
 */
export const generateOffersTableColumnHeaders = (
  creationDateString: string,
  includeYear = false
): string[] => {
  const creationDate = toDate(
    creationDateString.replace('Z', ''),
    "yyyy-MM-dd'T'HH:mm:ss.SSS"
  );

  return LEDGER_TABLES_MONTHS.map((monthOffset) => {
    const date = new Date(creationDate);
    date.setMonth(date.getMonth() + monthOffset);

    const monthNumber = date.getMonth();
    const year = date.getFullYear();
    const monthName = LEDGER_TABLES_IDX_MONTH_NAME_MAP[monthNumber.toString()];

    return `${monthOffset} - ${monthName}${includeYear ? ` ${year}` : ''}`;
  });
};

export const isLedgerOfferKey = (key: string): key is keyof LedgerOffer =>
  Object.keys(OFFERS_TABLE_FIELD_NAME_LABEL_MAP()).includes(key);

export const isLedgerOffer = (
  offer: LedgerOffer | unknown
): offer is LedgerOffer => {
  return (
    typeof offer === 'object' &&
    offer !== null &&
    (Object.keys(offer).includes('gross') ||
      Object.keys(offer).includes('dollarOverride'))
  );
};

/**
 * Formats a field value from a ledger offer based on the field type.
 *
 * @param offer - A partial ledger offer object which may contain the field to be formatted.
 * @param fieldName - The name of the field to be formatted.
 * @returns The formatted field value as a string. Returns an empty string if the field is not valid or the offer is undefined.
 *
 * The function performs the following formatting based on the field type:
 * - If the field is a dollar field, it formats the value as currency.
 * - If the field is a percent field, it formats the value as a percentage.
 * - Otherwise, it converts the field value to a string.
 */
export const formatLedgerOfferField = (
  offer: Partial<LedgerOffer> | undefined,
  fieldName: string
): string => {
  if (!isLedgerOfferKey(fieldName) || !offer || !offer[fieldName]) return '';

  if (DOLLAR_FIELDS.includes(fieldName) && !isNaN(Number(offer[fieldName]))) {
    return formatCurrency(Number(offer[fieldName]));
  }

  if (PERCENT_FIELDS.includes(fieldName) && !isNaN(Number(offer[fieldName]))) {
    return displayPercentage(Number(offer[fieldName]));
  }

  return defaultTo(String(offer?.[fieldName]), '');
};

/**
 * Updates a given offer object with a new value for a specified field.
 * If the field being updated is 'gross', it will unset the 'dollarOverride' field.
 * If the field being updated is 'dollarOverride', it will unset the 'gross' field.
 */
export const getUpdatedOffer = (
  offer: Partial<LedgerOffer>,
  field: keyof LedgerOffer,
  value: string | number
): Partial<LedgerOffer> => ({
  ...offer,
  [field]: value,
  ...(field === 'gross' && { dollarOverride: undefined }),
  ...(field === 'dollarOverride' && { gross: undefined }),
});

/**
 * Schema for validating dollar override values.
 * The dollar override represents amount of USD and its
 * value can either be an empty string or a number between 5000 and 500000.
 * This validation is required to make sure that we're
 * sending correct values to BE when calculating offer.
 */
const dollarOverrideSchema = z
  .string()
  .length(0)
  .or(z.coerce.number().min(5000).max(500000));

/**
 * Schema for validating gross values.
 * The gross represents amount of percents and its
 * value can either be an empty string or a number between 1 and 200.
 * This validation is required to make sure that we're
 * sending correct values to BE when calculating offer.
 */
const grossSchema = z.string().length(0).or(z.coerce.number().min(1).max(200));

export const isValidOfferCellValue = (
  fieldName: string,
  value: string
): boolean => {
  const newValue = value?.replace(/%|\$|,/g, '');

  if (fieldName === 'dollarOverride') {
    return dollarOverrideSchema.safeParse(newValue).success;
  }

  if (fieldName === 'gross') {
    return grossSchema.safeParse(newValue).success;
  }

  /**
   * We're not validating other fields as they're not editable.
   */
  return true;
};

/**
 * Generates the initial state for the offers table based on the fetched offers.
 * The function creates a cellsState object with the fetched offers and their values.
 * The cellsState object is used to keep track of the state of each cell in the table.
 */
export const geOffersTabletInitialState = (
  fetchedOffers: LedgerOffer[]
): OffersTableState => {
  const cellsState = fetchedOffers.reduce((acc, offer) => {
    const { months } = offer;

    return {
      ...acc,
      [months]: {
        dollarOverride: {
          value: formatLedgerOfferField(
            { dollarOverride: offer.dollarOverride },
            'dollarOverride'
          ),
          isValid: true,
        },
        gross: {
          value: formatLedgerOfferField({ gross: offer.gross }, 'gross'),
          isValid: true,
        },
        approvalAmount: {
          value: formatLedgerOfferField(
            { approvalAmount: offer.approvalAmount },
            'approvalAmount'
          ),
          isValid: true,
        },
        multipleOfAdb: {
          value: formatLedgerOfferField(
            { multipleOfAdb: offer.multipleOfAdb },
            'multipleOfAdb'
          ),
          isValid: true,
        },
        paybackAmount: {
          value: formatLedgerOfferField(
            { paybackAmount: offer.paybackAmount },
            'paybackAmount'
          ),
          isValid: true,
        },
        dailyPayment: {
          value: formatLedgerOfferField(
            { dailyPayment: offer.dailyPayment },
            'dailyPayment'
          ),
          isValid: true,
        },
        paybackAmountGross: {
          value: formatLedgerOfferField(
            { paybackAmountGross: offer.paybackAmountGross },
            'paybackAmountGross'
          ),
          isValid: true,
        },
        combinedGross: {
          value: formatLedgerOfferField(
            { combinedGross: offer.combinedGross },
            'combinedGross'
          ),
          isValid: true,
        },
      },
    };
  }, {});

  return {
    fetchedOffers,
    cellsState,
  };
};

/**
 * The function updates the cellsState and fetchedOffers properties of the state object.
 * It updates the cellsState with the calculated values from the response and updates the
 * fetchedOffers array with the new offer object.
 */
export const getUpdatedOffersTableStateWithResponse = (
  prevState: OffersTableState,
  calculationResponse: LedgerOffer
): OffersTableState => ({
  cellsState: {
    ...prevState.cellsState,
    [calculationResponse.months]: {
      ...prevState.cellsState[calculationResponse.months],
      approvalAmount: {
        value: formatLedgerOfferField(
          { approvalAmount: calculationResponse.approvalAmount },
          'approvalAmount'
        ),
        isValid: true,
      },
      multipleOfAdb: {
        value: formatLedgerOfferField(
          { multipleOfAdb: calculationResponse.multipleOfAdb },
          'multipleOfAdb'
        ),
        isValid: true,
      },
      paybackAmount: {
        value: formatLedgerOfferField(
          { paybackAmount: calculationResponse.paybackAmount },
          'paybackAmount'
        ),
        isValid: true,
      },
      dailyPayment: {
        value: formatLedgerOfferField(
          { dailyPayment: calculationResponse.dailyPayment },
          'dailyPayment'
        ),
        isValid: true,
      },
      paybackAmountGross: {
        value: formatLedgerOfferField(
          { paybackAmountGross: calculationResponse.paybackAmountGross },
          'paybackAmountGross'
        ),
        isValid: true,
      },
      combinedGross: {
        value: calculationResponse.combinedGross,
        isValid: true,
      },
    },
  },
  fetchedOffers: prevState.fetchedOffers.map((offer) =>
    offer.months === Number(calculationResponse.months)
      ? calculationResponse
      : offer
  ),
});

/**
 * The function updates the cellsState property of the state object with the new cell value.
 * It also updates the validation state of the cell and calls the updateOfferValidationState
 * function to update the validation state of the offer.
 */
export const getUpdatedOffersTableStateWithCellValue = (
  prevState: OffersTableState,
  month: number,
  fieldName: string,
  arg: string | ((prev: string) => string),
  updateOfferValidationState: (month: number, isValid: boolean) => void
): OffersTableState => {
  const newValue =
    typeof arg === 'function'
      ? arg(prevState.cellsState[month]?.[fieldName]?.value ?? '')
      : arg;

  const isNewValueValid = isValidOfferCellValue(fieldName, newValue);

  updateOfferValidationState(month, isNewValueValid);

  return {
    ...prevState,
    cellsState: {
      ...prevState.cellsState,
      [month]: {
        ...prevState.cellsState[month],
        [fieldName]: {
          isValid: isNewValueValid,
          value: newValue,
        },
        ...(fieldName === 'dollarOverride' && {
          gross: { value: '', isValid: true },
        }),
        ...(fieldName === 'gross' && {
          dollarOverride: { value: '', isValid: true },
        }),
      },
    },
  };
};

const fieldNameToColorMap: Record<string, ColorVariant> = {
  dollarOverride: 'white',
  gross: 'white',
  approvalAmount: 'gray-300',
  multipleOfAdb: 'gray-200',
  paybackAmount: 'gray-300',
  dailyPayment: 'gray-200',
  paybackAmountGross: 'gray-300',
  combinedGross: 'gray-200',
};

export const getRowColor = (fieldName: string): ColorVariant | undefined =>
  fieldNameToColorMap[fieldName];
