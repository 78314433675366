import { useCallback } from 'react';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { AcceptedOfferResponse } from 'types/api/banking/types';
import { fetchAcceptedOffer } from 'api/banking/acceptedOfferFetchUtils';

export const useApiAcceptedOffer = (
  submissionUuid?: string
): UseGenericQueryResponse<AcceptedOfferResponse> => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchAcceptedOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export const useApiAcceptedOffers = (
  submissionUuids?: string[]
): UseGenericQueryResponse<AcceptedOfferResponse[]> => {
  const loadData = useCallback(() => {
    if (!submissionUuids) {
      return undefined;
    }

    return Promise.all(submissionUuids.map((uuid) => fetchAcceptedOffer(uuid)));
  }, [submissionUuids]);

  return useGenericQuery(loadData);
};
