import {
  Table,
  formatDateString,
  formatDateTimeString,
  Box,
  Subheading,
} from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import { Attachment } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { AttachmentDownloadLink } from './AttachmentDownloadLink';

export interface AttachmentsTableProps {
  attachments: Attachment[];
  selectedAttachmentIds: string[];
  onRowSelect: (attachmentId: string, checked: boolean) => void;
  toggleAllChecked: (allChecked: boolean) => void;
}
export const AttachmentsTable = ({
  attachments,
  selectedAttachmentIds,
  onRowSelect,
  toggleAllChecked,
}: AttachmentsTableProps): JSX.Element => {
  const { documentTags } = useAttachmentManagerContext();
  const [sortKey, setSortKey] = useState<keyof Attachment>('fileName');
  const [direction, setDirection] = useState<
    'ascending' | 'descending' | 'none'
  >('none');

  const onSortClick = (key: keyof Attachment) => () => {
    if (key === sortKey) {
      setDirection((prevDir) =>
        prevDir === 'ascending' ? 'descending' : 'ascending'
      );
    } else {
      setSortKey(key);
      setDirection('ascending');
    }
  };

  const sortedAttachments = [...attachments].sort(
    (attachmentA: Attachment, attachmentB: Attachment): number => {
      return direction === 'descending'
        ? `${attachmentB[sortKey]}`.localeCompare(`${attachmentA[sortKey]}`)
        : `${attachmentA[sortKey]}`.localeCompare(`${attachmentB[sortKey]}`);
    }
  );

  return (
    <Box>
      <Subheading variant="section">Current Attachments</Subheading>
      <Table>
        <Table.Head>
          <Table.CheckboxColumnHeader
            label="Select all attachments"
            checked={
              !!attachments.length &&
              attachments.length === selectedAttachmentIds.length
            }
            onCheckboxChange={(newValue) => {
              if (attachments.length === 0) {
                return;
              }
              toggleAllChecked(newValue);
            }}
          />
          <Table.SortableColumnHeader
            label="Document Name"
            order={sortKey === 'fileName' ? direction : 'none'}
            onClick={onSortClick('fileName')}
          />
          <Table.ColumnHeader>Document Tags</Table.ColumnHeader>
          <Table.ColumnHeader>Internal Notes</Table.ColumnHeader>
          <Table.SortableColumnHeader
            label="Date Created"
            order={sortKey === 'createdAt' ? direction : 'none'}
            onClick={onSortClick('createdAt')}
          />
          <Table.SortableColumnHeader
            label="Date Modified"
            order={sortKey === 'updatedAt' ? direction : 'none'}
            onClick={onSortClick('updatedAt')}
          />
          <Table.SortableColumnHeader
            label="Expiry Date"
            order={sortKey === 'expiresOn' ? direction : 'none'}
            onClick={onSortClick('expiresOn')}
          />
          <Table.ColumnHeader>Uploaded By</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {sortedAttachments?.map((att) => (
            <Table.Row key={att.uuid}>
              <Table.CheckboxCell
                label={`Select ${att.fileName}`}
                checked={selectedAttachmentIds.includes(att.uuid)}
                onCheckboxChange={(checked) => {
                  onRowSelect(att.uuid, checked);
                }}
              />
              <Table.Cell columnWidth="large">
                <AttachmentDownloadLink attachment={att} />
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {att.documentTags
                  .map(
                    (tag) =>
                      documentTags.selectable[tag] ||
                      documentTags.nonSelectable[tag]
                  )
                  .sort()
                  .join(', ')}
              </Table.Cell>
              <Table.Cell columnWidth="medium" overflowStrategy="wrap">
                {att.description}
              </Table.Cell>
              <Table.Cell>
                {att.createdAt && formatDateTimeString(att.createdAt)}
              </Table.Cell>
              <Table.Cell>
                {att.updatedAt && formatDateTimeString(att.updatedAt)}
              </Table.Cell>
              <Table.Cell>
                {att.documentTags.includes('drivers_license') ? (
                  <>
                    {att.expiresOn
                      ? formatDateString(att.expiresOn)
                      : 'Not set'}
                  </>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell>{att.uploadedBy}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
