/* eslint-disable promise/prefer-await-to-then */

import React, { useEffect } from 'react';
import { useViewHistoryContext } from '../../../contexts/ViewHistoryProvider';
import { UnderwritingClient } from '../../../api/UnderwritingClient';

interface ViewHistoryTrackerProps {
  submissionUuid: string;
}

export const ViewHistoryTracker = (
  props: ViewHistoryTrackerProps
): JSX.Element => {
  const { addEntry } = useViewHistoryContext();
  useEffect(() => {
    if (props.submissionUuid) {
      /* eslint-disable promise/prefer-await-to-then */
      UnderwritingClient.fetchOpportunity(props.submissionUuid)
        .then((opportunity) => {
          addEntry('submissions', {
            title: opportunity.name,
            timestamp: Date.now(),
            objectUUID: props.submissionUuid,
          });
        })
        .catch(() => {
          return undefined;
        });
    }
  }, [props.submissionUuid, addEntry]);

  return <></>;
};
