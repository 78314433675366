import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGetApiIndustryRecommendations } from 'apiHooks/underwriting/submissionFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { IndustryRecommendationResponse } from 'types/api/underwriting/types';
import { IndustryRecommendations } from './suggestedIndustry.types';

const toIndustryRecommendation = (
  data: IndustryRecommendationResponse[]
): IndustryRecommendations | undefined => {
  const mostRecentRecommendation = data?.[0];

  if (!mostRecentRecommendation) {
    return undefined;
  }

  return {
    suggestedIndustry: {
      industry: mostRecentRecommendation.industry,
      isProhibited: mostRecentRecommendation.prohibited,
    },
    otherSuggestions: mostRecentRecommendation.predictions.map(
      (prediction) => ({
        vendor: prediction.vendor,
        naicsCode: prediction.naics_code,
        confidence: prediction.confidence_score,
        naicsDescription: prediction.naics_title,
        industry: prediction.industry,
        isProhibited: prediction.prohibited,
      })
    ),
  };
};

export const useSubmissionIndustryRecommendations = (
  submissionUuid?: string
): UseGenericQueryResponse<IndustryRecommendations> => {
  return useGenericFeatureQuery(
    useGetApiIndustryRecommendations,
    (data) => data && toIndustryRecommendation(data),
    submissionUuid
  );
};
