import React from 'react';
import { Flex, PageContainer } from '@forward-financing/fast-forward';
import { PaymentsSummary } from './PaymentsSummary';

export const PaymentsDashboardContainer = (): JSX.Element => {
  return (
    <PageContainer>
      <Flex paddingY={3} flexDirection={'column'} gap={2}>
        <PaymentsSummary status="pending" />
        <PaymentsSummary status="rejected" />
        <PaymentsSummary status="settled" />
        <PaymentsSummary status="failed" />
      </Flex>
    </PageContainer>
  );
};
