import React, { useState } from 'react';
import {
  Banner,
  Box,
  Card,
  Flex,
  Grid,
  PageContainer,
  Select,
} from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';
import {
  useGetLatestLexisNexisReportsMap,
  useGetLatestPaynetReports,
} from '../LastPulledOnDate/lastPulledOnDateHooks';
import { BusinessManualSearchForm } from './BusinessManualSearchForm';

import {
  useLexisNexisCustomerManualSearch,
  usePaynetManualSearch,
} from './businessManualSearchHooks';
import { BusinessManualSearchTable } from './BusinessManualSearchTable';
import { SearchType } from './businessManualSearch.types';

type Params = {
  submissionUuid: string;
};

export const BusinessManualSearchPage = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  if (!submissionUuid) {
    throw new Error('Submission UUID Param not set');
  }

  /**
   * Used to determine which third-party data fetcher to pass to BusinessManualSearchForm.
   *
   * The reasoning for having searchType exist in here rather than within BusinessManualSearchForm is because
   * the two fetchers for lexisNexis & Paynet need to be initialized in this component. For that reason, BusinessManualSearchForm
   * shouldn't need to care about the logic for figuring out which fetcher to use. It should just be passed the fetcher
   * that it needs to use.
   *
   * @mlukanus-ff - June 24, 2024
   */
  const [searchType, setSearchType] = useState<SearchType>('lexisNexis');

  const latestLexisNexisReportsMap =
    useGetLatestLexisNexisReportsMap(submissionUuid);
  const [
    lexisNexisFetcher,
    {
      data: lexisNexisData,
      loading: lexisNexisLoading,
      error: lexisNexisError,
      responseReady: lexisNexisResponseReady,
    },
  ] = useLexisNexisCustomerManualSearch(latestLexisNexisReportsMap);

  const latestPaynetReportsMap = useGetLatestPaynetReports(submissionUuid);
  const [
    paynetFetcher,
    {
      data: paynetData,
      loading: paynetLoading,
      error: paynetError,
      responseReady: paynetResponseReady,
    },
  ] = usePaynetManualSearch(latestPaynetReportsMap);

  const fetcher = {
    fn: searchType === 'lexisNexis' ? lexisNexisFetcher : paynetFetcher,
    data: searchType === 'lexisNexis' ? lexisNexisData : paynetData,
    error: searchType === 'lexisNexis' ? lexisNexisError : paynetError,
    loading: searchType === 'lexisNexis' ? lexisNexisLoading : paynetLoading,
    responseReady:
      searchType === 'lexisNexis'
        ? lexisNexisResponseReady
        : paynetResponseReady,
  };

  const shouldDisplayResultsTable = fetcher.responseReady && !fetcher.error;

  return (
    <PageContainer>
      <Flex mt={4} rounded flexDirection={'column'}>
        <Card collapsible title="Business Report Search Page">
          <Box mb={3}>
            <Grid gutter>
              {fetcher.error && (
                <Grid.Item s={12}>
                  <Banner variant="danger">{fetcher.error.message}</Banner>
                </Grid.Item>
              )}

              <Grid.Item s={12} m={3} l={3}>
                <Select
                  label="Search Type"
                  options={[
                    { text: 'Lexis Nexis', value: 'lexisNexis' },
                    { text: 'Paynet', value: 'paynet' },
                  ]}
                  defaultValue={searchType} // Used to prevent the Clear icon from appearing
                  value={searchType}
                  onValueChange={(value) => setSearchType(value as SearchType)}
                />
              </Grid.Item>
            </Grid>
          </Box>

          <BusinessManualSearchForm
            fetcher={fetcher.fn}
            loading={fetcher.loading}
            searchType={searchType}
            submissionUuid={submissionUuid}
          />
        </Card>

        {shouldDisplayResultsTable && submissionUuid && (
          <Card title="Search Results Table">
            <BusinessManualSearchTable
              searchType={searchType}
              data={fetcher.data}
              submissionUuid={submissionUuid}
            />
          </Card>
        )}
      </Flex>
    </PageContainer>
  );
};
