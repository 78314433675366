import { useCallback } from 'react';
import { fetchCreditDecision } from 'api/banking/creditDecisionFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  CreditDecisionResponse,
  DeclineCreditDecisionResponse,
} from 'types/api/banking/types';

export type UseApiCreditDecisionsResponse = UseGenericQueryResponse<
  CreditDecisionResponse | DeclineCreditDecisionResponse
>;

export const useApiCreditDecision = (
  submissionUuid?: string
): UseApiCreditDecisionsResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchCreditDecision(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export const useApiCreditDecisions = (
  submissionUuids?: string[]
): UseGenericQueryResponse<
  (CreditDecisionResponse | DeclineCreditDecisionResponse)[]
> => {
  const loadData = useCallback(() => {
    /**
     * This apiHook's data is only used to populate rows within one table.
     * As such, we do not need to worry about the case where submissionUuids
     * is an empty array, as it will perform the same as if it were undefined.
     * If the usage of this apiHook expands or changes, we may need to
     * revisit this.
     *
     * @mlukanus-ff - Jan 8, 2025
     */
    if (!submissionUuids) {
      return undefined;
    }

    return Promise.all(
      submissionUuids?.map((uuid) => fetchCreditDecision(uuid))
    );
  }, [submissionUuids]);

  return useGenericQuery(loadData);
};
